import _ from 'lodash'
import axios from 'axios'
import { Box } from '@mui/material'

const rootPath = '/scanTracking'

export const getDataCard = async ({
  setLoading,
  setDialogContext,
  data,
  setData,
}) => {
  setLoading(true)
  try {
    const result = await axios.get(`${rootPath}/getDataWorklist`)

    if (result.data.status === 'error') {
      setDialogContext({
        type: 'fail',
        content: result?.data?.msg || 'error!',
        open: true,
        onConfirmClick: () => {
          setDialogContext((p) => ({ ...p, open: false }))
          setLoading(false)
        },
      })
    } else {
      setData(_.get(result, 'data.row', []))
      setDialogContext((p) => ({ ...p, open: false }))
      setLoading(false)
    }
  } catch (error) {
    console.error('Get info Dashboard error : ', error)
  }
  setLoading(false)
}

export const saveDataCj = async ({
  dataObj,
  setLoading,
  setDialogContext,
  setOpenMessage,
  setMessagePop,
}) => {
  setLoading(true)
  setDialogContext((p) => ({ ...p, open: false }))
  try {
    let { data } = await axios.post(`${rootPath}/saveDataCj`, {
      ...dataObj,
    })

    if (data.status === 'error') {
      setDialogContext({
        type: 'fail',
        content: (
          <Box p={2} sx={{ wordBreak: 'break-word' }}>
            {data.msg}
          </Box>
        ),
        open: true,
        onConfirmClick: () => {
          setDialogContext((p) => ({ ...p, open: false }))
          setLoading(false)
        },
      })
    } else {
      setDialogContext((p) => ({ ...p, open: false }))
      await setMessagePop((prev) => {
        return {
          ...prev,
          text: data.msg,
          type: 'success',
        }
      })
      await setOpenMessage(true)

      return new Promise((resolve) => {
        setTimeout(() => {
          setLoading(false)
          resolve(true)
        }, 1000)
      })
    }
  } catch (error) {
    setLoading(false)
    console.error('create cj error : ', error)
  }
}

export const removeCj = async ({
  dataObj,
  setLoading,
  setDialogContext,
  setOpenMessage,
  setMessagePop,
  loadData,
}) => {
  setLoading(true)
  setDialogContext((p) => ({ ...p, open: false }))
  try {
    let { data } = await axios.post(`${rootPath}/removeCj`, {
      ...dataObj,
    })

    if (data.status === 'error') {
      setDialogContext({
        type: 'fail',
        content: (
          <Box p={2} sx={{ wordBreak: 'break-word' }}>
            {data.msg}
          </Box>
        ),
        open: true,
        onConfirmClick: () => {
          setDialogContext((p) => ({ ...p, open: false }))
          setLoading(false)
          loadData()
        },
      })
    } else {
      setDialogContext((p) => ({ ...p, open: false }))
      await setMessagePop((prev) => {
        return {
          ...prev,
          text: data.msg,
          type: 'success',
        }
      })
      await setOpenMessage(true)

      return new Promise((resolve) => {
        setTimeout(() => {
          setLoading(false)
          resolve(true)
        }, 1000)
      })
    }
  } catch (error) {
    setLoading(false)
    console.error('create cj error : ', error)
  }
}

export const generateShipping = async ({
  dataObj,
  setLoading,
  setDialogContext,
  setOpenMessage,
  setMessagePop,
  loadData,
}) => {
  setLoading(true)
  setDialogContext((p) => ({ ...p, open: false }))
  try {
    let { data } = await axios.post(`${rootPath}/generateShipping`, {
      ...dataObj,
    })

    if (data.status === 'error') {
      setDialogContext({
        type: 'fail',
        content: (
          <Box p={2} sx={{ wordBreak: 'break-word' }}>
            {data.msg}
          </Box>
        ),
        open: true,
        onConfirmClick: () => {
          setDialogContext((p) => ({ ...p, open: false }))
          setLoading(false)
          loadData()
        },
      })
    } else {
      setDialogContext((p) => ({ ...p, open: false }))
      await setMessagePop((prev) => {
        return {
          ...prev,
          text: data.msg,
          type: 'success',
        }
      })
      await setOpenMessage(true)

      return new Promise((resolve) => {
        setTimeout(() => {
          setLoading(false)
          resolve(true)
        }, 1000)
      })
    }
  } catch (error) {
    setLoading(false)
    console.error('create cj error : ', error)
  }
}
