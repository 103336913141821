import _ from 'lodash'
import jwt_decode from 'jwt-decode'

import axios from 'axios'

export const handleToken = ({ result, setContextAuth }) => {
  const token = _.get(result, 'data.token', '')
  const decode = jwt_decode(token)

  sessionStorage.setItem('token', token, { httpOnly: false })

  setContextAuth({ decode, status: 'success', token })
}

export const setDefaults = ({ contextAuth }) => {
  try {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL
    axios.defaults.headers.common['Authorization'] = contextAuth?.token
      ? `Bearer ${contextAuth?.token}`
      : ''
  } catch (error) {
    // console.error('setDefaults : ', error)
  }
}

export const handleSign = async ({ setDialogContext, setContextAuth }) => {
  console.log('handleSign')
  setDialogContext({
    type: 'auth',
    open: true,
    onConfirmClick: async () => {
      setDialogContext((p) => ({ ...p, open: false }))
      setTimeout(
        () =>
          handleSignOut({
            setDialogContext,
            setContextAuth,
          }),
        200
      )
    },
    onCancelClick: () => setDialogContext((p) => ({ ...p, open: false })),
  })
}

export const handleSignOut = ({ setContextAuth }) => {
  setContextAuth({ status: 'error' })
  sessionStorage.setItem('token', '')
  sessionStorage.setItem('location', '')
  sessionStorage.setItem('pageTitle', '')

  window.location.href = `/login`
}
