import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

export const dayFormatInput2 = (value) => {
  try {
    return value ? dayjs(value, 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
  } catch (error) {
    // console.error(' dayFormat  error : ', error)
  }
}

export const dayFormatInput = (value) => {
  try {
    return value ? dayjs(dayjs(value)).format('YYYY-MM-DD') : ''
  } catch (error) {
    // console.error(' dayFormat  error : ', error)
  }
}
export const dateTimeFormatInput = (value) => {
  try {
    let date = dayjs(value, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
    if (date === 'Invalid Date') {
      return dayjs(value).format('YYYY-MM-DD HH:mm')
    } else {
      return date
    }
  } catch (error) {
    // console.error(' dayFormat  error : ', error)
  }
}
export const getTime = (value) => {
  try {
    let date = dayjs(value, 'DD/MM/YYYY HH:mm').format('HH:mm')
    if (date === 'Invalid Date') {
      return dayjs(value).format('HH:mm')
    } else {
      return date
    }
  } catch (error) {
    // console.error(' dayFormat  error : ', error)
  }
}
export const dayFormat = (value = dayjs(), defaultText = '-') => {
  try {
    return value
      ? dayjs(value).tz('Asia/Seoul').format('DD/MM/YYYY')
      : defaultText
  } catch (error) {
    // console.error(' dayFormat  error : ', error)
  }
}
export const dateNow = ({ format = 'DD/MM/YYYY' }) =>
  dayjs().tz('Asia/Seoul').format(format)

export const dayTimeFormat = (value) => {
  try {
    return value ? dayjs(value).format('DD/MM/YYYY HH:mm:ss') : '-'
  } catch (error) {
    // console.error(' dayFormat  error : ', error)
  }
}

export const dateTimeFormat = ({
  value,
  formatValue = 'DD/MM/YYYY HH:mm:ss',
  format = 'DD/MM/YYYY HH:mm:ss',
  defaultText = '-',
}) => {
  try {
    const date = dayjs(value, formatValue).format(format)
    return date !== 'Invalid Date' ? date : defaultText
  } catch (error) {
    // console.error(' dateTimeFormat  error : ', error)
  }
}
