import React from 'react'

import { useDebouncedCallback } from 'use-debounce'

import { InputBase, Box, styled, TextField } from '@mui/material'

export default function SearchInput({ setFilter, placeholder = 'search' }) {
  const debounced = useDebouncedCallback((e) => {
    setFilter((prev) => ({
      ...prev,
      page: 0,
      search: encodeURIComponent(e.target.value),
    }))
  }, 1000)
  const configTextfield = {
    fullWidth: true,
    variant: 'outlined',
    size: 'small',
    onChange: debounced,
  }

  return (
    <Box className="d--f ai--c">
      <TextField
        {...configTextfield}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'white',
          },
          borderRadius: '10px',
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: 'black',
            backgroundColor: '#f2f2f2',
            borderRadius: '10px',
          },
          '& .MuiFormLabel-root': {
            color: '#d1d1d1',
          },
          '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
            {
              backgroundColor: 'transparent',
            },
          '& .MuiFormHelperText-root.Mui-error': {
            fontSize: '16px',
          },
        }}
        placeholder={placeholder}
        onChange={debounced}
      />
    </Box>
  )
}
