import { Button, Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function NotFound() {
  let navigate = useNavigate()

  return (
    <>
      <Grid
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Grid item xs={3}>
          <Box style={{ textAlign: 'center' }} alignItems="center">
            NotFound
          </Box>
          <Button onClick={() => navigate('/')}>BACK TO MAIN SCREEN</Button>
        </Grid>
      </Grid>
    </>
  )
}
