import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material'
import { useField, useFormikContext } from 'formik'

const CheckboxWrapper = ({
  name,
  label,
  keyValue,
  legend,
  disabled = false,
  handleChangeCheckBox,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)
  const [showValue, setShowValue] = useState(false)

  const handleChange = (evt) => {
    const { checked } = evt.target

    if (keyValue) {
      if (field?.value === keyValue) {
        setFieldValue(name, '')
      } else {
        setFieldValue(name, keyValue)
      }
    } else {
      setFieldValue(name, checked)
    }
    handleChangeCheckBox && handleChangeCheckBox()
  }

  const configCheckbox = {
    ...field,
    ...otherProps,
    onChange: handleChange,
  }

  const configFormControl = {}
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true
  }

  useEffect(() => {
    if (!field?.value) setShowValue(false)
    if (showValue !== field?.value) {
      setShowValue(field?.value)
    }
  }, [field.value])

  return (
    <FormControl {...configFormControl}>
      <FormLabel component="legend">{legend}</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              {...configCheckbox}
              checked={
                keyValue
                  ? field?.value === keyValue
                    ? true
                    : false
                  : showValue
              }
            />
          }
          label={label}
          disabled={disabled}
        />
      </FormGroup>
    </FormControl>
  )
}

export default CheckboxWrapper
