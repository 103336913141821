import React, { useState } from 'react'

import { OutlinedInput, Box } from '@mui/material'
import { useField } from 'formik'

import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'

import { ShowError } from '../../../components/styles/mui'
const TextFieldPasswordWrapper = ({
  name,
  handleOnChange,
  fullWidth = true,
  upperCase = false,
  variant = 'outlined',
  ...otherProps
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const [field, mata, helpers] = useField(name)
  const { setValue } = helpers

  const handleChange = (e) => {
    let value = e.target.value
    value = upperCase ? value.toUpperCase() : value
    setValue(value)
    handleOnChange && handleOnChange(value)
  }
  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: fullWidth,
    variant: variant,
    size: 'small',
    onChange: handleChange,
    // InputLabelProps={
    //   className: styles.floatingLabelFocusStyle,
    // }
  }

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true
    configTextfield.helperText = mata.error
  }

  return (
    <Box>
      <OutlinedInput
        {...configTextfield}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'white',
          },
          borderRadius: '10px',
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: 'black',
            backgroundColor: '#f2f2f2',
            borderRadius: '10px',
          },
          '& .MuiFormLabel-root': {
            color: '#d1d1d1',
          },
          '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
            {
              backgroundColor: 'transparent',
            },
          '& .MuiFormHelperText-root.Mui-error': {
            fontSize: '16px',
          },
        }}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((show) => !show)}
              onMouseDown={(event) => {
                event.preventDefault()
              }}
              edge="end"
            >
              {showPassword ? (
                <VisibilityOff sx={{ color: '#7C7F7F' }} />
              ) : (
                <Visibility sx={{ color: '#7C7F7F' }} />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
      {mata.touched && Boolean(mata.error) ? (
        <ShowError error={mata.error} />
      ) : (
        ''
      )}
    </Box>
  )
}

export default TextFieldPasswordWrapper
