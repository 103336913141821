import React from 'react'
import { Card } from '../styles'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Grid, Typography } from '@mui/material'

const CardMenu = ({ icon = '', text = '-', handleOnClick }) => {
  return (
    <div>
      <Card onClick={handleOnClick}>
        <Grid container columnSpacing={2}>
          <Grid item xs={2}>
            {icon ? icon : <HelpOutlineIcon style={{ fontSize: '45px' }} />}
          </Grid>
          <Grid item xs={10}>
            <Typography variant="lb" component="div" color={'gray'}>
              {text}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}

export default CardMenu
