import React from 'react'
import { Outlet } from 'react-router-dom'

import Header from './header'
import { Box } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'

export default function LayoutMenuNavBar() {
  return (
    <>
      <CssBaseline />
      <Header />
      <Box sx={{ pt: { xs: '56px', sm: '64px' } }}>
        <Outlet />
      </Box>
    </>
  )
}
