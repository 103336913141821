import React from 'react'
import { Box, Button, Dialog, IconButton } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { typeAll } from './text'

export const handleOnClose = (dialogType, handleClose) => {
  if (
    !(
      dialogType.type === 'fail' ||
      dialogType.type === 'success' ||
      dialogType.type === 'warning'
    )
  )
    handleClose()
}
export default function AlertDialog(props) {
  const {
    open,
    setOpen,
    handleClose,
    title,
    content,
    type,
    onConfirmClick,
    onCancelClick,
    maxWidth,
    agreeText,
    disagreeText,
    disabledBtn = false,
  } = props

  const onCancel = () => {
    setOpen((prev) => ({
      ...prev,
      open: false,
    }))
  }
  const dialogType = typeAll.find((item) => item.type === type)

  return (
    <Box>
      <Dialog
        data-testid="alert-dialog"
        fullWidth={true}
        maxWidth={!maxWidth ? 'xl' : maxWidth}
        open={open}
        onClose={() => handleOnClose(dialogType, handleClose)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {type === 'image' ? (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                m: 2,
              }}
            >
              {title ? title : <div></div>}
              <IconButton
                sx={{ color: 'white', backgroundColor: '#FF4D00' }}
                size={'small'}
                onClick={onCancel}
                disableRipple
              >
                <Clear fontSize="small" />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mx: 4,
                mb: 4,
              }}
            >
              {content}
            </Box>
          </>
        ) : (
          <Box
            p={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            {title ? title : dialogType?.title}

            <Box sx={{ width: '100%' }}>
              {content}

              <Button
                data-testid="btn-confirm-dialog"
                sx={{
                  width: '100%',
                  borderRadius: 30,
                  mt: 2,
                  mb: 2,
                  height: '55px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                variant="contained"
                onClick={onConfirmClick || onCancel}
                color={dialogType?.color}
                endIcon={<></>}
                disabled={disabledBtn || false}
              >
                {agreeText ? agreeText : dialogType?.agreeText}
              </Button>
              {(dialogType?.disagreeText || disagreeText) && (
                <Button
                  data-testid="btn-cancel-dialog"
                  sx={{
                    width: '100%',
                    borderRadius: 30,
                    mb: 2,
                    height: '55px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  variant="outlined"
                  onClick={onCancelClick || onCancel}
                  color={dialogType?.color}
                >
                  {disagreeText ? disagreeText : dialogType?.disagreeText}
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Dialog>
    </Box>
  )
}
