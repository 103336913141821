import React, { useState, useEffect } from 'react'
import CardInformation from '../../components/cardInformation'
import CardMenu from '../../components/cardMenu'
import { Box, Grid, Typography } from '@mui/material'
import { ScanSvg, PrintSvg, UserSvg, InquirySvg } from './schema'
import { useNavigate } from 'react-router-dom'
import Loading from '../../components/loading'
import { useDialogContext } from '../../context/dialog'
import { getInfoDashboard } from './handleData'

const Dashboard = () => {
  sessionStorage.setItem('pageTitle', 'DASHBOARD')
  const navigate = useNavigate()
  const { setDialogContext } = useDialogContext()
  const [loading, setLoading] = useState(true)
  const [showMenu, setShowMenu] = useState(false)
  const [infoArray, setInfoArray] = useState([
    { label: 'CREATED CJ ', value: '0', type: 'default' },
    { label: 'GEN SHIPPING MARK', value: '0', type: 'warning' },
  ])

  const menuArray = [
    { icon: <ScanSvg />, text: 'SCAN CJ TRACKING', link: '/scan' },
    { icon: <PrintSvg />, text: 'PRINTING (ON PC)', link: '/printing' },
  ]
  const menuArrayManagement = [
    { icon: <UserSvg />, text: 'USER', link: '/user' },
    { icon: <InquirySvg />, text: 'INQUIRY DATA', link: '/inquiryData' },
  ]

  useEffect(() => {
    getInfoDashboard({
      setLoading,
      setDialogContext,
      infoArray,
      setInfoArray,
      setShowMenu,
    })
  }, [])

  return (
    <Loading loading={loading} icon={true}>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 2 }} p={2}>
        {infoArray.map((item, index) => {
          return (
            <Grid key={index} item xs={6}>
              <CardInformation
                key={index}
                type={item.type}
                label={item.label}
                value={item.value}
              />
            </Grid>
          )
        })}
      </Grid>

      <Box sx={{ padding: 2 }}>
        <Typography variant="lb" color={'gray'}>
          MENU LIST
        </Typography>
      </Box>

      <Grid container rowSpacing={2} columnSpacing={{ xs: 2 }} p={2}>
        {menuArray.map((item, index) => {
          return (
            <Grid key={index} item xs={6}>
              <CardMenu
                key={index}
                icon={item.icon}
                text={item.text}
                handleOnClick={() => navigate(`${item.link}`)}
              />
            </Grid>
          )
        })}
      </Grid>
      <Box sx={{ padding: 2, display: { xs: 'none', sm: 'block' } }}>
        <Typography variant="lb" color={'gray'}>
          MANAGEMENT
        </Typography>
      </Box>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 2 }} p={2}>
          {menuArrayManagement.map((item, index) => {
            if (!showMenu && item.text === 'USER') return
            return (
              <Grid key={index} item xs={6}>
                <CardMenu
                  key={index}
                  icon={item.icon}
                  text={item.text}
                  handleOnClick={() => navigate(`${item.link}`)}
                />
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Loading>
  )
}

export default Dashboard
