import axios from 'axios'
import _ from 'lodash'
import { handleSignOut } from './auth'
import { Box } from '@mui/material'

export const handleErrorAPI = ({
  setDialogContext,
  setContextAuth,
  contextAuth,
  navigate,
}) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const msg = _.get(error, 'response.data.msg', undefined) || error.message

      error.response.status === 401
        ? setDialogContext({
            type: 'fail',
            content: (
              <Box p={2} sx={{ wordBreak: 'break-word' }}>
                {msg}
              </Box>
            ),
            open: true,
            onConfirmClick: () =>
              handleSignOut({
                setDialogContext,
                setContextAuth,
              }),
          })
        : setDialogContext({
            type: 'fail',
            content: (
              <Box p={2} sx={{ wordBreak: 'break-word' }}>
                {msg}
              </Box>
            ),
            open: true,
          })
    }
  )
}
