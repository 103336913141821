export const showMessage = async ({
  result,
  setPopAlertcontext,
  setDialogContext,
  onConfirmClick = () => {
    setDialogContext((p) => ({ ...p, open: false }))
  },
}) => {
  try {
    if (result?.data) {
      const {
        data: { msg, status, msgStep2 },
      } = result
      if (status === 'success') {
        setPopAlertcontext({ open: true, type: 'success', msg })
      } else if (status === 'warning') {
        setDialogContext({
          type: status,
          content: msg,
          open: true,
          onConfirmClick: () => {
            setPopAlertcontext({ open: true, type: 'success', msg: msgStep2 })
            setDialogContext((p) => ({ ...p, open: false, onConfirmClick }))
          },
        })
      } else {
        setDialogContext({
          type: status,
          content: msg,
          open: true,
          onConfirmClick: onConfirmClick,
        })
      }
    }
  } catch (error) {
    // console.error(' showMessage  error : ', error)
  }
}
