// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        // root: {
        //   height: '40px',
        // },
      },
      variants: [
        {
          props: { variant: 'primary1' },
          style: {
            color: `${theme.palette.primary.contrastText}`,
            background: `${theme.palette.primary.darker}`,
            border: `1px solid  ${theme.palette.primary.darker}`,
            borderRadius: '5px',
            '&:hover': {
              color: `${theme.palette.primary.darker}`,
              background: `${theme.palette.primary.light}`,
              border: `1px solid  ${theme.palette.primary.darker}`,
            },
            '&:disabled': {
              cursor: 'not-allowed',
              color: `${theme.palette.default.main}`,
              background: `${theme.palette.default.lighter}`,
              border: `1px solid  ${theme.palette.default.lighter}`,
            },
          },
        },
        {
          props: { variant: 'primary2' },
          style: {
            color: `${theme.palette.primary.contrastText}`,
            background: `${theme.palette.primary.main}`,
            border: `1px solid  ${theme.palette.primary.main}`,
            borderRadius: '5px',
            '&:hover': {
              color: `${theme.palette.primary.main}`,
              background: `${theme.palette.primary.light}`,
              border: `1px solid  ${theme.palette.primary.main}`,
            },
            '&:disabled': {
              cursor: 'not-allowed',
              color: `${theme.palette.default.main}`,
              background: `${theme.palette.default.lighter}`,
              border: `1px solid  ${theme.palette.default.lighter}`,
            },
          },
        },
        {
          props: { variant: 'primary2Outline' },
          style: {
            color: `${theme.palette.primary.main}`,
            background: `${theme.palette.grey[0]}`,
            border: `1px solid  ${theme.palette.primary.main}`,
            borderRadius: '5px',
            '&:hover': {
              color: `${theme.palette.primary.contrastText}`,
              background: `${theme.palette.primary.main}`,
              border: `1px solid  ${theme.palette.primary.main}`,
            },
            '&:disabled': {
              cursor: 'not-allowed',
              color: `${theme.palette.default.main}`,
              background: `${theme.palette.default.lighter}`,
              border: `1px solid  ${theme.palette.default.lighter}`,
            },
          },
        },
        {
          props: { variant: 'primary3Outline' },
          style: {
            color: `#CE0100`,
            backgroundColor: `#FFF2F2`,
            border: `1px solid #FF5F5F`,
            borderRadius: '5px',
            '&:hover': {
              color: `${theme.palette.primary.contrastText}`,
              backgroundColor: `#FF5F5F`,
              border: `1px solid  ${theme.palette.primary.main}`,
            },
            '&:disabled': {
              cursor: 'not-allowed',
              color: `${theme.palette.default.main}`,
              background: `${theme.palette.default.lighter}`,
              border: `1px solid  ${theme.palette.default.lighter}`,
            },
          },
        },
        {
          props: { variant: 'Outline2' },
          style: {
            color: `${theme.palette.grey[600]}`,
            background: `${theme.palette.grey[0]}`,
            border: `1px solid  ${theme.palette.grey[600]}`,
            borderRadius: '5px',
            '&:hover': {
              color: `${theme.palette.primary.contrastText}`,
              background: `${theme.palette.primary.light}`,
              border: `1px solid  ${theme.palette.grey[600]}`,
            },
            '&:disabled': {
              cursor: 'not-allowed',
              color: `${theme.palette.default.main}`,
              background: `${theme.palette.default.lighter}`,
              border: `1px solid  ${theme.palette.default.lighter}`,
            },
          },
        },
        {
          props: { variant: 'wth' },
          style: {
            height: '40px',
            color: `${theme.palette.primary.main}`,
            background: `${theme.palette.primary.light}`,
            border: `1px solid  ${theme.palette.primary.main}`,
            borderRadius: '5px',
            '&:hover': {
              color: `${theme.palette.primary.contrastText}`,
              background: `${theme.palette.primary.main}`,
              border: `1px solid  ${theme.palette.primary.main}`,
            },
            '&:disabled': {
              cursor: 'not-allowed',
              color: `${theme.palette.default.main}`,
              background: `${theme.palette.default.lighter}`,
              border: `1px solid  ${theme.palette.default.lighter}`,
            },
          },
        },
        {
          props: { variant: 'wthOutlinedLight' },
          style: {
            color: `${theme.palette.primary.dark}`,
            background: `${theme.palette.grey[0]}`,
            border: `1px solid  ${theme.palette.primary.dark}`,
            borderRadius: '5px',
            '&:hover': {
              color: `${theme.palette.primary.contrastText}`,
              background: `${theme.palette.primary.dark}`,
              border: `1px solid  ${theme.palette.primary.dark}`,
            },
            '&:disabled': {
              cursor: 'not-allowed',
              color: `${theme.palette.default.main}`,
              background: `${theme.palette.default.lighter}`,
              border: `1px solid  ${theme.palette.default.lighter}`,
            },
          },
        },
        {
          props: { variant: 'default1' },
          style: {
            color: `${theme.palette.default.contrastText}`,
            background: `${theme.palette.default.darker}`,
            border: `1px solid  ${theme.palette.default.main}`,
            borderRadius: '5px',
            '&:hover': {
              color: `${theme.palette.default.darker}`,
              background: `${theme.palette.default.lighter}`,
              border: `1px solid  ${theme.palette.default.lighter}`,
            },
          },
        },
        {
          props: { variant: 'textBtnCancel' },
          style: {
            color: 'grey', // Change color to grey
            background: 'transparent', // Make the background transparent
            border: 'none', // Remove the border
            borderRadius: '8px',
            '&:hover': {
              color: 'grey',
              background: 'rgba(169, 169, 169, 0.3)', // Change the hover background to a lighter grey
              border: 'none', // Remove the border on hover
            },
          },
        },
        {
          props: { variant: 'cancel' },
          style: {
            color: `${theme.palette.grey[0]}`,
            background: `${theme.palette.wth.grey}`,
            border: `1px solid  ${theme.palette.wth.grey}`,
            borderRadius: '5px',
            '&:hover': {
              color: `${theme.palette.grey[0]}`,
              background: `${theme.palette.grey.greyDisabled}`,
              border: `1px solid  ${theme.palette.wth.grey}`,
            },
          },
        },
        {
          props: { variant: 'blackOutline' },
          style: {
            color: `${theme.palette.common.black}`,
            background: `${theme.palette.grey[0]}`,
            border: `1px solid  ${theme.palette.common.black}`,
            borderRadius: '5px',
            '&:hover': {
              color: `${theme.palette.primary.contrastText}`,
              background: `${theme.palette.common.black}`,
              border: `1px solid  ${theme.palette.common.black}`,
            },
            '&:disabled': {
              cursor: 'not-allowed',
              color: `${theme.palette.default.main}`,
              background: `${theme.palette.default.lighter}`,
              border: `1px solid  ${theme.palette.default.lighter}`,
            },
          },
        },
        {
          props: { variant: 'filterPrimary' },
          style: {
            color: `${theme.palette.primary.contrastText}`,
            background: `${theme.palette.primary.main}`,
            border: `1px solid  ${theme.palette.primary.main}`,
            borderRadius: '8px',
            '&:hover': {
              color: `${theme.palette.primary.main}`,
              background: `${theme.palette.primary.light}`,
              border: `1px solid  ${theme.palette.primary.main}`,
            },
          },
        },
      ],
      //   styleOverrides: {
      //     root: {
      //       '&:hover': {
      //         boxShadow: 'none',
      //       },
      //     },

      //     sizeLarge: {
      //       height: 48,
      //     },
      //     containedInherit: {
      //       color: theme.palette.grey[800],
      //       boxShadow: theme.customShadows.z8,
      //       '&:hover': {
      //         backgroundColor: theme.palette.grey[400],
      //       },
      //     },
      //     containedPrimary: {
      //       boxShadow: theme.customShadows.primary,
      //     },
      //     containedSecondary: {
      //       boxShadow: theme.customShadows.secondary,
      //     },
      //     outlinedInherit: {
      //       border: `1px solid ${theme.palette.grey[500_32]}`,
      //       '&:hover': {
      //         backgroundColor: theme.palette.action.hover,
      //       },
      //     },
      //     textInherit: {
      //       '&:hover': {
      //         backgroundColor: theme.palette.action.hover,
      //       },
      //     },
      //   },
    },
  }
}
