import {
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Typography,
} from '@mui/material'

import { EmptyData } from '../index'

export const ShowRow = ({
  styleTableRow,
  columns,
  rows,
  showCheckbox,
  handleClick,
  isSelected,
  showCheckboxSticky,
}) => {
  return (
    <TableBody>
      {rows.map((row, index) => {
        const isItemSelected = showCheckbox && isSelected(row)
        const labelId = `enhanced-table-checkbox-${index}`
        const sticky = {
          position: 'sticky',
          background: 'white',
          boxShadow: `0px 4px 20px rgba(0, 0, 0, 0.05)`,
          zIndex: 1,
        }

        return (
          <TableRow
            hover
            onClick={(event) => handleClick(event, row)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={labelId}
            selected={isItemSelected}
            sx={styleTableRow({ row })}
          >
            {showCheckbox && (
              <TableCell
                padding="checkbox"
                key={`checkbox-${index}}`}
                sx={
                  showCheckboxSticky === 'left'
                    ? { ...sticky, right: 0 }
                    : showCheckboxSticky === 'right'
                    ? { ...sticky, left: 0 }
                    : {}
                }
              >
                <Checkbox
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                  key={`checkbox-${index}}`}
                />
              </TableCell>
            )}
            {columns.map((column, columnIndex) => (
              <TableCell
                key={`${index}-${columnIndex}-${row[column.id] || ''}`}
                align={column.align || 'left'}
                padding={column.disablePadding ? 'none' : 'normal'}
                sx={
                  (column.maxWidth ? { maxWidth: column.maxWidth } : {},
                  column.sticky === 'left'
                    ? { ...sticky, right: 0 }
                    : column.sticky === 'right'
                    ? { ...sticky, left: 0 }
                    : column.sticky === 'right2'
                    ? { ...sticky, left: 156 }
                    : {})
                }
              >
                {column.children ? (
                  column.children(row, index)
                ) : (
                  <Typography
                    variant="ml"
                    color="text.primary"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {row[column.id]}
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        )
      })}
      <EmptyData emptyRows={rows.length} columnLength={columns.length} />
    </TableBody>
  )
}
