import _ from 'lodash'
import axios from 'axios'
import { Box, Typography } from '@mui/material'
import * as API from '../../services/apiCRUD'
const rootPath = '/inputHouse'

export const getDataDetail = async ({
  setLoading,
  setDialogContext,
  dataDetail,
  setDataDetail,
  id,
  navigate,
  setHosueList,
}) => {
  setLoading(true)
  try {
    const [result, dataList] = await Promise.all([
      axios.get(`${rootPath}/getDataDetail?id=${id}`),
      axios.get(`${rootPath}/getDataList?id=${id}`),
    ])

    if (result.data.status === 'error') {
      setDialogContext({
        type: 'fail',
        content: result?.data?.msg || 'error!',
        open: true,
        onConfirmClick: () => {
          setDialogContext((p) => ({ ...p, open: false }))
          navigate(`/scan`)
          setLoading(false)
        },
      })
    } else {
      setDataDetail(_.get(result, 'data.data', null))
      setHosueList(_.get(dataList, 'data.dataList', []))

      setDialogContext((p) => ({ ...p, open: false }))
      setLoading(false)
    }
  } catch (error) {
    console.error('Get info Dashboard error : ', error)
  }
  setLoading(false)
}

export const viewShippingMark = async ({
  setLoading,
  setDialogContext,
  id,
  setViewShippingData,
}) => {
  setLoading(true)
  try {
    const result = await axios.get(`${rootPath}/viewShippingMark?id=${id}`)

    if (result.data.status === 'error') {
      setDialogContext({
        type: 'fail',
        content: result?.data?.msg || 'error!',
        open: true,
        onConfirmClick: () => {
          setDialogContext((p) => ({ ...p, open: false }))
          setLoading(false)
        },
      })
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          // setViewShippingData(_.get(result, 'data.data', null))
          setDialogContext((p) => ({ ...p, open: false }))
          setLoading(false)
          resolve(_.get(result, 'data.data', null))
        }, 1000)
      })
    }
  } catch (error) {
    console.error('Get viewShippingMark error : ', error)
  }
  setLoading(false)
}

export const validateCj = async ({ cj_code, setLoading, setDialogContext }) => {
  setLoading(true)
  setDialogContext((p) => ({ ...p, open: false }))
  try {
    let { data } = await axios.post(`${rootPath}/validateCj`, { cj_code })

    if (data.status === 'error') {
      setDialogContext({
        type: 'fail',
        content: (
          <Box p={2} sx={{ wordBreak: 'break-word' }}>
            {data.msg}
          </Box>
        ),
        open: true,
        onConfirmClick: () => {
          setDialogContext((p) => ({ ...p, open: false }))
          setLoading(false)
        },
      })
    } else {
      setDialogContext((p) => ({ ...p, open: false }))

      return new Promise((resolve) => {
        setTimeout(() => {
          setLoading(false)
          resolve(_.get(data, 'data', null))
        }, 1000)
      })
    }
  } catch (error) {
    setLoading(false)
    console.error('create cj error : ', error)
  }
}

export const createHosueLabel = async ({
  dataObj,
  setLoading,
  setDialogContext,
  setOpenMessage,
  setMessagePop,
  navigate,
}) => {
  setLoading(true)
  setDialogContext((p) => ({ ...p, open: false }))
  try {
    let { data } = await axios.post(`${rootPath}/createHosueLabel`, {
      ...dataObj,
    })

    if (data.status === 'error') {
      setDialogContext({
        type: 'fail',
        content: (
          <Box p={2} sx={{ wordBreak: 'break-word' }}>
            {data.msg}
          </Box>
        ),
        open: true,
        onConfirmClick: () => {
          setDialogContext((p) => ({ ...p, open: false }))
          navigate(`/scan`)
          setLoading(false)
        },
      })
    } else {
      setDialogContext((p) => ({ ...p, open: false }))
      await setMessagePop((prev) => {
        return {
          ...prev,
          text: data.msg,
          type: 'success',
        }
      })
      await setOpenMessage(true)

      return new Promise((resolve) => {
        setTimeout(() => {
          setLoading(false)
          resolve(true)
        }, 1000)
      })
    }
  } catch (error) {
    setLoading(false)
    console.error('create cj error : ', error)
  }
}

export const removeHosueLabel = async ({
  dataObj,
  setLoading,
  setDialogContext,
  setOpenMessage,
  setMessagePop,
  navigate,
  loadData,
}) => {
  setLoading(true)
  setDialogContext((p) => ({ ...p, open: false }))
  try {
    let { data } = await axios.post(`${rootPath}/removeHosueLabel`, {
      ...dataObj,
    })

    if (data.status === 'error') {
      setDialogContext({
        type: 'fail',
        content: (
          <Box p={2} sx={{ wordBreak: 'break-word' }}>
            {data.msg}
          </Box>
        ),
        open: true,
        onConfirmClick: () => {
          setDialogContext((p) => ({ ...p, open: false }))
          setLoading(false)
          loadData()
        },
      })
    } else {
      setDialogContext((p) => ({ ...p, open: false }))
      await setMessagePop((prev) => {
        return {
          ...prev,
          text: data.msg,
          type: 'success',
        }
      })
      await setOpenMessage(true)

      return new Promise((resolve) => {
        setTimeout(() => {
          setLoading(false)
          resolve(true)
        }, 1000)
      })
    }
  } catch (error) {
    setLoading(false)
    console.error('create cj error : ', error)
  }
}

export const generateShipping = async ({
  dataObj,
  setLoading,
  setDialogContext,
  setOpenMessage,
  setMessagePop,
}) => {
  setLoading(true)
  setDialogContext((p) => ({ ...p, open: false }))
  try {
    let { data } = await axios.post(`${rootPath}/generateShipping`, {
      ...dataObj,
    })

    if (data.status === 'error') {
      setDialogContext({
        type: 'fail',
        content: (
          <Box p={2} sx={{ wordBreak: 'break-word' }}>
            {data.msg}
          </Box>
        ),
        open: true,
        onConfirmClick: () => {
          setDialogContext((p) => ({ ...p, open: false }))
        },
      })
    } else {
      setDialogContext((p) => ({ ...p, open: false }))
      await setMessagePop((prev) => {
        return {
          ...prev,
          text: data.msg,
          type: 'success',
        }
      })
      await setOpenMessage(true)

      return new Promise((resolve) => {
        setTimeout(() => {
          setLoading(false)
          resolve(true)
        }, 1000)
      })
    }
  } catch (error) {
    setLoading(false)
    console.error('create cj error : ', error)
  }
}

export const getSearch = async ({ rootPath }) => {
  try {
    let result = await API.findAll({ rootPath })
    return _.get(result, 'data.dataList', [])
  } catch (error) {
    // console.error(' getSearch  error : ', error)
  }
}
