import { Typography, Box } from '@mui/material'
import SearchInput from '../../components/formsUi/searchInput'

export const TextHeadTable = ({ setFilter, text, ...otherProps }) => {
  return (
    <>
      <Box
        {...otherProps}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="xlm">{text}</Typography>

        <SearchInput setFilter={setFilter} />
      </Box>
    </>
  )
}
export const LeftRight = ({ left, right, ...otherProps }) => {
  return (
    <>
      <Box
        {...otherProps}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {left}
        {right}
      </Box>
    </>
  )
}
