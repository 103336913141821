/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Typography,
} from '@mui/material'

import { EmptyData } from '../index'

export const TableDragAndDrop = ({
  columns,
  rows,
  showCheckbox,
  handleClick,
  isSelected,
  setDataTable,
  dropSortSticky,
  showCheckboxSticky,
}) => {
  const handleDragEnd = (result, provided) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    setDataTable((prev) => {
      const temp = [...prev.rows]
      const d = temp[result.destination.index]
      temp[result.destination.index] = temp[result.source.index]
      temp[result.source.index] = d

      return { ...prev, rows: temp }
    })
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {(droppableProvided) => (
          <TableBody
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {rows.map((row, index) => {
              const isItemSelected = showCheckbox && isSelected(row)
              const labelId = `enhanced-table-checkbox-${index}`
              const sticky = {
                position: 'sticky',
                background: 'white',
                boxShadow: `0px 4px 20px rgba(0, 0, 0, 0.05)`,
                zIndex: 1,
              }

              return (
                <>
                  <Draggable
                    key={`${index}`}
                    draggableId={`${index}`}
                    index={index}
                  >
                    {(draggableProvided, snapshot) => {
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={labelId}
                          selected={isItemSelected}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          style={{
                            ...draggableProvided.draggableProps.style,
                            background: snapshot.isDragging
                              ? 'rgba(245,245,245, 0.75)'
                              : 'none',
                          }}
                        >
                          <TableCell
                            align="left"
                            key={`${index}1`}
                            sx={
                              dropSortSticky === 'left'
                                ? { ...sticky, right: 0 }
                                : dropSortSticky === 'right'
                                ? { ...sticky, left: 0 }
                                : {}
                            }
                          >
                            <div
                              {...draggableProvided.dragHandleProps}
                              style={{ width: '20px' }}
                            >
                              <img src={`/icons/dragAndDrop.svg`}></img>
                            </div>
                          </TableCell>

                          {showCheckbox && (
                            <TableCell
                              padding="checkbox"
                              key={`checkbox-${index}}`}
                              sx={
                                showCheckboxSticky === 'left'
                                  ? { ...sticky, right: 0 }
                                  : showCheckboxSticky === 'right'
                                  ? { ...sticky, left: 0 }
                                  : {}
                              }
                            >
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                                key={`checkbox-${index}}`}
                              />
                            </TableCell>
                          )}

                          {columns.map((column, columnIndex) => (
                            <TableCell
                              key={`${index}-${columnIndex}-${
                                row[column.id] || ''
                              }`}
                              align={column.align || 'left'}
                              padding={
                                column.disablePadding ? 'none' : 'normal'
                              }
                              sx={
                                (column.maxWidth
                                  ? { maxWidth: column.maxWidth }
                                  : {},
                                column.sticky === 'left'
                                  ? { ...sticky, right: 0 }
                                  : column.sticky === 'right'
                                  ? { ...sticky, left: 0 }
                                  : {})
                              }
                            >
                              {column.children ? (
                                column.children(row, index)
                              ) : (
                                <Typography
                                  variant="ml"
                                  color="text.primary"
                                  sx={{ whiteSpace: 'nowrap' }}
                                >
                                  {row[column.id]}
                                </Typography>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    }}
                  </Draggable>
                </>
              )
            })}
            <EmptyData emptyRows={rows.length} columnLength={columns.length} />
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  )
}
