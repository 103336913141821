import React, { useState } from 'react'
import AlertDialogPc from '../components/alertDialog'
import AlertDialogHandheld from '../components/alertDialog/handheld'

const Dialog = React.createContext(null)

function DialogProvider(props) {
  const [dialogContext, setDialogContext] = useState({
    open: false,
    type: 'insert',
    device: '',
    title: '',
    icon: '',
    content: '',
    agreeText: '',
    disagreeText: '',
    color: 'primary',
    disabledBtn: false,
    maxWidth: '',
  })

  const handleClose = () => {
    setDialogContext({ ...dialogContext, open: false })
  }

  return (
    <Dialog.Provider
      value={{
        dialogContext,
        setDialogContext,
      }}
      {...props}
    >
      {dialogContext.device === 'handheld' ? (
        <AlertDialogHandheld
          open={dialogContext.open}
          setOpen={setDialogContext}
          handleClose={handleClose}
          title={dialogContext.title}
          content={dialogContext.content}
          agreeText={dialogContext.agreeText}
          disagreeText={dialogContext.disagreeText}
          type={dialogContext.type}
          onConfirmClick={dialogContext.onConfirmClick}
          onCancelClick={dialogContext.onCancelClick}
          disabledBtn={dialogContext.disabledBtn}
        />
      ) : (
        <AlertDialogPc
          open={dialogContext.open}
          setOpen={setDialogContext}
          handleClose={handleClose}
          title={dialogContext.title}
          content={dialogContext.content}
          agreeText={dialogContext.agreeText}
          disagreeText={dialogContext.disagreeText}
          type={dialogContext.type}
          onConfirmClick={dialogContext.onConfirmClick}
          onCancelClick={dialogContext.onCancelClick}
          maxWidth={dialogContext.maxWidth}
        />
      )}

      {props.children}
    </Dialog.Provider>
  )
}

function useDialogContext() {
  const dialogContext = React.useContext(Dialog)
  if (dialogContext === undefined) {
    throw new Error('useGlobalContext must be used within a UserProvider')
  }

  return dialogContext
}

export { DialogProvider, useDialogContext }
