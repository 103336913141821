import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
export const typeAll = [
  {
    type: 'content',
    icon: '',
    title: 'content',
    content: 'content',
    disagreeText: 'Cancel',
    agreeText: 'Save',
    color: 'primary',
  },
  {
    type: 'insert',
    icon: '',
    title: 'ยืนยันบันทึกข้อมูล',
    content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
    disagreeText: 'ยกเลิก',
    agreeText: 'บันทึก',
    color: 'primary',
  },
  {
    type: 'update',
    icon: '',
    title: 'ยืนยันแก้ไขข้อมูล',
    content: 'คุณต้องการแก้ไขข้อมูลนี้หรือไม่',
    disagreeText: 'ยกเลิก',
    agreeText: 'แก้ไข',
    color: 'primary',
  },
  {
    type: 'delete',
    icon: '',
    title: 'Confirm delete data',
    content: 'Do you want to delete this item?',
    disagreeText: 'Cancel',
    agreeText: 'Delete',
    color: 'error',
  },
  {
    type: 'success',
    icon: <CheckCircleOutlinedIcon color="primary" />,
    title: 'สำเร็จ',
    content: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
    disagreeText: '',
    agreeText: 'OK',
    color: 'primary',
  },
  {
    type: 'fail',
    icon: <ErrorOutlineOutlinedIcon color="error" />,
    title: 'Error',
    content: 'ระบบขัดข้อง',
    disagreeText: '',
    agreeText: 'OK',
    color: 'error',
  },
  {
    type: 'warning',
    icon: <ErrorOutlineOutlinedIcon color="warning" />,
    title: 'Warning',
    content: '',
    disagreeText: '',
    agreeText: 'OK',
    color: 'primary',
  },
  {
    type: 'error',
    icon: <ErrorOutlineOutlinedIcon color="error" />,
    title: 'Error',
    content: '',
    disagreeText: '',
    agreeText: 'OK',
    color: 'error',
  },
  {
    type: 'auth',
    icon: '',
    title: 'Log out',
    content: 'Do you want to log out of this system?',
    disagreeText: 'cancel',
    agreeText: 'Log out',
    color: 'error',
  },
  {
    type: 'image',
    icon: '',
    title: '',
    content: '',
    disagreeText: '',
    agreeText: '',
    color: 'error',
  },

  {
    type: 'search',
    icon: '',
    title: '',
    content: '',
    disagreeText: '',
    agreeText: '',
    color: 'error',
  },
  {
    type: 'blank',
    icon: '',
    title: '',
    content: '',
    disagreeText: '',
    agreeText: '',
    color: 'error',
  },
  {
    type: 'active',
    icon: <ErrorOutlineOutlinedIcon color="warning" />,
    title: '',
    content: '',
    disagreeText: 'Cancel',
    agreeText: '',
    color: 'primary',
  },
]
