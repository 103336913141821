/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Grid, Typography, Box, Button, Breadcrumbs } from '@mui/material'
import Link from '@mui/material/Link'

import Loading from '../../components/loading'
import Table from './table'

import { useDialogContext } from '../../context/dialog'

import { usePopAlertContext } from '../../context/popAlert'
import { getDataTable, handleDelete } from '../../services/apiCRUDMasterData'
import { initialForm, key, rootPath } from './form/schema'

import { InfoCard } from '../../components/styles'
import SearchInput from '../../components/formsUi/searchInput'
import AlertDialogPc from '../../components/alertDialog'

import { showMessage } from '../../utils/showMessage'
import FormUser from './form/form'

export default function User() {
  sessionStorage.setItem('pageTitle', 'USER MANAGEMENT')
  const { setDialogContext } = useDialogContext()
  const { setPopAlertcontext } = usePopAlertContext()

  const [loading, setLoading] = useState(true)

  const [filter, setFilter] = useState({
    limit: 100,
    page: 0,
    search: '',
    order: 'desc',
    orderBy: key,
  })

  const [dataTable, setDataTable] = useState({
    totalCount: 2,
    rows: [],
    selected: [],
  })
  const [dialogContextUser, setDialogContextUser] = useState({
    open: false,
    type: 'insert',
    device: '',
    title: '',
    icon: '',
    content: '',
    agreeText: '',
    disagreeText: '',
    color: 'primary',
    disabledBtn: false,
    maxWidth: '',
  })

  const loadData = ({ result }) => {
    showMessage({
      result,
      setPopAlertcontext,
      setDialogContext,
    })
    getDataTable({ rootPath, setLoading, setDataTable, filter })
    if (result?.data) {
      const {
        data: { status },
      } = result
      if (status === 'success')
        setDialogContextUser((p) => ({ ...p, open: false }))
    }
  }

  useEffect(() => {
    loadData({})
  }, [filter])

  const handleData = ({ type, data }) => {
    if (type !== 'Delete') {
      setDialogContextUser({
        type: 'blank',
        content: (
          <FormUser
            type={type}
            data={data}
            loadData={loadData}
            setDialogContextUser={setDialogContextUser}
          />
        ),
        open: true,
      })
    } else {
      handleDelete({
        key,
        rootPath,
        loadData,
        row: data,
        setDialogContext,
        setLoading,
      })
    }
  }

  const handleClose = () => {
    // setDialogContextUser({ ...dialogContextUser, open: false })
  }
  return (
    <Box>
      <AlertDialogPc
        open={dialogContextUser.open}
        setOpen={setDialogContextUser}
        handleClose={handleClose}
        title={dialogContextUser.title}
        content={dialogContextUser.content}
        agreeText={dialogContextUser.agreeText}
        disagreeText={dialogContextUser.disagreeText}
        type={dialogContextUser.type}
        onConfirmClick={dialogContextUser.onConfirmClick}
        onCancelClick={dialogContextUser.onCancelClick}
        maxWidth={dialogContextUser.maxWidth}
      />
      <Loading loading={loading} icon={true}>
        <Box pl={2} pt={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              DASHBOARD
            </Link>
            <Typography color="primary">USER MANAGEMENT</Typography>
          </Breadcrumbs>
        </Box>
        {/* ------------------ */}
        <InfoCard>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="lb" sx={{ color: '#7C7F7F' }}>
                  USER MANAGEMENT
                </Typography>

                <Box>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        sx={{ width: 100 }}
                        variant="primary2Outline"
                        onClick={() =>
                          handleData({ type: 'Create', data: initialForm })
                        }
                      >
                        Create
                      </Button>
                    </Grid>

                    <Grid item>
                      <SearchInput setFilter={setFilter} />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>

            {/* ------------------  Table */}

            <Grid item xs={12} md={12} mt={2}>
              <Table
                filter={filter}
                setFilter={setFilter}
                dataTable={dataTable}
                setDataTable={setDataTable}
                handleData={handleData}
              />
            </Grid>

            {/* ------------------ */}
          </Grid>
        </InfoCard>
      </Loading>
    </Box>
  )
}
