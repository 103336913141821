import React from 'react'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import WarningIcon from '@mui/icons-material/Warning'

export default function PopAlertConponent({
  type = '',
  open,
  setOpen,
  vertical = 'top',
  horizontal = 'right',
  device,
  children,
  autoHideDuration = 6000,
}) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  if (type === '') {
    return
  }

  const handleType = ({ type }) => {
    let typeObj
    switch (type) {
      case 'error':
        return (typeObj = {
          color: 'error',
          icon: <ErrorOutlineOutlinedIcon fontSize="inherit" color="error" />,
          bgColor: '#FFDFDC',
          borderColor: '#FDA29B',
        })
      case 'warning':
        return (typeObj = {
          color: 'warning',
          icon: <WarningIcon fontSize="inherit" color="warning" />,
          bgColor: '#FFE7C8',
          borderColor: '#E69830',
        })

      case 'success':
        return (typeObj = {
          color: 'success',
          icon: <CheckCircleOutlineIcon fontSize="inherit" color="success" />,
          bgColor: '#EAF2EA',
          borderColor: '#2E7D32',
        })

      default:
        return (typeObj = {
          color: 'info',
          icon: <ErrorOutlineOutlinedIcon fontSize="inherit" color="info" />,
          bgColor: '#CDEDFF',
          borderColor: '#0C53B7',
        })
    }
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
      >
        <Alert
          icon={handleType({ type }).icon}
          severity={type}
          onClose={handleClose}
          style={
            device == 'handheld'
              ? {
                  width: '300px',
                  marginBottom: '30px',
                  overflowWrap: 'break-word',
                  backgroundColor: handleType({ type }).bgColor,
                  border: `1px solid ${handleType({ type }).borderColor}`,
                }
              : {}
          }
        >
          {children}
        </Alert>
      </Snackbar>
    </>
  )
}
