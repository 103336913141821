import React from 'react'
import { TextField } from '@mui/material'
import { useField } from 'formik'

const TextFieldNumberWrapper = ({
  name,
  digit = 0,
  error,
  onChange,
  otherPropsSX,
  ...otherProps
}) => {
  const [field, meta, helpers] = useField(name)
  const { setValue } = helpers

  const handleChange = (e) => {
    const value = e.target.value
    const valueSplit = value.split('.')[1]
    const checkDigit = valueSplit || ''

    if (digit >= checkDigit.length) {
      setValue(value)
      onChange && onChange({ value })
    }
  }
  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: 'outlined',
    size: 'small',
    type: 'number',
    onChange: handleChange,
  }

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true
    configTextfield.helperText = meta.error
  }

  const handleBlurDecimalValue = (e) => {
    const { value } = e.target

    if (value !== '') {
      let val = parseFloat(value).toFixed(digit)

      setValue(val)
      onChange && onChange({ val })
    }
  }

  return (
    <TextField
      {...configTextfield}
      sx={{
        ...otherPropsSX,
        '& .MuiOutlinedInput-root': {
          backgroundColor: 'white',
        },
        borderRadius: '10px',
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: 'black',
          backgroundColor: '#f2f2f2',
          borderRadius: '10px',
        },
        '& .MuiFormLabel-root': {
          color: '#d1d1d1',
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
          {
            backgroundColor: 'transparent',
          },
        '& .MuiFormHelperText-root.Mui-error': {
          fontSize: '16px',
        },
      }}
      onWheel={(event) => event.currentTarget.querySelector('input')?.blur()}
      onBlur={(e) => handleBlurDecimalValue(e)}
    />
  )
}

export default TextFieldNumberWrapper
