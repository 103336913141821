import styled from 'styled-components'

export const Card = styled.div`
  background-color: white;
  // border-radius: 12px;
  padding: 16px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
  cursor: pointer;
  &:active {
    background-color: rgb(217, 217, 217);
  }
`
export const CardHeader = styled.div`
  background: #fff3e9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`

export const CardOther = styled.div`
  background: #d9d9d9;
  border-radius: ${(props) => (props.radius ? props.radius : '8px')};
  padding: ${(props) => (props.padding ? props.padding : '16px;')};
  border: ${(props) =>
    props.error ? '2px solid #AD0909 ' : '1px solid #d9d9d9 '};
  ${(props) => (props.other ? props.other : '')}
`

export const CardHeaderOutline = styled.div`
  border: 1px solid #e69830;
  background: #fff;
  border-radius: ${(props) => (props.radius ? props.radius : '8px')};
  padding: 20px;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
  ${(props) => (props.other ? props.other : '')}
`

export const WorkCard = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.cols == 4 ? '1fr 1fr 1fr' : '2fr 2fr 2fr 1fr'};
  grid-template-rows: ${(props) => (props.rows == 2 ? '1fr 2fr' : '2fr 1fr')};
  gap: 8px 8px;
  justify-items: stretch;
  align-items: stretch;

  background-color: ${(props) =>
    props.error == 'y' ? 'rgba(255, 0, 0, 0.1)' : 'white'};
  padding: 16px;
  box-shadow: 0px 10px 10px rgba(183, 183, 183, 0.25);
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`

export const InfoCard = styled.div`
  background-color: white;
  padding: 16px;
  margin: 16px;
  // margin-top: 10px;
  //   border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
`
export const CardDetail = styled.div`
  background-color: white;
  padding: ${(props) => (props.padding ? props.padding : '16px')};
  margin: ${(props) => (props.margin ? props.margin : '16px')};
  margin-top: 0px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
`
export const DividerLine = styled.hr`
  /* margin: 16px -16px; */
  border-top: 1px solid #d9d9d9;
`
