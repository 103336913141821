import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Grid, Typography, Box, Button, Breadcrumbs } from '@mui/material'
import Link from '@mui/material/Link'

import Loading from '../../components/loading'
import { InfoCard } from '../../components/styles'
import { useDialogContext } from '../../context/dialog'

import List from './list'
import Detail from './detail'
import { handleDataAll } from './handleData'
import { findDetail } from '../../services/apiCRUDMasterData'
import AlertDialogPc from '../../components/alertDialog'
import PopAlertConponent from '../../components/popAlert'

const InquiryDataDetail = () => {
  const { setDialogContext } = useDialogContext()

  let { id } = useParams()

  sessionStorage.setItem('pageTitle', 'INQUIRY DATA')

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    detail: {
      sys_no_cj: 0,
      cj_code: '',
      customer_code: '',
      create_by: '',
      create_date: '',
      status: '',
      shiping_mark_code: '',
    },
    list: [],
  })
  const [dialogContextCreate, setDialogContextCreate] = useState({
    open: false,
    type: 'insert',
    device: '',
    title: '',
    icon: '',
    content: '',
    agreeText: '',
    disagreeText: '',
    color: 'primary',
    disabledBtn: false,
    maxWidth: '',
  })

  const [dataSearch, setDataSearch] = useState({
    consigneeName: {},
    consigneeNameList: [],
    consigneeNameSearch: '',
    currency: {},
    currencyList: [],
    currencySearch: '',
  })
  const [openMessage, setOpenMessage] = useState(false)
  const [messagePop, setMessagePop] = useState({ text: '', type: '' })

  const navigate = useNavigate()
  const loadData = () => {
    findDetail({ url: `inquiryData/${id}`, setData, setLoading })
  }

  const handleData = ({ type, subType, item }) => {
    handleDataAll({
      id,
      type,
      subType,
      item,
      data: data.data,
      detail: data.detail,
      loadData,
      setDialogContext,
      setLoading,
      handleClose,
      setDialogContextCreate,
      dataSearch,
      setDataSearch,
      setOpenMessage,
      setMessagePop,
      navigate,
    })
  }

  useEffect(() => {
    loadData()
  }, [])

  const handleClose = () => {
    setDialogContextCreate((p) => ({ ...p, open: false }))
  }
  return (
    <Loading loading={loading} icon={true}>
      <PopAlertConponent
        type={messagePop.type}
        open={openMessage}
        setOpen={setOpenMessage}
        autoHideDuration={2500}
      >
        {messagePop.text}
      </PopAlertConponent>
      <AlertDialogPc
        open={dialogContextCreate.open}
        setOpen={setDialogContextCreate}
        handleClose={handleClose}
        title={dialogContextCreate.title}
        content={dialogContextCreate.content}
        agreeText={dialogContextCreate.agreeText}
        disagreeText={dialogContextCreate.disagreeText}
        type={dialogContextCreate.type}
        onConfirmClick={dialogContextCreate.onConfirmClick}
        onCancelClick={dialogContextCreate.onCancelClick}
        maxWidth={dialogContextCreate.maxWidth}
      />

      <Box pl={2} pt={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            DASHBOARD
          </Link>
          <Link underline="hover" color="inherit" href="/inquiryData">
            INQUIRY DATA
          </Link>
          <Typography color="primary"> DETAILS </Typography>
        </Breadcrumbs>
      </Box>
      <InfoCard>
        <Detail
          handleData={handleData}
          detail={data.detail}
          setLoading={setLoading}
        />
        <List handleData={handleData} list={data.list} />
      </InfoCard>
    </Loading>
  )
}

export default InquiryDataDetail
