import React from 'react'
// import PropTypes from 'prop-types'

import {
  Typography,
  Checkbox,
  TableSortLabel,
  TableRow,
  TableHead,
  TableCell,
} from '@mui/material'

export default function HeadTable(props) {
  const {
    sort,
    dropSort,
    showCheckbox,
    columns,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    showCheckboxSticky,
    dropSortSticky,
    backgroundTableHead,
    customDisabledSort,
    diabledHeadCheckbox,
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const sticky = {
    position: 'sticky',
    background: 'white',
    boxShadow: `0px 4px 20px rgba(0, 0, 0, 0.05)`,
    zIndex: 1,
  }

  return (
    <TableHead sx={{ background: backgroundTableHead }}>
      <TableRow style={{ paddingLeft: 5 }}>
        {dropSort && (
          <TableCell
            padding="checkbox"
            sx={
              dropSortSticky === 'left'
                ? { ...sticky, right: 0 }
                : dropSortSticky === 'right'
                ? { ...sticky, left: 0 }
                : {}
            }
          >
            <Typography
              variant="mm"
              color="text.gray"
              sx={{ whiteSpace: 'nowrap' }}
            >
              Seq
            </Typography>
          </TableCell>
        )}
        {showCheckbox && (
          <TableCell
            padding="checkbox"
            sx={
              showCheckboxSticky === 'left'
                ? { ...sticky, right: 0 }
                : showCheckboxSticky === 'right'
                ? { ...sticky, left: 0 }
                : {}
            }
          >
            {diabledHeadCheckbox ? (
              <></>
            ) : (
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
            )}
          </TableCell>
        )}
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={
              headCell.sticky === 'left'
                ? { ...sticky, right: 0 }
                : headCell.sticky === 'right'
                ? { ...sticky, left: 0 }
                : headCell.sticky === 'right2'
                ? { ...sticky, left: 156 }
                : {}
            }
          >
            {sort && headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography
                  variant="mm"
                  color="text.gray"
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {headCell.label}
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography variant="mm"> {headCell.label}</Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

// HeadTable.propTypes = {
//   columns: PropTypes.array.isRequired,
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// }
