import * as yup from 'yup'

export const validation = {
  text: yup.string('Enter your String').required('Required'),
  number: yup.number('Enter your Number').required('Required'),
  date: yup.date('Enter your Date').required('Required'),
  boolean: yup.boolean('Enter your Boolean').required('Required'),
  object: yup.object('Enter your Object').required('Required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Required'),
  password: yup
    .string('Enter your password')
    // .min(8, 'Password should be of minimum 8 characters length')
    .required('Required'),
}

export const allnumeric = (inputtxt) => {
  if (inputtxt.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
    return true
  } else {
    return false
  }
}

export const allinteger = (inputtxt) => {
  var numbers = /^[0-9]+$/
  if (inputtxt.match(numbers)) {
    return true
  } else {
    return false
  }
}
