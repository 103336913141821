import { Outlet } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'

export default function LayoutLogin() {
  return (
    <>
      <Box>
        <CssBaseline />

        <Outlet />
      </Box>
    </>
  )
}
