import React, { useState, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { TextField, MenuItem, Tooltip, Box } from '@mui/material'
import { useField } from 'formik'
import { SearchOutlined, CloseOutlined } from '@mui/icons-material'
import CircularProgress from '@mui/material/CircularProgress'
import _ from 'lodash'
const SelectSearchWrapper = ({
  value,
  valueSearch,
  name,
  valueKey,
  labelKey,
  options,
  defaultOptions,
  onChange = () => {},
  disabled,
  menuItemDisabled = [],
  handleDebounce = () => {},
  labelValue = false,
  ...otherProps
}) => {
  const [loading, setLoading] = useState(false)
  const [field, mata] = useField(name)
  const [showValue, setShowValue] = useState('')

  const debounced = useDebouncedCallback(async (e) => {
    setLoading(true)
    await handleDebounce({ name, value: e.target.value })
    setTimeout(() => setLoading(false), 400)
  }, 1000)
  const configTextfield = {
    ...field,
    ...otherProps,
    select: !disabled,
    value: showValue,
    disabled: disabled,
    fullWidth: true,
    variant: 'outlined',
    size: 'small',
  }
  if (mata && mata.touched && mata.error) {
    configTextfield.error = true
    configTextfield.helperText = mata.error
  }
  let valueDefault = []
  if (value && options.length)
    valueDefault = options.filter((d) => d[valueKey] === value)

  if (value !== '' && options.length !== 0) {
    options = [...options, { ...defaultOptions, hidden: true }]
  } else if (value !== '' && valueDefault.length === 0) {
    options = [
      ...options,
      { ...defaultOptions, hidden: true },
      { [valueKey]: 'NoData', [labelKey]: 'NoData' },
    ]
  } else if (value === '' && options.length === 0) {
    options = [...options, { [valueKey]: 'NoData', [labelKey]: 'NoData' }]
  }

  const optionsUniq = _.uniqBy(options, (e) => e[valueKey])

  useEffect(() => {
    if (!field?.value) setShowValue('')
    if (field?.value !== undefined && showValue !== field?.value) {
      if (labelValue && disabled) {
        const result = options.find((data) => data[valueKey] === field?.value)
        setShowValue(_.get(result, `${labelKey}`, ''))
      } else {
        setShowValue(`${field?.value}`)
      }
    }
  }, [value, field])
  return (
    <>
      <TextField
        {...configTextfield}
        SelectProps={
          value
            ? {
                IconComponent: () => null,
              }
            : {}
        }
        InputProps={{
          endAdornment: (
            <>
              {!disabled && value && (
                <CloseOutlined
                  cursor="pointer"
                  onClick={async () => {
                    onChange({
                      name,
                      value: '',
                      option: {},
                    })
                    await handleDebounce({ name, value: '' })
                  }}
                />
              )}
            </>
          ),
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'white',
          },
          borderRadius: '10px',
          '& .MuiOutlinedInput-root.Mui-disabled': {
            WebkitTextFillColor: 'black',
            backgroundColor: '#f2f2f2',
            borderRadius: '10px',
          },
          '& .MuiFormLabel-root': {
            color: '#d1d1d1',
          },
          '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
            {
              backgroundColor: 'transparent',
            },
          '& .MuiFormHelperText-root.Mui-error': {
            fontSize: '16px',
          },
        }}
      >
        {/* <Tooltip title="Limit show 50 item" placement="top">
          <TextField
            defaultValue={valueSearch}
            placeholder="Search"
            fullWidth={true}
            size={'small'}
            onChange={debounced}
            autoFocus
            InputProps={{ endAdornment: <SearchOutlined /> }}
          />
        </Tooltip> */}

        {/* <MenuItem onKeyDown={(e) => e.stopPropagation()}> */}
        <Box pl={2} pr={2} onKeyDown={(e) => e.stopPropagation()}>
          <Tooltip title="Limit show 50 item" placement="top">
            <TextField
              defaultValue={valueSearch}
              placeholder="Search"
              fullWidth={true}
              size={'small'}
              onChange={debounced}
              autoFocus
              InputProps={{ endAdornment: <SearchOutlined /> }}
            ></TextField>
          </Tooltip>
        </Box>

        {/* </MenuItem> */}
        {loading ? (
          <MenuItem key={'loading'} value={'loading'} disabled>
            <CircularProgress /> loading
          </MenuItem>
        ) : (
          optionsUniq.map((item, index) => (
            <MenuItem
              style={{ display: item?.hidden ? 'none' : '' }}
              key={index}
              value={item[valueKey]}
              onClick={() => {
                onChange({
                  name,
                  value: item[valueKey],
                  index: index,
                  option: {
                    [labelKey]: item[labelKey],
                    [valueKey]: item[valueKey],
                  },
                })
              }}
              disabled={
                item[labelKey] === 'NoData' ||
                (menuItemDisabled.includes(item[valueKey]) &&
                  showValue !== item[valueKey])
              }
            >
              {item[labelKey]}
            </MenuItem>
          ))
        )}
      </TextField>
    </>
  )
}
export default SelectSearchWrapper
