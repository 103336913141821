import * as yup from 'yup'

export const initialForm = {}

export const validationSchema = yup.object().shape({})

export const initialValues = {
  type: 'insert',
  data: initialForm,
}

export const rootPath = '/inquiryData'
export const key = 'id'
