import React, { useState, useEffect } from 'react'
import { TextField } from '@mui/material'
import { useField } from 'formik'
import dayjs from 'dayjs'
import { useDebouncedCallback } from 'use-debounce'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { dayFormatInput } from '../../../utils/dayjs'

import { CalendarTodayOutlined } from '@mui/icons-material'

const DatePickerWrapper = ({
  label,
  name,
  disabled,
  inputFormat = 'dd/MM/yyyy',
  handleOnChange,
  minDate = '',
  maxDate = '',
  ...otherProps
}) => {
  const [field, meta, helpers] = useField(name)
  const [value, setValue] = useState('')
  const [valueOld, setValueOld] = useState('')

  const debounced = useDebouncedCallback(async (newValue) => {
    setValue(newValue)

    const value = dayjs(dayjs(newValue)).format('YYYY-MM-DD')

    if (value !== 'Invalid Date') {
      setValueOld(value)
      helpers.setValue(value)
      handleOnChange && handleOnChange(value)
    } else if (newValue === null) {
      setValueOld('')
      helpers.setValue('')
      handleOnChange && handleOnChange('')
    }
  }, 500)

  useEffect(() => {
    if (field?.value === '' && value === '') setValue(null)
    if (field?.value !== valueOld) {
      const valueFormat = dayFormatInput(field?.value)
      setValue(valueFormat)
      setValueOld(valueFormat)
    }
  }, [field])

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          minDate={minDate}
          maxDate={maxDate}
          label={label}
          components={{
            OpenPickerIcon: CalendarTodayOutlined,
          }}
          disabled={disabled}
          inputFormat={inputFormat}
          //   value={'2023-02-12'}
          value={value}
          onChange={debounced}
          renderInput={(params) => (
            <>
              <TextField
                sx={
                  disabled
                    ? { backgroundColor: '#f2f2f2', borderRadius: '10px' }
                    : {
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: 'white',
                        },
                        '& .MuiFormHelperText-root.Mui-error': {
                          fontSize: '16px',
                        },
                        '& .MuiFormLabel-root': {
                          color: '#d1d1d1',
                        },
                      }
                }
                {...params}
                size={'small'}
                fullWidth
                error={Boolean(meta.touched && meta.error)}
                helperText={meta.touched && meta.error}
              />
            </>
          )}
        />
      </LocalizationProvider>
    </>
  )
}

export default DatePickerWrapper
