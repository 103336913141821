import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Typography,
} from '@mui/material'
import { typeAll } from './text'
import { Clear } from '@mui/icons-material'

export const handleOnClose = (dialogType, handleClose) => {
  if (!(dialogType.type === 'fail' || dialogType.type === 'success'))
    handleClose()
}
export default function AlertDialog(props) {
  const {
    open,
    setOpen,
    handleClose,
    title,
    content,
    disagreeText,
    type,
    onConfirmClick,
    onCancelClick,
    maxWidth,
    agreeText,
  } = props

  const onCancel = () => {
    setOpen((prev) => ({
      ...prev,
      open: false,
    }))
  }
  const dialogType = typeAll.find((item) => item.type === type)

  return (
    <Box>
      <Dialog
        data-testid="alert-dialog"
        fullWidth={true}
        maxWidth={!maxWidth ? 'xs' : maxWidth}
        open={open}
        onClose={() => handleOnClose(dialogType, handleClose)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {type === 'search' ? (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                mt: 1,
                mr: 1,
              }}
            >
              <IconButton
                sx={{ color: 'white', backgroundColor: '#FF4D00' }}
                size={'small'}
                onClick={onCancel}
                disableRipple
              >
                <Clear fontSize="small" />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 2,
              }}
            >
              <Typography variant="m">
                {title ? title : dialogType?.title}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 4,
              }}
            >
              {dialogType?.name === 'select' ? (
                content
              ) : (
                <> {content ? content : dialogType?.content}</>
              )}
            </Box>
          </>
        ) : type === 'blank' ? (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                p: 2,
              }}
            >
              <Box sx={{ lineHeight: 2.5 }}>
                {dialogType?.icon !== '' && dialogType?.icon}
              </Box>
              <Typography variant="lm">
                {title ? title : dialogType?.title}
              </Typography>
              {content ? content : dialogType?.content}
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                // justifyContent: 'center',
                m: 2,
                // mt: 2,
                // mb: 1,
              }}
            >
              <Box sx={{ lineHeight: 2.5 }}>
                {dialogType?.icon !== '' && dialogType?.icon}
              </Box>
              <Typography sx={{ ml: 1 }} variant="h6">
                {title ? title : dialogType?.title}
              </Typography>
            </Box>
            {dialogType?.name === 'select' ? (
              content
            ) : (
              <Box sx={{ mx: 3, wordBreak: 'break-word' }}>
                {content ? content : dialogType?.content}
              </Box>
            )}
            <DialogActions>
              {dialogType?.disagreeText && (
                <Button
                  data-testid="btn-cancel-dialog"
                  sx={{ mx: 1, mb: 1, minWidth: 100 }}
                  variant="textBtnCancel"
                  onClick={onCancelClick || onCancel}
                  // color={'dialogType?.color'}
                >
                  <Typography variant="mm">
                    {disagreeText ? disagreeText : dialogType?.disagreeText}
                  </Typography>
                </Button>
              )}
              <Button
                data-testid="btn-confirm-dialog"
                sx={{ mx: 1, mb: 1, minWidth: 100 }}
                variant="text"
                onClick={onConfirmClick || onCancel}
                color={dialogType?.color}
                endIcon={<></>}
              >
                <Typography variant="mm">
                  {agreeText ? agreeText : dialogType?.agreeText}
                </Typography>
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  )
}
