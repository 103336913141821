import React from 'react'
import _ from 'lodash'

import numeral from 'numeral'
import { Box, Typography, Grid, Button } from '@mui/material'

import { InfoCard } from '../../components/styles'
import { useAuthenticated } from '../../context/authenticated'
const List = ({ handleData, list }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} ml={4} mr={4}>
        <Typography variant={'lb'} sx={{ color: '#7C7F7F' }}>
          HOUSE AIR WAYBILL LIST ({list.length})
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {list.map((item, index) => (
          <InfoCard>
            <CardItem handleData={handleData} item={item} />
          </InfoCard>
        ))}
      </Grid>
    </Grid>
  )
}

export default List

const CardItem = ({ handleData, item }) => {
  const { contextAuth } = useAuthenticated()
  const admin = _.get(contextAuth, 'decode.data.admin', '')
  return (
    <>
      <Box sx={{ position: 'relative', p: 2, mb: 2 }}>
        <Grid container>
          <Grid item xs={12} sm={3}>
            <Typography variant="mel" component="div">
              HOUSE LABEL
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography variant="lb" component="div" color="primary">
              {item?.house_code || '-'}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="mel" component="div">
              SHIPPER
            </Typography>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Typography variant="lb" component="div" color="black">
              {item?.shipper_name || '-'}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="mel" component="div">
              DESCRIPTION
            </Typography>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Typography variant="lb" component="div" color="black">
              {item?.description || '-'}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="mel" component="div">
              CONSIGNEE ADDRESS
            </Typography>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Typography variant="lb" component="div" color="black">
              {item?.consignee || '-'}
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ border: '1px solid #7C7F7F', my: 2, p: 2 }}>
          <Grid container>
            <Grid item sm={3}>
              <Typography variant="sel" component="div">
                Qty ({item?.qty_unit || '-'})
              </Typography>
              <Typography variant="lb" component="div" color="black">
                {item?.qty || '-'}
              </Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography variant="sel" component="div">
                CHARGEABLE WEIGHT (g)
              </Typography>
              <Typography variant="lb" component="div" color="black">
                {numeral(parseFloat(item?.chargeable_weigth)).format('0,0.000')}
              </Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography variant="sel" component="div">
                CUSTOM VALUE
              </Typography>
              <Typography variant="lb" component="div" color="black">
                {numeral(parseFloat(item?.custom_value)).format('0,0.000')}{' '}
                {item?.currency_code}
              </Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography variant="sel" component="div">
                CREATE BY
              </Typography>
              <Typography variant="lb" component="div" color="black">
                {item?.create_by || '-'}
              </Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography variant="sel" component="div">
                DATE
              </Typography>
              <Typography variant="lb" component="div" color="black">
                {item?.create_date || '-'}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            top: '12px',
            right: '12px',
            zIndex: 1,
          }}
        >
          <Button
            variant="Outline2"
            onClick={() =>
              handleData({
                type: 'Print',
                subType: 'One',
                item: item,
              })
            }
            sx={{ mx: 1 }}
          >
            <img src={`/icons/print.svg`}></img>
          </Button>

          {admin === 'Y' ? (
            <Button
              variant="Outline2"
              onClick={() =>
                handleData({
                  type: 'Edit',
                  subType: 'One',
                  item: item,
                })
              }
              style={{ borderColor: '#7C7F7F' }}
              sx={{ mx: 1 }}
            >
              <img src={`/icons/edit.svg`}></img>
            </Button>
          ) : (
            ''
          )}
          {admin === 'Y' ? (
            <Button
              variant="Outline2"
              style={{ borderColor: '#7C7F7F' }}
              sx={{ mx: 1 }}
              onClick={() =>
                handleData({
                  type: 'Delete',
                  subType: 'One',
                  item: item,
                })
              }
            >
              <img src={`/icons/delete.svg`}></img>
            </Button>
          ) : (
            ''
          )}
        </Box>
      </Box>
    </>
  )
}
