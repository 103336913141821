import React from 'react'
import { TextField } from '@mui/material'
import { useField } from 'formik'

const TextFieldWrapper = ({
  name,
  handleOnChange,
  fullWidth = true,
  upperCase = false,
  variant = 'outlined',
  ...otherProps
}) => {
  const [field, mata, helpers] = useField(name)
  const { setValue } = helpers

  const handleChange = (e) => {
    let value = e.target.value
    value = upperCase ? value.toUpperCase() : value
    setValue(value)
    handleOnChange && handleOnChange(value)
  }
  const styles = {
    floatingLabelFocusStyle: {
      color: 'red',
    },
  }
  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: fullWidth,
    variant: variant,
    size: 'small',
    onChange: handleChange,
    // InputLabelProps={
    //   className: styles.floatingLabelFocusStyle,
    // }
  }

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true
    configTextfield.helperText = mata.error
  }

  return (
    <TextField
      {...configTextfield}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: 'white',
        },
        borderRadius: '10px',
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: 'black',
          backgroundColor: '#f2f2f2',
          borderRadius: '10px',
        },
        '& .MuiFormLabel-root': {
          color: '#d1d1d1',
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
          {
            backgroundColor: 'transparent',
          },
        '& .MuiFormHelperText-root.Mui-error': {
          fontSize: '16px',
        },
      }}
    />
  )
}

export default TextFieldWrapper
