/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import _ from 'lodash'

import { useAuthenticated } from '../../context/authenticated'

import DataTable from '../../components/table'
import { Typography, Box, Button } from '@mui/material'

import IconButton from '@mui/material/IconButton'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'

export default function Table({
  filter,
  setFilter,
  dataTable,
  setDataTable,
  handleData,
}) {
  const { contextAuth } = useAuthenticated()

  const id = _.get(contextAuth, 'decode.data.id', '')
  const columns = [
    {
      id: 'id',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'System No.',
      //   children: (r) => <Typography color="primary">{r.id}</Typography>,
    },
    {
      id: 'username',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'Username',
    },
    {
      id: 'password',
      sort: false,
      align: 'center',
      disablePadding: false,
      label: 'Password',
      children: (r) => <ShowPassword r={r} />,
    },
    {
      id: 'firstname',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'First Name',
    },
    {
      id: 'lastname',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'Last Name',
    },
    {
      id: 'admin',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'Role Admin',
    },
    {
      id: 'create_date',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'Create Date Time',
    },
    {
      id: 'create_by',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'Create By',
    },
    {
      id: '',
      sort: false,
      align: 'center',
      disablePadding: false,
      label: 'Action',
      children: (r) => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: 140,
          }}
        >
          <Button
            variant="Outline2"
            onClick={() =>
              handleData({
                type: 'Edit',
                data: { ...r, password: atob(r.password) },
              })
            }
          >
            <img src={`/icons/edit.svg`}></img>
          </Button>

          <Button
            disabled={id === r.id}
            variant="Outline2"
            onClick={() => handleData({ type: 'Delete', data: r })}
          >
            <img src={`/icons/delete.svg`}></img>
          </Button>
        </Box>
      ),
    },
  ]

  return (
    <>
      <DataTable
        dataTable={{
          ...dataTable,
          columns: columns,
        }}
        setDataTable={setDataTable}
        filter={filter}
        setFilter={setFilter}
        showCheckbox={false}
      />
    </>
  )
}
export const ShowPassword = ({ r }) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        variant="ml"
        color="text.primary"
        sx={{ whiteSpace: 'nowrap' }}
      >
        {showPassword ? atob(r.password) : '*************'}
      </Typography>

      <IconButton onClick={() => setShowPassword(!showPassword)}>
        {showPassword ? (
          <VisibilityOff sx={{ color: '#7C7F7F' }} />
        ) : (
          <Visibility sx={{ color: '#7C7F7F' }} />
        )}
      </IconButton>
    </Box>
  )
}
