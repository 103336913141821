import React, { useState, useRef, useEffect } from 'react'
import Loading from '../../components/loading'
import { Box, Typography, Grid, Button, IconButton } from '@mui/material'
import Card from '../../components/cardWorkingList'
import { Clear } from '@mui/icons-material'
import CardHouseLabel from '../../components/cardHouseLabel'
import InputScanbarcode from '../../components/scanBarcode/inputScanbarcode'
import Drawer from '../../components/drawer'
import BarcodeReader from '../../components/barcodeReader'
import { useDialogContext } from '../../context/dialog'
import viewShipping from './viewShipping'
import { BrowserMultiFormatReader } from '@zxing/library'
import { initialForm, key, rootPath } from './form/schema'
import { showMessage } from '../../utils/showMessage'
import { usePopAlertContext } from '../../context/popAlert'
import PopAlertConponent from '../../components/popAlert'
import FormCreate from './form/form'
import { useSearchParams, useNavigate } from 'react-router-dom'
import AlertDialogPc from '../../components/alertDialog'
import numeral from 'numeral'

import {
  getDataDetail,
  viewShippingMark,
  validateCj,
  removeHosueLabel,
} from './handleData'
import { generateShipping } from '../scanTracking/handleData'

const codeReader = new BrowserMultiFormatReader()

const InputHouse = () => {
  const [dataDetail, setDataDetail] = useState({
    id: '',
    cj_code: '',
    customer_code: '',
    status: '-',
    create_date: '',
    create_by: '',
    qty: 0,
    shiping_mark_code: '',
    origin: '',
    destination: '',
  })

  const [dataSearch, setDataSearch] = useState({
    consigneeName: {},
    consigneeNameList: [],
    consigneeNameSearch: '',
    currency: {},
    currencyList: [],
    currencySearch: '',
  })

  const [hosueList, setHosueList] = useState([])
  const [viewShippingData, setViewShippingData] = useState({
    shiping_mark_code: '',
    quantit_in_boxy: '',
    cj_code: '',
  })

  const [dialogContextCreate, setDialogContextCreate] = useState({
    open: false,
    type: 'insert',
    device: '',
    title: '',
    icon: '',
    content: '',
    agreeText: '',
    disagreeText: '',
    color: 'primary',
    disabledBtn: false,
    maxWidth: '',
  })

  sessionStorage.setItem('pageTitle', 'Input House Air Waybill')
  const { setDialogContext } = useDialogContext()
  const { setPopAlertcontext } = usePopAlertContext()
  const inputRefBarcode = useRef()
  const [searchParams] = useSearchParams()
  let id = searchParams.get('params')
  const navigate = useNavigate()
  const [openScan, setOpenScan] = useState(false)
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [openMessage, setOpenMessage] = useState(false)
  const [messagePop, setMessagePop] = useState({ text: '', type: '' })

  const handleKeyPress = async (e) => {
    let barcode = e.target.value
    if (e.key === 'Enter') {
      if (barcode.trim() === '') return -1

      let res = await validateCj({
        cj_code: barcode,
        setLoading,
        setDialogContext,
      })

      if (!res) return

      if (res.status === 2)
        return navigate(`/inputhouse?params=${res.sys_no_cj}`)

      if (res.status === 1) {
        setDialogContext({
          type: 'content',
          device: 'tablet',

          title: (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <svg
                  width="41"
                  height="34"
                  viewBox="0 0 41 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 34L20.5 0L41 34H0ZM20.5 28.6316C21.028 28.6316 21.471 28.4598 21.8288 28.1162C22.1866 27.7726 22.3649 27.3479 22.3636 26.8421C22.3636 26.3351 22.1847 25.9098 21.8269 25.5662C21.4691 25.2226 21.0268 25.0514 20.5 25.0526C19.972 25.0526 19.529 25.2244 19.1712 25.568C18.8134 25.9116 18.6351 26.3363 18.6364 26.8421C18.6364 27.3491 18.8153 27.7744 19.1731 28.118C19.5309 28.4616 19.9732 28.6328 20.5 28.6316ZM18.6364 23.2632H22.3636V14.3158H18.6364V23.2632Z"
                    fill="#FF8E00"
                  />
                </svg>
                <Typography sx={{ mx: 2 }} variant="lb">
                  Generate shipping mark no.
                </Typography>
              </Box>
            </>
          ),
          agreeText: 'Confirm',
          disagreeText: 'Cancel',
          onConfirmClick: () =>
            hendleGenerateShipping({
              id: res.sys_no_cj,
              cj_code: res.cj_code,
              create_date_raw: res.create_date_raw,
            }),
          content: (
            <Box sx={{ display: 'grid', alignItems: 'center', mt: 2 }}>
              <Typography sx={{ mx: 2 }} variant="mr" color={'grey'}>
                Ref. CJ Tracking
              </Typography>
              <Typography sx={{ mx: 2 }} variant="lb" color={'primary'}>
                {res.cj_code}
              </Typography>
            </Box>
          ),
          open: true,
        })
      }
    }
  }

  const hendleGenerateShipping = async ({ id, cj_code, create_date_raw }) => {
    const dataObj = {
      id: id,
      cj_code: cj_code,
      createdate_cj: create_date_raw,
    }

    const res = await generateShipping({
      dataObj,
      setLoading,
      setDialogContext,
      setOpenMessage,
      setMessagePop,
    })

    if (res) {
      await navigate(`/inputhouse?params=${id}`)
    }
  }

  const handleScan = (e) => {
    e.preventDefault()
    setOpenScan(true)
  }

  const handleResultScan = async (result) => {
    if (!!result) {
      setInputValue(result)
      setOpenScan(false)
    }
  }

  const onClickViewShipping = async () => {
    let datas = dataDetail

    let res = await viewShippingMark({
      setLoading,
      setDialogContext,
      id: datas.shiping_mark_code,
      setViewShippingData,
    })

    await viewShipping({
      setDialogContext,
      Box,
      Typography,
      Grid,
      Button,
      viewShippingData: { ...res },
    })
  }

  const loadData = async () => {
    await getDataDetail({
      setLoading,
      setDialogContext,
      dataDetail,
      setDataDetail,
      id,
      navigate,
      setHosueList,
    })
  }

  const onClickAction = async ({ type, data }) => {
    await setDialogContextCreate({
      type: 'blank',
      content: (
        <FormCreate
          type={type}
          data={data}
          dataDetail={dataDetail}
          loadData={loadData}
          setDialogContextUser={setDialogContextCreate}
          handleClose={handleClose}
          dataSearch={dataSearch}
          setDataSearch={setDataSearch}
          setLoading={setLoading}
          setDialogContext={setDialogContext}
          setOpenMessage={setOpenMessage}
          setMessagePop={setMessagePop}
          navigate={navigate}
        />
      ),
      open: true,
    })
  }

  const handleClose = () => {
    setDialogContextCreate((p) => ({ ...p, open: false }))
  }

  const handleEditHouse = async (hosueList) => {
    const dataForm = {
      house_code: hosueList.house_code,
      consigneeName: hosueList.consignee_code,
      shipper: hosueList.shipper_name,
      qty: hosueList.qty,
      chargeableWeigth: numeral(parseFloat(hosueList.chargeable_weigth)).format(
        '0.000'
      ),
      date: hosueList.house_date,
      customValue: numeral(parseFloat(hosueList.custom_value)).format('0.000'),
      currency: hosueList.currency,
      description: hosueList.description,
    }

    onClickAction({ type: 'edit', data: dataForm })
  }

  const handleRemoveHouse = async (hosueList) => {
    setDialogContext({
      type: 'content',
      device: 'tablet',
      title: (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ mx: 2 }} variant="lb">
              Confirm remove House Label
            </Typography>
          </Box>
        </>
      ),
      agreeText: 'Remove',
      disagreeText: 'Cancel',
      onConfirmClick: () =>
        handleConfirmRemove({
          house_code: hosueList.house_code,
          sys_no_cj: hosueList.sys_no_cj,
        }),
      content: (
        <Box sx={{ display: 'grid', alignItems: 'center', mt: 2 }}>
          <Typography sx={{ mx: 2 }} variant="mr" color={'grey'}>
            Do you want to remove House Label?
          </Typography>
          <Typography sx={{ mx: 2 }} variant="lb" color={'primary'}>
            {hosueList.house_code}
          </Typography>
        </Box>
      ),
      open: true,
    })
  }

  const handleConfirmRemove = async ({ house_code, sys_no_cj }) => {
    const dataObj = {
      id: house_code,
      sys_no_cj: sys_no_cj,
    }
    const res = await removeHosueLabel({
      dataObj,
      setLoading,
      setDialogContext,
      setOpenMessage,
      setMessagePop,
      navigate,
      loadData,
    })
    if (res) {
      await getDataDetail({
        setLoading,
        setDialogContext,
        dataDetail,
        setDataDetail,
        id,
        navigate,
        setHosueList,
      })
    }
  }

  useEffect(() => {
    if (id) {
      getDataDetail({
        setLoading,
        setDialogContext,
        dataDetail,
        setDataDetail,
        id,
        navigate,
        setHosueList,
      })
    }
  }, [id])

  return (
    <Loading loading={loading} icon={true}>
      <PopAlertConponent
        type={messagePop.type}
        open={openMessage}
        setOpen={setOpenMessage}
        autoHideDuration={2500}
      >
        {messagePop.text}
      </PopAlertConponent>

      <AlertDialogPc
        open={dialogContextCreate.open}
        setOpen={setDialogContextCreate}
        handleClose={handleClose}
        title={dialogContextCreate.title}
        content={dialogContextCreate.content}
        agreeText={dialogContextCreate.agreeText}
        disagreeText={dialogContextCreate.disagreeText}
        type={dialogContextCreate.type}
        onConfirmClick={dialogContextCreate.onConfirmClick}
        onCancelClick={dialogContextCreate.onCancelClick}
        maxWidth={dialogContextCreate.maxWidth}
      />

      <Drawer open={openScan} setOpen={setOpenScan} margin={0} width="100vw">
        <div
          style={{
            padding: '20px',
            position: 'fixed',
            zIndex: 1,
            background: 'white',
            width: '100vw',
            // height: '100vh',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'end',
            }}
            pl={3}
          >
            <Typography variant="h3" color={'common.black'}>
              Scan Barcode
            </Typography>
            <IconButton
              color="primary"
              size="small"
              onClick={() => {
                setOpenScan(false)
                codeReader.reset()
                if (inputRefBarcode.current) {
                  inputRefBarcode.current.focus()
                }
              }}
            >
              <Clear fontSize="large" />
            </IconButton>
          </Box>
        </div>
        <Box>
          <BarcodeReader
            handleResultScan={handleResultScan}
            openScan={openScan}
            codeReader={codeReader}
          />
        </Box>
      </Drawer>

      <InputScanbarcode
        inputRefBarcode={inputRefBarcode}
        handleScan={(e) => handleScan(e)}
        onKeyPress={handleKeyPress}
        inputValue={inputValue}
        setInputValue={setInputValue}
        placeholder={'Scan barcode CJ'}
      />

      <Box sx={{ pt: '80px !important' }}>
        <Box p={2}>
          <Typography variant={'lb'} sx={{ color: '#7C7F7F' }}>
            DETAILS
          </Typography>
        </Box>
        <Box>
          <Card
            id={dataDetail.id}
            cj_code={dataDetail.cj_code}
            customer_code={dataDetail.customer_code}
            status={dataDetail.status}
            status_raw={dataDetail.status_raw}
            create_date={dataDetail.create_date}
            create_by={dataDetail.create_by || ''}
            qty={dataDetail.qty}
            setLoading={setLoading}
            // handleConfirmCreate={()=> handleConfirmCreate()}
            handleRemoveCard={() => {
              return
            }}
            noAction={true}
          />
        </Box>

        {/* shipping mark section */}
        <Box p={2}>
          <Typography variant={'lb'} sx={{ color: '#7C7F7F' }}>
            SHIPPING MARK
          </Typography>
        </Box>

        {/* shipping code card */}
        <Box
          sx={{
            p: 2,
            backgroundColor: '#fff',
          }}
        >
          <Grid container>
            <Grid item xs={7} display={'flex'} alignItems={'center'}>
              <Typography variant="mel" component="div" mr={2}>
                Code
              </Typography>
              <Typography variant="lb" component="div" color="primary">
                {dataDetail.shiping_mark_code}
              </Typography>
            </Grid>
            <Grid
              item
              xs={5}
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <Button variant="primary2Outline" onClick={onClickViewShipping}>
                View Shipping Mark
              </Button>
            </Grid>
          </Grid>
        </Box>

        {/* house label section create */}
        <Box p={2}>
          <Grid container>
            <Grid item xs={7}>
              <Typography variant={'lb'} sx={{ color: '#7C7F7F' }}>
                House Air Waybill List ({hosueList.length})
              </Typography>
            </Grid>
            <Grid
              item
              xs={5}
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <Button
                variant="primary3Outline"
                onClick={() =>
                  onClickAction({ type: 'Create', data: initialForm })
                }
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>

        {/* house label card */}
        <Box>
          {hosueList.map((item, index) => {
            return (
              <CardHouseLabel
                hosueList={item}
                handleEditHouse={(hosueList) => handleEditHouse(hosueList)}
                handleRemoveHouse={(hosueList) => handleRemoveHouse(hosueList)}
              />
            )
          })}
        </Box>
      </Box>
    </Loading>
  )
}

export default InputHouse
