import React from 'react'
import _ from 'lodash'
import Link from '@mui/material/Link'

import DataTable from '../../components/table'
import { ShowStatus } from '../../components/styles/mui'
import { Typography, Box, Button } from '@mui/material'

import { useAuthenticated } from '../../context/authenticated'

export default function Table({
  filter,
  setFilter,
  dataTable,
  setDataTable,
  handleData,
}) {
  const { contextAuth } = useAuthenticated()
  const admin = _.get(contextAuth, 'decode.data.admin', '')

  const columns = [
    {
      id: 'id',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'System No.',
      children: (r) => (
        <Link
          underline="hover"
          color="inherit"
          href={`/inquiryData/detail/${r.id}`}
        >
          <Typography color="primary">{r.id}</Typography>
        </Link>
      ),
    },
    {
      id: 'cj_tracking',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'CJ Tracking',
    },
    {
      id: 'customer_code',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'Customer Code',
    },
    {
      id: 'qty',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'Qty',
    },
    {
      id: 'create_date',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'Create Date Time',
    },
    {
      id: 'create_by',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'Create By',
    },
    {
      id: 'status',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'Status',
      children: (r) => <ShowStatus status={r.status}></ShowStatus>,
    },

    {
      id: '',
      sort: false,
      align: 'center',
      disablePadding: false,
      label: 'Print',
      children: (r) => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: admin === 'Y' ? 'space-between' : 'center',
            width: 140,
          }}
        >
          {admin !== 'Y' ? (
            ''
          ) : (
            <Button
              disabled={admin !== 'Y'}
              variant="Outline2"
              onClick={() => handleData({ type: 'Delete', data: r })}
            >
              <img src={`/icons/delete.svg`}></img>
            </Button>
          )}
          <Button
            variant="Outline2"
            onClick={() =>
              handleData({
                type: 'Print',
                data: r,
              })
            }
          >
            <img src={`/icons/print.svg`}></img>
          </Button>
        </Box>
      ),
    },
  ]

  return (
    <>
      <DataTable
        dataTable={{
          ...dataTable,
          columns: columns,
        }}
        setDataTable={setDataTable}
        filter={filter}
        setFilter={setFilter}
        showCheckbox={false}
      />
    </>
  )
}
