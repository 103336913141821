import React from 'react'
import { Grid, Typography, Box } from '@mui/material'
import Label from '../formsUi/label'

export const GridList = ({
  list,
  variantLabel,
  spacing = 0,
  xs = 4,
  md = 4,
  sxBoxContent = {},
  //   Labelprops = {},
  ...props
}) => {
  if (!list) return <></>
  return (
    <Grid {...props} container spacing={spacing}>
      {list.map((d, i) => (
        <Grid key={i} item xs={xs} md={d?.md ? d?.md : md}>
          <Box sx={sxBoxContent}>
            <Label
              sx={{ color: '#7C7F7F' }}
              //   Labelprops={Labelprops}
              label={d?.label || ''}
              required={d?.required || false}
              variantLabel={variantLabel}
            />
            {d?.link ? (
              <a
                rel="noreferrer"
                href={d.link}
                target="_blank"
                style={{ textDecorationColor: '#E69830' }}
              >
                <Typography variant="subtitle1" color={d?.color}>
                  {d?.content || ''}
                </Typography>
              </a>
            ) : (
              <Typography variant="subtitle1" color={d?.color}>
                {d?.content || ''}
              </Typography>
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}
export const GridListV2 = ({
  list,
  variantLabel = 'ml',
  variantText = 'subtitle1',
  spacing = 0,
  xs = 4,
  md = 4,
  mainBoxSX = {},
  itemBoxSX = {},
  boxSX = {},
  labelSX = {},
  textSX = {},
}) => {
  if (!list) return <></>
  return (
    <Grid container spacing={spacing} sx={mainBoxSX}>
      {list.map((d, i) => (
        <Grid key={i} item xs={xs} md={md} sx={itemBoxSX}>
          <Box sx={boxSX}>
            <Label
              sx={{ ...labelSX, color: '#7C7F7F' }}
              label={d?.label || ''}
              required={d?.required || false}
              variantLabel={variantLabel}
            />
            {d?.type === 'text' ? (
              <Typography variant={variantText} color={d?.color} sx={textSX}>
                {d?.content || ''}
              </Typography>
            ) : (
              d?.content || <></>
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}
export const FlexList = ({
  list,
  spacing,
  xs = 4,
  md = 4,
  textPL = 0,
  boxPl = 0,
  boxMapPr = 0,
  flexDirection = 'column',
  justifyContent = 'space-between',
  ...props
}) => {
  if (!list) return <></>
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: flexDirection,
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: justifyContent,
      }}
    >
      {list.map((d, i) => (
        <Box
          key={i}
          sx={{
            display: 'flex',
            flexDirection: flexDirection,
            alignItems: 'center',
            paddingRight: boxMapPr,
          }}
        >
          <Label
            sx={{ color: '#7C7F7F' }}
            pb={0}
            label={d?.label || ''}
            required={d?.required || false}
          />
          <Box sx={{ paddingLeft: boxPl }}>
            {d?.content === 'text' ? (
              <Typography variant="subtitle1" color={d?.color} pl={textPL}>
                {d?.content || ''}
              </Typography>
            ) : (
              d?.content
            )}
          </Box>
        </Box>
      ))}
    </Box>
  )
}
export const AllList = ({
  list,
  mainBoxSX = {},
  itemBoxSX = {},
  textSX = {},
  boxSX = {},
}) => {
  if (!list) return <></>
  return (
    <Box sx={mainBoxSX}>
      {list.map((d, i) => (
        <Box key={i} sx={itemBoxSX}>
          <Label
            label={d?.label || ''}
            sx={{ color: '#7C7F7F' }}
            required={d?.required || false}
          />
          <Box sx={boxSX}>
            {d?.type === 'text' ? (
              <Typography variant="subtitle1" color={d?.color} sx={textSX}>
                {d?.content || ''}
              </Typography>
            ) : (
              d?.content
            )}
          </Box>
        </Box>
      ))}
    </Box>
  )
}
