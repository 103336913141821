import axios from 'axios'
import { handleToken } from '../utils/auth'
export const login = async ({ body, setContextAuth, navigate, path = '' }) => {
  try {
    let rootPath = window?.location?.pathname.split('/')[1]
    const result = await axios.post(`/auth/login`, body)
    if (result.data.token) {
      handleToken({ result, setContextAuth })
      navigate(`/`)
    }
  } catch (error) {
    // console.error(' login  error : ', error)
  }
}
