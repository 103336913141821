import { useAuthenticated } from './context/authenticated'
import { useDialogContext } from './context/dialog'
// routes
import Router from './routes'

import './styles/flex.css'
import './styles/fonts.css'
// components
import ScrollToTop from './components/scrollToTop'

import { setDefaults } from './utils/auth'
import { handleErrorAPI } from './utils/axios'
import { useNavigate } from 'react-router-dom'

function App() {
  const { contextAuth, setContextAuth } = useAuthenticated()
  const { setDialogContext } = useDialogContext()
  const navigate = useNavigate()

  setDefaults({ contextAuth })
  handleErrorAPI({ setDialogContext, setContextAuth, contextAuth, navigate })

  return (
    <>
      <ScrollToTop />
      <Router />
    </>
  )
}

export default App
