import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
export default function DrawerComponent({
  anchor = 'right',
  open,
  setOpen,
  width,
  children,
  margin = 24,
  styleEtc = { width: '100vw', overflowY: 'auto' },
  ...props
}) {
  return (
    <>
      <Drawer
        props={props}
        anchor={anchor}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        style={{ zIndex: 2 }}
      >
        <Box sx={styleEtc}>
          {/* <Box style={{ margin: margin }}>{children}</Box> */}
        </Box>
        <Box style={{ width: width || '', margin: margin }}>{children}</Box>
      </Drawer>
    </>
  )
}
