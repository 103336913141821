import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Grid, Typography, IconButton, Chip } from '@mui/material'
import { DeleteForever, AddCircleOutline } from '@mui/icons-material'

export const MuiLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
}))

export const GridInfo = styled(Grid)(({ theme, border, error }) => ({
  background: '#FFFFFF',
  border: border
    ? `1px solid ${error ? theme.palette.error.main : theme.palette.grey[300]}`
    : '',
  borderRadius: '8px',
}))
export const ShowError = ({ error, pl = 2 }) => (
  <Typography
    color="error"
    sx={{ pl: pl, fontSize: '16px', fontWeight: '400' }}
  >
    {error}
  </Typography>
)
export const IconButtonDeleteForever = ({ onClick }) => (
  <IconButton
    size="large"
    style={{ color: 'black' }}
    onClick={onClick}
    sx={{
      '&.Mui-disabled': {
        '& .MuiSvgIcon-root': {
          color: '#C4CDD5',
        },
      },
    }}
  >
    <DeleteForever fontSize="inherit" />
  </IconButton>
)
export const IconButtonAddCircleOutline = ({ onClick, ...prop }) => (
  <IconButton
    {...prop}
    size="large"
    style={{ color: 'black' }}
    onClick={onClick}
    sx={{
      '&.Mui-disabled': {
        '& .MuiSvgIcon-root': {
          color: '#C4CDD5',
        },
      },
    }}
  >
    <AddCircleOutline fontSize="inherit" />
  </IconButton>
)
const renderStatus = (status) => {
  switch (status) {
    case 'Created CJ':
      return {
        text: 'sml.createdText',
        background: 'sml.createdBackground',
      }
    case 'Gen Shipping Mark':
      return {
        text: 'sml.shippingText',
        background: 'sml.shippingBackground',
      }
    case 'Printed':
      return {
        text: 'sml.printedText',
        background: 'sml.printedBackground',
      }
    default:
      return {}
  }
}
export const ShowStatus = ({ status }) => {
  const color = renderStatus(status)
  if (!status) return <></>
  return (
    <>
      <Chip
        label={
          <Typography variant="xsb" sx={{ color: color.text }}>
            {/* {status.toUpperCase()} */}
            {status}
          </Typography>
        }
        sx={{
          borderRadius: '8px',
          backgroundColor: color.background,
        }}
      />
    </>
  )
}
