import { CardInfo } from './style'
import { Typography } from '@mui/material'
import numeral from 'numeral'

export default function CardInformation({
  label,
  value,
  type = 'default',
  handleOnClick,
  ...props
}) {
  const getTypeColor = (type) => {
    switch (type) {
      case 'info':
        return { bg: '#b0d9e5', text: '#0D9796' }
      case 'warning':
        return { bg: '#ffe4b0', text: '#BA7B00' }
      case 'success':
        return { bg: '#c7d7ae', text: '#3F6600' }
      case 'danger':
        return { bg: '#ffe6e5;', text: '#CE0100' }
      default:
        return { bg: '#d9d9d9', text: '#7C7F7F' }
    }
  }

  return (
    <>
      <CardInfo
        className={`${getTypeColor(type).bg}`}
        {...props}
        onClick={handleOnClick}
        style={{
          backgroundColor: `${getTypeColor(type).bg}`,
          color: `${getTypeColor(type).text}`,
        }}
      >
        <Typography
          variant="lb"
          component="div"
          style={{ textOverflow: 'ellipsis' }}
        >
          {label}
        </Typography>
        <Typography
          variant="xxlb"
          component="div"
          style={{ textOverflow: 'ellipsis', textAlign: 'end' }}
        >
          {numeral(parseFloat(value)).format('0,0')}
        </Typography>
      </CardInfo>
    </>
  )
}
