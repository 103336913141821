import * as yup from 'yup'
import { dateNow } from '../../../utils/dayjs'
export const initialForm = {
  consigneeName: '',
  shipper: 'KCS',
  qty: '',
  chargeableWeigth: '',
  date: dateNow({ format: 'YYYYMMDD' }),
  customValue: '',
  currency: 1,
  description: '',
}

export const validationSchema = yup.object().shape({
  consigneeName: yup.string().required('Please select consignee'),
  shipper: yup
    .string()
    .max(100, 'Maximum 100 characters')
    .required('Please input shipper'),
  qty: yup.number().min(1).required('Please Qty.'),
  chargeableWeigth: yup
    .number()
    .min(0.001, 'Chargeable weigth must be greater than or equal to 0.001')
    .required('Please input chargeable weigth')
    .test('is-decimal-places', 'Must have three decimal places', (value) => {
      if (value) {
        return Number(value).toFixed(3) === parseFloat(value).toFixed(3)
      }
      return true
    }),
  date: yup.date().required('Please select date'),
  customValue: yup
    .number()
    .min(0.001, 'Custom value must be greater than or equal to 0.001')
    .required('Please input custom value')
    .test('is-decimal-places', 'Must have three decimal places', (value) => {
      if (value) {
        return Number(value).toFixed(3) === parseFloat(value).toFixed(3)
      }
      return true
    }),
  currency: yup.string().required('Please select currency'),
  description: yup
    .string()
    .max(100, 'Maximum 100 characters')
    .required('Please input description'),
})

export const initialValues = {
  type: 'insert',
  data: initialForm,
}

export const rootPath = '/inputhouse'
export const key = 'id'
