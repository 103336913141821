import React from 'react'
import { Typography, Box } from '@mui/material'

export default function Label({
  label,
  required,
  variantLabel = 'ml',
  ...otherPropsBox
}) {
  return (
    <Box className="d--f" {...otherPropsBox}>
      <Typography variant={variantLabel}>{label}</Typography>
      <Typography color="error">{required && '  *'}</Typography>
    </Box>
  )
}
