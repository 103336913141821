import React, { useState, useRef, useEffect } from 'react'
import CardMenu from '../../components/cardMenu'
import { Box, Grid, Typography, IconButton, TextField } from '@mui/material'
import InputScanbarcode from '../../components/scanBarcode/inputScanbarcode'
import Drawer from '../../components/drawer'
import { Clear } from '@mui/icons-material'
import Card from '../../components/cardWorkingList'
import Loading from '../../components/loading'
import { useDialogContext } from '../../context/dialog'
import PopAlertConponent from '../../components/popAlert'
import { useNavigate } from 'react-router-dom'
import {
  getDataCard,
  saveDataCj,
  removeCj,
  generateShipping,
} from './handleData'
import BarcodeReader from '../../components/barcodeReader'

import { BrowserMultiFormatReader } from '@zxing/library'
const codeReader = new BrowserMultiFormatReader()

const ScanTracking = () => {
  sessionStorage.setItem('pageTitle', 'SCAN CJ TRACKING')
  const { setDialogContext } = useDialogContext()
  const navigate = useNavigate()
  const [openScan, setOpenScan] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [openMessage, setOpenMessage] = useState(false)
  const [messagePop, setMessagePop] = useState({ text: '', type: '' })
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const inputRefBarcode = useRef()
  const inputRef = useRef()
  const inputRef2 = useRef()
  const [customerCode, setCustomerCode] = useState('')

  const handleKeyPress = async (e) => {
    let barcode = e.target.value
    if (e.key === 'Enter') {
      if (barcode.trim() === '') return -1

      onEnterInput({ barcode })
    }
  }

  const onEnterInput = async ({ barcode }) => {
    setInputValue('')

    setDialogContext({
      type: 'content',
      device: 'tablet',
      maxWidth: 'sm',
      title: <Typography variant="mm">Create CJ Tracking</Typography>,
      agreeText: 'Save',

      disagreeText: 'Cancel',
      onCancelClick: () => {
        setCustomerCode('')
        setDialogContext({ open: false })
        inputRefBarcode.current.select()
      },
      onConfirmClick: () => handleConfirmCreate(),
      content: (
        <>
          <Grid container rowSpacing={2} mb={2}>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Cj Code"
                variant="outlined"
                defaultValue={barcode}
                inputRef={inputRef}
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleChangeInput}
                id="outlined-basic"
                label="Customer Code"
                variant="outlined"
                defaultValue={customerCode}
                inputRef={inputRef2}
                fullWidth
                inputProps={{ maxLength: 100 }}
                autoFocus
              />
            </Grid>
          </Grid>
        </>
      ),
      open: true,
    })
  }

  const handleChangeInput = async (e) => {
    setCustomerCode(e.target.value)
  }

  const handleScan = (e) => {
    e.preventDefault()

    setOpenScan(true)
  }

  const handleResultScan = async (result) => {
    console.log('result', result)
    if (!!result) {
      setInputValue(result)
      setOpenScan(false)
      onEnterInput({ barcode: result })
    }
  }

  const handleConfirmCreate = async () => {
    const barcodeValue = inputRef.current.value
    const customerCodeValue = inputRef2.current.value
    if (barcodeValue.trim() === '' || customerCodeValue.trim() === '') {
      await setMessagePop((prev) => {
        return {
          ...prev,
          text: 'Input Cannot be blank',
          type: 'warning',
        }
      })

      return await setOpenMessage(true)
    }

    const dataObj = {
      cj_code: barcodeValue,
      customer_code: customerCodeValue,
    }

    const res = await saveDataCj({
      dataObj,
      setLoading,
      setDialogContext,
      setOpenMessage,
      setMessagePop,
    })
    if (res) {
      await getDataCard({ setLoading, setDialogContext, data, setData })
      setCustomerCode('')
    }
  }

  const handleRemoveCard = async ({ id, cj_code }) => {
    setDialogContext({
      type: 'content',
      device: 'tablet',
      title: (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ mx: 2 }} variant="lb">
              Confirm remove CJ
            </Typography>
          </Box>
        </>
      ),
      agreeText: 'Remove',
      disagreeText: 'Cancel',
      onConfirmClick: () => handleConfirmRemove({ id }),
      content: (
        <Box sx={{ display: 'grid', alignItems: 'center', mt: 2 }}>
          <Typography sx={{ mx: 2 }} variant="mr" color={'grey'}>
            Do you want to remove CJ?
          </Typography>
          <Typography sx={{ mx: 2 }} variant="lb" color={'primary'}>
            {cj_code}
          </Typography>
        </Box>
      ),
      open: true,
    })
  }

  const handleConfirmRemove = async ({ id, cj_code }) => {
    const dataObj = {
      id: id,
      cj_code: cj_code,
    }
    const res = await removeCj({
      dataObj,
      setLoading,
      setDialogContext,
      setOpenMessage,
      setMessagePop,
      loadData,
    })

    if (res) {
      await getDataCard({ setLoading, setDialogContext, data, setData })
    }
  }

  const hendleGenerateShipping = async ({ id, cj_code, create_date_raw }) => {
    const dataObj = {
      id: id,
      cj_code: cj_code,
      createdate_cj: create_date_raw,
    }

    const res = await generateShipping({
      dataObj,
      setLoading,
      setDialogContext,
      setOpenMessage,
      setMessagePop,
      loadData,
    })

    if (res) {
      await navigate(`/inputhouse?params=${id}`)
    }
  }

  const loadData = async () => {
    getDataCard({ setLoading, setDialogContext, data, setData })
  }

  useEffect(() => {
    getDataCard({ setLoading, setDialogContext, data, setData })
  }, [])

  return (
    <Loading loading={loading} icon={true}>
      <PopAlertConponent
        type={messagePop.type}
        open={openMessage}
        setOpen={setOpenMessage}
        autoHideDuration={2500}
      >
        {messagePop.text}
      </PopAlertConponent>

      <Drawer open={openScan} setOpen={setOpenScan} margin={0} width="100vw">
        <div
          style={{
            padding: '20px',
            position: 'fixed',
            zIndex: 1,
            background: 'white',
            width: '100vw',
            // height: '100vh',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'end',
            }}
            pl={3}
          >
            <Typography variant="h3" color={'common.black'}>
              Scan Barcode
            </Typography>
            <IconButton
              color="primary"
              size="small"
              onClick={() => {
                setOpenScan(false)
                codeReader.reset()
                if (inputRefBarcode.current) {
                  console.log('Ref is defined')
                  inputRefBarcode.current.focus()
                  console.log('Focus attempted')
                }
              }}
            >
              <Clear fontSize="large" />
            </IconButton>
          </Box>
        </div>
        <Box>
          {/* <BarcodeScannerComponent
            style={{ width: '100%' }}
            onUpdate={handleResultScan}
            facingMode="environment"
          /> */}
          <BarcodeReader
            handleResultScan={handleResultScan}
            openScan={openScan}
            codeReader={codeReader}
          />
        </Box>
      </Drawer>

      <InputScanbarcode
        inputRefBarcode={inputRefBarcode}
        handleScan={(e) => handleScan(e)}
        onKeyPress={handleKeyPress}
        inputValue={inputValue}
        setInputValue={setInputValue}
        placeholder={'Scan barcode CJ'}
      />

      <Box sx={{ pt: '80px !important' }}>
        <Box p={2}>
          <Typography variant={'lb'} sx={{ color: '#7C7F7F' }}>
            WORKING LIST
          </Typography>
        </Box>
        {data.map((item, index) => {
          return (
            <Card
              key={item.id}
              id={item.id}
              cj_code={item.cj_code}
              customer_code={item.customer_code}
              status={item.status}
              status_raw={item.status_raw}
              create_date={item.create_date}
              create_by={item.create_by_fullname || ''}
              qty={item.qty}
              create_date_raw={item.create_date_raw}
              setLoading={setLoading}
              handleRemoveCard={(id, cj_code) => handleRemoveCard(id, cj_code)}
              hendleGenerateShipping={(id, cj_code, create_date_raw) =>
                hendleGenerateShipping(id, cj_code, create_date_raw)
              }
            />
          )
        })}
      </Box>
    </Loading>
  )
}

export default ScanTracking
