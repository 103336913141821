import axios from 'axios'
import dayjs from 'dayjs'

import _ from 'lodash'
import { handleDelete } from '../../services/apiCRUDMasterData'
import { printing } from '../../services/printing'
import numeral from 'numeral'

import FormCreate from '../inputHouse/form/form'

export const handleDataAll = async ({
  id,
  type,
  subType,
  item,
  data,
  detail,
  loadData,
  setDialogContext,
  setLoading,
  handleClose,
  setDialogContextCreate,
  dataDetail,
  dataSearch,
  setDataSearch,
  setOpenMessage,
  setMessagePop,
  navigate,
}) => {
  //   console.log('handleDataAll')
  //   console.log(type, subType, item)
  //   console.log(detail)
  //   console.log('shiping_mark_code', item?.shiping_mark_code)
  //   console.log('house_code', item.house_code)
  switch (type) {
    case 'Edit':
      const dataForm = {
        house_code: item.house_code,
        consigneeName: item.consignee_code,
        shipper: item.shipper_name,
        qty: item.qty,
        chargeableWeigth: numeral(parseFloat(item.chargeable_weigth)).format(
          '0.000'
        ),
        date: item.house_date,
        customValue: numeral(parseFloat(item.custom_value)).format('0.000'),
        currency: item.currency,
        description: item.description,
      }
      await setDialogContextCreate({
        type: 'blank',
        content: (
          <FormCreate
            inquiryDataAdmin={true}
            type={type}
            data={dataForm}
            dataDetail={detail}
            loadData={loadData}
            setDialogContextUser={setDialogContextCreate}
            handleClose={handleClose}
            dataSearch={dataSearch}
            setDataSearch={setDataSearch}
            setLoading={setLoading}
            setDialogContext={setDialogContext}
            setOpenMessage={setOpenMessage}
            setMessagePop={setMessagePop}
            navigate={navigate}
          />
        ),
        open: true,
      })
      break
    case 'Delete':
      handleDelete({
        key: 'house_code',
        rootPath: 'inquiryData/house-label',
        loadData,
        row: item,
        setDialogContext,
        setLoading,
      })
      break

    case 'Print':
      const body = {
        house_code: item?.house_code || '', //?
        shiping_mark_code: item?.shiping_mark_code || '', //?
        cj_code: detail?.cj_code || 0,
        cj_create_date: dayjs(detail.create_date, 'DD/MM/YYYY HH:mm:ss').format(
          'YYYY-MM-DD'
        ),
        sys_no_cj: detail?.sys_no_cj || '',
        qty: Number(detail?.qty || 0),
        qty_unit: item?.qty_unit || '', //?
        shipper_name: item?.shipper_name || '', //?
        origin: item?.origin || '', //?
        destination: item?.destination || '', //?
        chargeable_weight: item?.chargeable_weight || 1.0, //?
        custom_value: item?.custom_value || 1.0, //?
        currency_code: item?.currency_code || '', //?
        description: item?.description || '', //?
        status: item?.status || '', //?
        page: 'INQUIRY',
      }
      let url = ''
      if (subType === 'ShippingMark') {
        url = `/pdf/shippingMarkLable/${item?.shiping_mark_code}`
      } else {
        url = `/pdf/houseLabel/${item.house_code}`
      }

      let res = await printing({
        body,
        printPDFUrl: url,
      })
      await loadData({ result: res })

      break

    default:
      break
  }
}

export const viewShippingMark = async ({
  setLoading,
  setDialogContext,
  id,
  setViewShippingData,
}) => {
  setLoading(true)
  try {
    const result = await axios.get(`inputHouse/viewShippingMark?id=${id}`)

    if (result.data.status === 'error') {
      setDialogContext({
        type: 'fail',
        content: result?.data?.msg || 'error!',
        open: true,
        onConfirmClick: () => {
          setDialogContext((p) => ({ ...p, open: false }))
          setLoading(false)
        },
      })
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          // setViewShippingData(_.get(result, 'data.data', null))
          setDialogContext((p) => ({ ...p, open: false }))
          setLoading(false)
          resolve(_.get(result, 'data.data', null))
        }, 1000)
      })
    }
  } catch (error) {
    console.error('Get viewShippingMark error : ', error)
  }
  setLoading(false)
}
