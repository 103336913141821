// ----------------------------------------------------------------------

function pxToRem(value) {
  return `${value / 16}rem`
}

function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  }
}

const FONT_PRIMARY = 'Kanit , sans-serif'
const WEIGHT_BOLD = 500
const WEIGHT_MEDIUM = 500
const WEIGHT_REGULAR = 400
const WEIGHT_LIGHT = 300
const WEIGHT_EXTRALIGHT = 275

const SIZE_XXXL = 80
const SIZE_XXL = 50
const SIZE_XL = 30
const SIZE_L = 20
const SIZE_M = 18
const SIZE_S = 16
const SIZE_XS = 14
const SIZE_XXS = 10

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.1,
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
  xxxlb: {
    fontWeight: WEIGHT_BOLD,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XXXL),
  },
  xxxlm: {
    fontWeight: WEIGHT_MEDIUM,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XXXL),
  },
  xxxlr: {
    fontWeight: WEIGHT_REGULAR,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XXXL),
  },
  xxxll: {
    fontWeight: WEIGHT_LIGHT,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XXXL),
  },
  xxxlel: {
    fontWeight: WEIGHT_EXTRALIGHT,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XXXL),
  },

  xxlb: {
    fontWeight: WEIGHT_BOLD,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XXL),
  },
  xxlm: {
    fontWeight: WEIGHT_MEDIUM,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XXL),
  },
  xxlr: {
    fontWeight: WEIGHT_REGULAR,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XXL),
  },
  xxll: {
    fontWeight: WEIGHT_LIGHT,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XXL),
  },
  xxlel: {
    fontWeight: WEIGHT_EXTRALIGHT,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XXL),
  },

  xlb: {
    fontWeight: WEIGHT_BOLD,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XL),
  },
  xlm: {
    fontWeight: WEIGHT_MEDIUM,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XL),
  },
  xlr: {
    fontWeight: WEIGHT_REGULAR,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XL),
  },
  xll: {
    fontWeight: WEIGHT_LIGHT,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XL),
  },
  xlel: {
    fontWeight: WEIGHT_EXTRALIGHT,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XL),
  },

  lb: {
    fontWeight: WEIGHT_BOLD,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_L),
  },
  lm: {
    fontWeight: WEIGHT_MEDIUM,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_L),
  },
  lr: {
    fontWeight: WEIGHT_REGULAR,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_L),
  },
  ll: {
    fontWeight: WEIGHT_LIGHT,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_L),
  },
  lel: {
    fontWeight: WEIGHT_EXTRALIGHT,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_L),
  },

  mb: {
    fontWeight: WEIGHT_BOLD,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_M),
  },
  mm: {
    fontWeight: WEIGHT_MEDIUM,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_M),
  },
  mr: {
    fontWeight: WEIGHT_REGULAR,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_M),
  },
  ml: {
    fontWeight: WEIGHT_LIGHT,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_M),
  },
  mel: {
    fontWeight: WEIGHT_EXTRALIGHT,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_M),
  },

  sb: {
    fontWeight: WEIGHT_BOLD,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_S),
  },
  sm: {
    fontWeight: WEIGHT_MEDIUM,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_S),
  },
  sr: {
    fontWeight: WEIGHT_REGULAR,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_S),
  },
  sl: {
    fontWeight: WEIGHT_LIGHT,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_S),
  },
  sel: {
    fontWeight: WEIGHT_EXTRALIGHT,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_S),
  },

  xsb: {
    fontWeight: WEIGHT_BOLD,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XS),
  },
  xsm: {
    fontWeight: WEIGHT_MEDIUM,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XS),
  },
  xsr: {
    fontWeight: WEIGHT_REGULAR,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XS),
  },
  xsl: {
    fontWeight: WEIGHT_LIGHT,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XS),
  },
  xsel: {
    fontWeight: WEIGHT_EXTRALIGHT,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XS),
  },

  xxsb: {
    fontWeight: WEIGHT_BOLD,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XXS),
  },
  xxsm: {
    fontWeight: WEIGHT_MEDIUM,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XXS),
  },
  xxsr: {
    fontWeight: WEIGHT_REGULAR,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XXS),
  },
  xxsl: {
    fontWeight: WEIGHT_LIGHT,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XXS),
  },
  xxsel: {
    fontWeight: WEIGHT_EXTRALIGHT,
    lineHeight: 1.5,
    fontSize: pxToRem(SIZE_XXS),
  },
}

export default typography
