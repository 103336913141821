import React from 'react'

import { Box, Typography, Button } from '@mui/material'

import { Form, Formik } from 'formik'

import { GridList } from '../../../components/pc'
import TextFieldWrapper from '../../../components/formsUi/textField'
import TextFieldPasswordWrapper from '../../../components/formsUi/textFieldPassword'
import CheckboxWrapper from '../../../components/formsUi/checkbox/index'

import { handleInsert, handleUpdate } from '../../../services/apiCRUDMasterData'

import { validationSchema, rootPath, key } from './schema'

const FormUser = ({ type, data, loadData, setDialogContextUser }) => {
  const handleSubmitForm = async (values, { setSubmitting, resetForm }) => {
    const data = {
      username: values.username,
      password: values.password,
      firstname: values.firstname,
      lastname: values.lastname,
      admin: values.admin,
    }
    let result = {}
    if (type === 'Create') {
      result = await handleInsert({ rootPath, loadData, values: data })
      const {
        data: { status },
      } = result
      if (status !== 'success') resetForm()
    } else if (type === 'Edit') {
      result = await handleUpdate({
        key,
        rootPath,
        loadData,
        values: data,
        dataForm: values,
      })
    }
    if (result?.data) {
      const {
        data: { status },
      } = result
      if (status === 'success')
        setDialogContextUser((p) => ({ ...p, open: false }))
    }
  }
  let showType = type === 'Create' ? 'CREATE' : 'EDIT'

  return (
    <Box p={2}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="xlm">{showType}</Typography>
        {type === 'Create' ? (
          ''
        ) : (
          <Typography variant="mm" color={'primary'} pr={2}>
            SYSTEM NO. : {data.id}
          </Typography>
        )}
      </Box>

      <Formik
        initialValues={data}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmitForm}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
              }}
            >
              <GridList
                spacing={2}
                xs={12}
                md={12}
                pr={2}
                list={[
                  {
                    label: 'USERNAME',
                    required: true,
                    content: (
                      <TextFieldWrapper
                        disabled={type === 'Edit'}
                        name="username"
                        id="username"
                      />
                    ),
                  },
                  {
                    label: 'PASSWORD',
                    required: true,
                    content: (
                      <TextFieldPasswordWrapper name="password" id="password" />
                    ),
                  },
                  {
                    label: 'FIRST NAME',
                    required: true,
                    content: (
                      <TextFieldWrapper name="firstname" id="firstname" />
                    ),
                  },
                  {
                    label: 'LAST NAME',
                    required: true,
                    content: <TextFieldWrapper name="lastname" id="lastname" />,
                  },
                  {
                    label: 'ROLE ADMIN',
                    required: true,
                    content: (
                      <CheckboxWrapper
                        keyValue={'Y'}
                        name={`admin`}
                        sx={{ color: '#7C7F7F' }}
                      />
                    ),
                  },
                ]}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box></Box>
              <Box>
                <Button
                  variant="textBtnCancel"
                  onClick={() =>
                    setDialogContextUser((p) => ({ ...p, open: false }))
                  }
                >
                  <Typography variant="mm">Cancel</Typography>
                </Button>
                <Button color={'error'} type="submit">
                  <Typography variant="mm">Save</Typography>
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default FormUser
