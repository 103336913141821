import React from 'react'

import Box from '@mui/material/Box'
import styled from 'styled-components'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

export default function Loading({
  loading,
  icon,
  children,
  left = '50% !important',
  text = 'Loading...',
  opacity = 0.1,
}) {
  return (
    <>
      {loading && icon && (
        <Icon left={left}>
          <CircularProgress />
          <Typography variant="body2">{text}</Typography>
        </Icon>
      )}
      <Box
        sx={{
          opacity: loading ? opacity : 'unset',
          pointerEvents: loading ? 'none' : 'unset',
        }}
      >
        {children}
      </Box>
    </>
  )
}

const Icon = styled.div`
  top: 50% !important;
  left: ${(props) => props.left};
  z-index: 10;
  position: fixed;
  transform: translate(-50%, -50%);
  text-align: center;
`
