import React, { useState, useEffect } from 'react'
import { TextField } from '@mui/material'
import { useField } from 'formik'
import dayjs from 'dayjs'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { dayFormatInput } from '../../../utils/dayjs'

import { CalendarTodayOutlined } from '@mui/icons-material'

const DatePickerState = ({
  value,
  height,
  disabled,
  handleChangeValue,
  inputFormat = 'dd/MM/yyyy',
  otherPropsLabel,
}) => {
  // const [field, meta, helpers] = useField(name)
  const [showValue, setShowValue] = useState('')
  const [valueOld, setValueOld] = useState('')

  const handleChange = (newValue) => {
    setShowValue(newValue)
    let date = dayjs(dayjs(newValue)).format('YYYY-MM-DD')
    if (date !== 'Invalid Date') {
      setValueOld(date)
      handleChangeValue(date)
    }
    if (newValue === null) {
      setValueOld('')
      handleChangeValue('')
    }
  }

  useEffect(() => {
    if (value === '' && showValue === '') setShowValue(null)
    if (value !== valueOld) {
      const valueFormat = dayFormatInput(value)
      setShowValue(valueFormat)
      setValueOld(valueFormat)
    }
  }, [value])

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          {...otherPropsLabel}
          components={{
            OpenPickerIcon: CalendarTodayOutlined,
          }}
          disabled={disabled}
          inputFormat={inputFormat}
          value={showValue}
          onChange={handleChange}
          renderInput={(params) => (
            <>
              <TextField
                sx={
                  disabled
                    ? { backgroundColor: '#f2f2f2', borderRadius: '10px' }
                    : {
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: 'white',
                          height: height ? height : '',
                        },
                        '& .MuiFormHelperText-root.Mui-error': {
                          fontSize: '16px',
                        },
                      }
                }
                {...params}
                size={'small'}
                fullWidth
                error={false}
                // helperText={meta.touched && meta.error}
              />
            </>
          )}
        />
      </LocalizationProvider>
    </>
  )
}

export default DatePickerState
