import React, { useState, useEffect } from 'react'

import {
  BrowserMultiFormatReader,
  BrowserBarcodeReader,
  NotFoundException,
  ChecksumException,
  FormatException,
} from '@zxing/library'

const BarcodeReader = ({ handleResultScan, openScan, codeReader }) => {
  const [selectedDeviceId, setSelectedDeviceId] = useState('')
  const [code, setCode] = useState('')
  const [videoInputDevices, setVideoInputDevices] = useState([])

  // const codeReader = new BrowserMultiFormatReader()

  console.log('ZXing code reader initialized')

  useEffect(() => {
    codeReader
      .decodeFromVideoDevice()
      .then((videoInputDevices) => {
        setupDevices(videoInputDevices)
      })
      .catch((err) => {
        console.error(err)
      })

    // cleanup function when component will unmount
    return () => {
      codeReader.reset()
    }
  }, [])

  function setupDevices(videoInputDevices) {
    // selects first device
    setSelectedDeviceId(videoInputDevices[0].deviceId)

    // setup devices dropdown
    if (videoInputDevices.length >= 1) {
      setVideoInputDevices(videoInputDevices)
    }
  }

  function decodeContinuously(selectedDeviceId) {
    codeReader.decodeFromInputVideoDeviceContinuously(
      selectedDeviceId,
      'video',
      (result, err) => {
        if (result) {
          console.log('found ', result)
          handleResultScan(result.text)
          codeReader.reset()
        }

        if (err) {
          if (err instanceof NotFoundException) {
            // console.log('No QR code found.')
          }

          if (err instanceof ChecksumException) {
            console.log("A code was found, but it's read value was not valid.")
          }

          if (err instanceof FormatException) {
            console.log('A code was found, but it was in a invalid format.')
          }
        }
      }
    )
  }

  useEffect(
    (deviceId) => {
      decodeContinuously(selectedDeviceId)
      console.log(`Started decode from camera with id ${selectedDeviceId}`)
    },
    [selectedDeviceId]
  )

  return (
    <main class="wrapper">
      <section className="container" id="demo-content">
        <div>
          <video id="video" width="100%" height="auto" />
        </div>
      </section>
    </main>
  )
}

export default BarcodeReader
