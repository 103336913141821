import React, { useState } from 'react'
import PopAlertConponent from '../components/popAlert'
const PopAlert = React.createContext(null)

function PopAlertProvider(props) {
  const [popAlertcontext, setPopAlertcontext] = useState({
    open: false,
    type: '',
    msg: '',
    vertical: 'top',
    horizontal: 'right',
    device: '',
  })

  return (
    <PopAlert.Provider
      value={{
        popAlertcontext,
        setPopAlertcontext,
      }}
      {...props}
    >
      <PopAlertConponent
        type={popAlertcontext.type}
        open={popAlertcontext.open}
        setOpen={setPopAlertcontext}
        device={popAlertcontext.device}
        vertical={popAlertcontext.vertical}
        horizontal={popAlertcontext.horizontal}
      >
        {popAlertcontext.msg}
      </PopAlertConponent>
      {props.children}
    </PopAlert.Provider>
  )
}
function usePopAlertContext() {
  const popAlertcontext = React.useContext(PopAlert)
  if (popAlertcontext === undefined) {
    throw new Error('useGlobalContext must be used within a UserProvider')
  }

  return popAlertcontext
}

export { PopAlertProvider, usePopAlertContext }
