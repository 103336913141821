import React, { useState } from 'react'
import { Box, Typography, Grid, Button, IconButton } from '@mui/material'
import numeral from 'numeral'

import { viewShippingMark } from './handleData'
import viewShipping from '../inputHouse/viewShipping'
import { LeftRight } from '../../layouts/layoutPage'
import { ShowStatus } from '../../components/styles/mui'

import { useDialogContext } from '../../context/dialog'
const Detail = ({ handleData, detail, setLoading }) => {
  const { setDialogContext } = useDialogContext()

  const [viewShippingData, setViewShippingData] = useState({
    shiping_mark_code: '',
    quantit_in_boxy: '',
    cj_code: '',
  })
  const onClickViewShipping = async () => {
    let res = await viewShippingMark({
      setLoading,
      setDialogContext,
      id: detail?.shiping_mark_code || '',
      setViewShippingData,
    })
    await viewShipping({
      setDialogContext,
      Box,
      Typography,
      Grid,
      Button,
      viewShippingData: { ...res },
    })
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} ml={4} mr={4}>
        <Typography variant={'lb'} sx={{ color: '#7C7F7F' }}>
          DETAILS
        </Typography>
      </Grid>

      {/* detail */}
      <Grid item xs={12} ml={4} mr={4}>
        <Grid container>
          <Grid item xs={4}>
            <>
              <Typography variant="sel" component="div">
                CODE
              </Typography>
              <Typography variant="lb" component="div" color="primary">
                {detail?.cj_code}
              </Typography>
            </>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="sel" component="div">
              CREATE DATE/TIME
            </Typography>
            <Typography variant="lb" component="div" color="default">
              {detail?.create_date}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="sel" component="div">
              STATUS
            </Typography>
            <ShowStatus status={detail?.status}></ShowStatus>
          </Grid>
        </Grid>

        <Grid container mt={2}>
          <Grid item xs={4}>
            <Typography variant="sel" component="div">
              CUSTOMER CODE
            </Typography>
            <Typography variant="lb" component="div" color="default">
              {detail?.customer_code}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="sel" component="div">
              CREATE BY
            </Typography>
            <Typography variant="lb" component="div" color="default">
              {detail?.create_by}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="sel" component="div">
              QTY
            </Typography>
            <Typography variant="lb" component="div" color="default">
              {numeral(parseFloat(detail?.qty)).format('0,0')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/*  Shipping Mark */}
      <Grid item xs={12} mt={4} ml={4} mr={4}>
        <Typography pt={3} variant={'lb'} sx={{ color: '#7C7F7F' }}>
          SHIPPING MARK
        </Typography>
      </Grid>

      <Grid item xs={12} ml={4} mr={4}>
        <LeftRight
          left={
            <Box display={'flex'}>
              <Typography variant="mel" component="div" mr={2}>
                CODE
              </Typography>
              <Typography variant="lb" component="div" color="primary">
                {detail?.shiping_mark_code}
              </Typography>
            </Box>
          }
          right={
            <Box>
              <Button
                variant="Outline2"
                onClick={() =>
                  handleData({
                    type: 'Print',
                    subType: 'ShippingMark',
                    item: detail,
                  })
                }
                sx={{ mx: 2 }}
              >
                <img src={`/icons/print.svg`}></img>
              </Button>
              <Button
                variant="primary2Outline"
                onClick={onClickViewShipping}
                disabled={!detail?.shiping_mark_code}
              >
                View Shipping Mark
              </Button>
            </Box>
          }
        />
      </Grid>
    </Grid>
  )
}

export default Detail
