export const UserSvg = () => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27 13.5C25.3312 13.5 23.6999 13.9949 22.3124 14.922C20.9248 15.8491 19.8434 17.1669 19.2048 18.7086C18.5662 20.2504 18.3991 21.9469 18.7246 23.5836C19.0502 25.2203 19.8538 26.7237 21.0338 27.9037C22.2138 29.0837 23.7172 29.8873 25.3539 30.2129C26.9906 30.5384 28.6871 30.3713 30.2289 29.7327C31.7706 29.0941 33.0884 28.0127 34.0155 26.6251C34.9427 25.2376 35.4375 23.6063 35.4375 21.9375C35.4375 19.6997 34.5486 17.5536 32.9662 15.9713C31.3839 14.3889 29.2378 13.5 27 13.5Z"
      fill="#7C7F7F"
    />
    <path
      d="M27 3.375C22.3274 3.375 17.7598 4.76058 13.8747 7.35653C9.98955 9.95248 6.96148 13.6422 5.17336 17.9591C3.38524 22.276 2.91738 27.0262 3.82896 31.609C4.74053 36.1918 6.9906 40.4014 10.2946 43.7054C13.5986 47.0094 17.8082 49.2595 22.391 50.171C26.9738 51.0826 31.724 50.6148 36.0409 48.8266C40.3578 47.0385 44.0475 44.0105 46.6435 40.1253C49.2394 36.2402 50.625 31.6726 50.625 27C50.6179 20.7365 48.1265 14.7315 43.6975 10.3025C39.2685 5.8735 33.2636 3.38215 27 3.375ZM40.4865 42.0626C40.4529 39.8492 39.5511 37.7375 37.9754 36.1827C36.3997 34.6278 34.2762 33.7542 32.0625 33.75H21.9375C19.7238 33.7542 17.6004 34.6278 16.0247 36.1827C14.449 37.7375 13.5471 39.8492 13.5135 42.0626C10.4533 39.3301 8.2953 35.7326 7.32517 31.7464C6.35503 27.7601 6.61855 23.5733 8.08084 19.7401C9.54312 15.907 12.1352 12.6084 15.5138 10.2812C18.8925 7.95397 22.8983 6.70786 27.0009 6.70786C31.1034 6.70786 35.1092 7.95397 38.4879 10.2812C41.8665 12.6084 44.4586 15.907 45.9209 19.7401C47.3831 23.5733 47.6467 27.7601 46.6765 31.7464C45.7064 35.7326 43.5484 39.3301 40.4882 42.0626H40.4865Z"
      fill="#7C7F7F"
    />
  </svg>
)
export const InquirySvg = () => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3337 38.0003C23.4753 37.9995 27.4975 36.613 30.76 34.0617L41.0173 44.319L44.3167 41.0197L34.0593 30.7623C36.612 27.4995 37.9993 23.4763 38.0003 19.3337C38.0003 9.04133 29.626 0.666992 19.3337 0.666992C9.04133 0.666992 0.666992 9.04133 0.666992 19.3337C0.666992 29.626 9.04133 38.0003 19.3337 38.0003ZM19.3337 5.33366C27.0547 5.33366 33.3337 11.6127 33.3337 19.3337C33.3337 27.0547 27.0547 33.3337 19.3337 33.3337C11.6127 33.3337 5.33366 27.0547 5.33366 19.3337C5.33366 11.6127 11.6127 5.33366 19.3337 5.33366Z"
      fill="#7C7F7F"
    />
  </svg>
)
export const ScanSvg = () => (
  <svg
    width="44"
    height="45"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33333 11.25H11V33.75H7.33333V11.25ZM12.8333 11.25H14.6667V33.75H12.8333V11.25ZM16.5 11.25H22V33.75H16.5V11.25ZM23.8333 11.25H25.6667V33.75H23.8333V11.25ZM29.3333 11.25H33V33.75H29.3333V11.25ZM34.8333 11.25H36.6667V33.75H34.8333V11.25ZM3.66667 7.5V15H0V7.5C0 6.50544 0.386308 5.55161 1.07394 4.84835C1.76158 4.14509 2.69421 3.75 3.66667 3.75H11V7.5H3.66667ZM40.3333 3.75C41.3058 3.75 42.2384 4.14509 42.9261 4.84835C43.6137 5.55161 44 6.50544 44 7.5V15H40.3333V7.5H33V3.75H40.3333ZM3.66667 30V37.5H11V41.25H3.66667C2.69421 41.25 1.76158 40.8549 1.07394 40.1516C0.386308 39.4484 0 38.4946 0 37.5V30H3.66667ZM40.3333 37.5V30H44V37.5C44 38.4946 43.6137 39.4484 42.9261 40.1516C42.2384 40.8549 41.3058 41.25 40.3333 41.25H33V37.5H40.3333Z"
      fill="#7C7F7F"
    />
  </svg>
)

export const PrintSvg = () => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.25 18C10.805 18 10.37 18.132 9.99997 18.3792C9.62996 18.6264 9.34157 18.9778 9.17127 19.389C9.00097 19.8001 8.95642 20.2525 9.04323 20.689C9.13005 21.1254 9.34434 21.5263 9.65901 21.841C9.97368 22.1557 10.3746 22.37 10.811 22.4568C11.2475 22.5436 11.6999 22.499 12.111 22.3287C12.5222 22.1584 12.8736 21.87 13.1208 21.5C13.368 21.13 13.5 20.695 13.5 20.25C13.5 19.6533 13.2629 19.081 12.841 18.659C12.419 18.2371 11.8467 18 11.25 18ZM38.25 9H36V2.25C36 1.65326 35.7629 1.08097 35.341 0.65901C34.919 0.237053 34.3467 0 33.75 0H11.25C10.6533 0 10.081 0.237053 9.65901 0.65901C9.23705 1.08097 9 1.65326 9 2.25V9H6.75C4.95979 9 3.2429 9.71116 1.97703 10.977C0.711159 12.2429 0 13.9598 0 15.75V29.25C0 31.0402 0.711159 32.7571 1.97703 34.023C3.2429 35.2888 4.95979 36 6.75 36H9V42.75C9 43.3467 9.23705 43.919 9.65901 44.341C10.081 44.7629 10.6533 45 11.25 45H33.75C34.3467 45 34.919 44.7629 35.341 44.341C35.7629 43.919 36 43.3467 36 42.75V36H38.25C40.0402 36 41.7571 35.2888 43.023 34.023C44.2888 32.7571 45 31.0402 45 29.25V15.75C45 13.9598 44.2888 12.2429 43.023 10.977C41.7571 9.71116 40.0402 9 38.25 9ZM13.5 4.5H31.5V9H13.5V4.5ZM31.5 40.5H13.5V31.5H31.5V40.5ZM40.5 29.25C40.5 29.8467 40.2629 30.419 39.841 30.841C39.419 31.2629 38.8467 31.5 38.25 31.5H36V29.25C36 28.6533 35.7629 28.081 35.341 27.659C34.919 27.2371 34.3467 27 33.75 27H11.25C10.6533 27 10.081 27.2371 9.65901 27.659C9.23705 28.081 9 28.6533 9 29.25V31.5H6.75C6.15326 31.5 5.58097 31.2629 5.15901 30.841C4.73705 30.419 4.5 29.8467 4.5 29.25V15.75C4.5 15.1533 4.73705 14.581 5.15901 14.159C5.58097 13.7371 6.15326 13.5 6.75 13.5H38.25C38.8467 13.5 39.419 13.7371 39.841 14.159C40.2629 14.581 40.5 15.1533 40.5 15.75V29.25Z"
      fill="#7C7F7F"
    />
  </svg>
)

export const CheckSvg = () => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_20_2128)">
      <path
        d="M22.5 0C24.5654 0 26.5576 0.263672 28.4766 0.791016C30.3955 1.31836 32.1826 2.08008 33.8379 3.07617C35.4932 4.07227 37.0093 5.24414 38.3862 6.5918C39.7632 7.93945 40.9424 9.45557 41.9238 11.1401C42.9053 12.8247 43.6597 14.6191 44.187 16.5234C44.7144 18.4277 44.9854 20.4199 45 22.5C45 24.5654 44.7363 26.5576 44.209 28.4766C43.6816 30.3955 42.9199 32.1826 41.9238 33.8379C40.9277 35.4932 39.7559 37.0093 38.4082 38.3862C37.0605 39.7632 35.5444 40.9424 33.8599 41.9238C32.1753 42.9053 30.3809 43.6597 28.4766 44.187C26.5723 44.7144 24.5801 44.9854 22.5 45C20.4346 45 18.4424 44.7363 16.5234 44.209C14.6045 43.6816 12.8174 42.9199 11.1621 41.9238C9.50684 40.9277 7.99072 39.7559 6.61377 38.4082C5.23682 37.0605 4.05762 35.5444 3.07617 33.8599C2.09473 32.1753 1.34033 30.3809 0.812988 28.4766C0.285645 26.5723 0.0146484 24.5801 0 22.5C0 20.4346 0.263672 18.4424 0.791016 16.5234C1.31836 14.6045 2.08008 12.8174 3.07617 11.1621C4.07227 9.50684 5.24414 7.99072 6.5918 6.61377C7.93945 5.23682 9.45557 4.05762 11.1401 3.07617C12.8247 2.09473 14.6191 1.34033 16.5234 0.812988C18.4277 0.285645 20.4199 0.0146484 22.5 0ZM35.7495 15.0513L32.7612 12.063L18.2812 26.543L12.2388 20.5005L9.25049 23.4888L18.2812 32.5195L35.7495 15.0513Z"
        fill="#6DB000"
      />
    </g>
    <defs>
      <clipPath id="clip0_20_2128">
        <rect width="45" height="45" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const BoxSvg = () => (
  <svg
    width="44"
    height="41"
    viewBox="0 0 44 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.387695 27.4529L19.5359 17.3232L43.6115 30.0595L24.4633 40.1892L0.387695 27.4529Z"
      fill="#AAAAAA"
    />
    <path
      d="M19.5363 17.3228V0L0 10.3369L16.3454 19.011L19.5363 17.3228Z"
      fill="#979797"
    />
    <path
      d="M19.5364 0V17.3228L27.6547 21.6176L44.0001 12.9409L19.5364 0Z"
      fill="#A0A0A0"
    />
    <path
      d="M0 10.3379V27.2488L24.4636 40.1897V23.3069L0 10.3379Z"
      fill="#A0A0A0"
    />
    <path
      d="M24.4639 23.3074V40.1902L44.0001 29.8559V12.9424L24.4639 23.3074Z"
      fill="#979797"
    />
    <path
      d="M24.4636 23.3066L24.8488 39.5448L39.0362 32.4796L24.4636 40.1894V23.3066Z"
      fill="#B5B5B5"
    />
    <path
      d="M26.7975 34.7587C26.7278 34.7592 26.6593 34.7424 26.5993 34.7101C26.5118 34.6528 26.4425 34.5752 26.3983 34.4854C26.3542 34.3955 26.3368 34.2965 26.348 34.1985V32.7072V32.5972L26.4318 32.61L27.7104 31.9219V33.5922C27.7075 33.8069 27.6491 34.0178 27.5399 34.2077C27.4307 34.3976 27.2738 34.561 27.0822 34.6845C26.9961 34.7317 26.8979 34.7573 26.7975 34.7587ZM26.5546 32.8172V34.2036C26.5456 34.2673 26.5541 34.3321 26.5795 34.392C26.6048 34.4519 26.6462 34.505 26.6998 34.5464C26.7449 34.5647 26.7945 34.5718 26.8435 34.5668C26.8926 34.5619 26.9393 34.5451 26.979 34.5182C27.1387 34.4109 27.2693 34.2713 27.3608 34.1103C27.4523 33.9493 27.5022 33.7712 27.5066 33.5897V33.4669H27.032L26.9399 32.8811L26.5546 32.8172ZM27.2107 33.2802H27.5066V32.257L26.7417 32.6688L27.1241 32.7328L27.2107 33.2802Z"
      fill="#E0E0E0"
    />
    <path
      d="M27.0291 35.9502C27.0017 35.9502 26.9754 35.9402 26.956 35.9224C26.9367 35.9047 26.9258 35.8806 26.9258 35.8555V34.5995C26.9265 34.5746 26.9376 34.5509 26.9568 34.5333C26.976 34.5157 27.0019 34.5055 27.0291 34.5049C27.0565 34.5049 27.0827 34.5149 27.1021 34.5326C27.1215 34.5504 27.1324 34.5744 27.1324 34.5995V35.8555C27.1328 35.868 27.1303 35.8805 27.1253 35.8921C27.1202 35.9038 27.1126 35.9144 27.103 35.9232C27.0933 35.9321 27.0818 35.939 27.0691 35.9437C27.0563 35.9483 27.0427 35.9505 27.0291 35.9502Z"
      fill="#E0E0E0"
    />
    <path
      d="M26.4349 36.264C26.4169 36.2644 26.3991 36.2603 26.3834 36.2522C26.3677 36.2441 26.3546 36.2323 26.3456 36.218C26.3323 36.1966 26.3286 36.1712 26.3354 36.1473C26.3421 36.1234 26.3588 36.1029 26.3819 36.0901L27.5739 35.4583C27.5981 35.4461 27.6265 35.4431 27.6531 35.4498C27.6796 35.4564 27.7023 35.4723 27.7163 35.4941C27.7294 35.5158 27.7327 35.5414 27.7254 35.5654C27.7181 35.5893 27.7008 35.6096 27.6772 35.622L26.4852 36.2513C26.4703 36.2602 26.4527 36.2646 26.4349 36.264Z"
      fill="#E0E0E0"
    />
    <path
      d="M30.6809 34.1012C30.6419 34.1019 30.6033 34.0931 30.5692 34.0756C30.5293 34.051 30.497 34.0173 30.4755 33.9779C30.454 33.9384 30.444 33.8946 30.4464 33.8505V33.5947C30.4439 33.5812 30.4447 33.5673 30.4487 33.554C30.4527 33.5408 30.4598 33.5285 30.4695 33.518C30.4792 33.5075 30.4913 33.4991 30.5049 33.4933C30.5185 33.4876 30.5333 33.4846 30.5483 33.4846C30.5633 33.4846 30.5781 33.4876 30.5917 33.4933C30.6053 33.4991 30.6174 33.5075 30.6271 33.518C30.6368 33.5285 30.6439 33.5408 30.6479 33.554C30.6519 33.5673 30.6527 33.5812 30.6502 33.5947V33.8505C30.6488 33.8614 30.6498 33.8725 30.6532 33.883C30.6565 33.8936 30.6622 33.9034 30.6697 33.9119C30.6697 33.9119 30.7032 33.9119 30.7367 33.8966C30.8005 33.8428 30.8508 33.777 30.8841 33.7037C30.9173 33.6304 30.9328 33.5513 30.9294 33.4719V31.8016C30.9269 31.788 30.9277 31.7741 30.9317 31.7609C30.9357 31.7476 30.9428 31.7353 30.9525 31.7249C30.9622 31.7144 30.9742 31.7059 30.9878 31.7002C31.0015 31.6944 31.0163 31.6914 31.0313 31.6914C31.0463 31.6914 31.0611 31.6944 31.0747 31.7002C31.0883 31.7059 31.1004 31.7144 31.1101 31.7249C31.1198 31.7353 31.1268 31.7476 31.1308 31.7609C31.1348 31.7741 31.1356 31.788 31.1332 31.8016V33.4719C31.1393 33.5827 31.1173 33.6933 31.0688 33.7949C31.0204 33.8966 30.9469 33.9865 30.854 34.0577C30.8023 34.0877 30.742 34.1028 30.6809 34.1012Z"
      fill="#E0E0E0"
    />
    <path
      d="M29.6006 32.6402V32.4764C29.6255 32.059 29.7573 31.6528 29.9852 31.2906C30.2132 30.9285 30.5309 30.6205 30.9127 30.3917C31.0492 30.2857 31.2181 30.2209 31.3964 30.2063C31.5746 30.1917 31.7534 30.2278 31.9083 30.3099C32.0632 30.3919 32.1867 30.5158 32.2619 30.6646C32.337 30.8134 32.3601 30.9799 32.3281 31.1412V31.1974L29.6006 32.6402ZM31.5269 30.4121C31.3456 30.419 31.1694 30.4693 31.016 30.5579C30.6902 30.7538 30.4143 31.0117 30.2072 31.3141C30 31.6165 29.8664 31.9562 29.8155 32.3102L32.1215 31.09C32.1302 30.9715 32.1058 30.853 32.0506 30.7456C31.9954 30.6381 31.9112 30.5454 31.806 30.4761C31.7207 30.4328 31.6244 30.4107 31.5269 30.4121Z"
      fill="#E0E0E0"
    />
    <path
      d="M34.3659 32.1772C34.339 32.1766 34.3134 32.1663 34.2946 32.1486C34.2759 32.1309 34.2654 32.1072 34.2654 32.0826V28.8953C34.2629 28.8818 34.2637 28.8679 34.2677 28.8546C34.2717 28.8414 34.2788 28.8291 34.2885 28.8186C34.2982 28.8081 34.3102 28.7997 34.3238 28.7939C34.3375 28.7882 34.3523 28.7852 34.3673 28.7852C34.3823 28.7852 34.3971 28.7882 34.4107 28.7939C34.4243 28.7997 34.4364 28.8081 34.4461 28.8186C34.4558 28.8291 34.4629 28.8414 34.4668 28.8546C34.4708 28.8679 34.4716 28.8818 34.4692 28.8953V32.0954C34.4658 32.1182 34.4534 32.1391 34.4344 32.1542C34.4154 32.1692 34.391 32.1774 34.3659 32.1772Z"
      fill="#E0E0E0"
    />
    <path
      d="M34.0667 29.6099C34.0529 29.6123 34.0387 29.6123 34.0249 29.6099C34.0003 29.5998 33.981 29.5813 33.9711 29.5584C33.9612 29.5355 33.9615 29.5099 33.9718 29.4871L34.3375 28.7197L34.7451 29.1495C34.7642 29.1672 34.7749 29.1911 34.7749 29.216C34.7749 29.2409 34.7642 29.2648 34.7451 29.2825C34.7255 29.2994 34.6995 29.3088 34.6725 29.3088C34.6456 29.3088 34.6196 29.2994 34.6 29.2825L34.4073 29.0778L34.1728 29.5767C34.1605 29.591 34.144 29.6017 34.1251 29.6076C34.1063 29.6135 34.086 29.6143 34.0667 29.6099Z"
      fill="#E0E0E0"
    />
    <path
      d="M35.3151 31.6809C35.2882 31.6802 35.2626 31.6699 35.2439 31.6522C35.2251 31.6346 35.2146 31.6109 35.2146 31.5862V28.3836C35.2121 28.37 35.2129 28.3562 35.2169 28.3429C35.2209 28.3297 35.228 28.3174 35.2377 28.3069C35.2474 28.2964 35.2595 28.288 35.2731 28.2822C35.2867 28.2764 35.3015 28.2734 35.3165 28.2734C35.3315 28.2734 35.3463 28.2764 35.3599 28.2822C35.3735 28.288 35.3856 28.2964 35.3953 28.3069C35.405 28.3174 35.4121 28.3297 35.4161 28.3429C35.4201 28.3562 35.4208 28.37 35.4184 28.3836V31.5837C35.4188 31.5963 35.4164 31.6089 35.4113 31.6207C35.4063 31.6324 35.3988 31.6432 35.3891 31.6522C35.3795 31.6613 35.368 31.6685 35.3553 31.6734C35.3426 31.6783 35.3289 31.6809 35.3151 31.6809Z"
      fill="#E0E0E0"
    />
    <path
      d="M35.016 29.1158C35.0021 29.1182 34.9879 29.1182 34.9741 29.1158C34.9495 29.1057 34.9302 29.0872 34.9203 29.0642C34.9104 29.0413 34.9107 29.0157 34.921 28.993L35.2868 28.2256L35.6943 28.64C35.7096 28.6592 35.7163 28.683 35.7133 28.7066C35.7103 28.7302 35.6976 28.7519 35.6779 28.7674C35.6583 28.7829 35.6329 28.791 35.607 28.7901C35.5811 28.7893 35.5564 28.7795 35.538 28.7628L35.3454 28.5581L35.1109 29.0569C35.1032 29.0742 35.09 29.089 35.0731 29.0995C35.0563 29.11 35.0364 29.1156 35.016 29.1158Z"
      fill="#E0E0E0"
    />
    <path
      d="M4.18492 22.3414L9.51706 25.1552C9.58755 25.1972 9.64627 25.2538 9.68846 25.3204C9.73066 25.3871 9.75514 25.4619 9.75994 25.5389V28.9333C9.75994 29.2147 9.54218 29.3273 9.27976 29.1891L3.95321 26.3728C3.88345 26.3309 3.82538 26.2746 3.78368 26.2084C3.74199 26.1422 3.71782 26.0679 3.71312 25.9916V22.5946C3.70475 22.3158 3.9225 22.2007 4.18492 22.3414Z"
      fill="#FAFAFA"
    />
    <path
      d="M4.05371 22.8682L4.23517 22.9654V25.9633L4.05371 25.8687V22.8682Z"
      fill="#B5B5B5"
    />
    <path
      d="M4.79102 23.2607L5.10369 23.4296V26.4275L4.79102 26.2587V23.2607Z"
      fill="#B5B5B5"
    />
    <path
      d="M5.53027 23.6514L5.71173 23.746V26.744L5.53027 26.6493V23.6514Z"
      fill="#B5B5B5"
    />
    <path
      d="M6.08594 23.9404L6.45165 24.1374L6.44886 27.1354L6.08594 26.9384V23.9404Z"
      fill="#B5B5B5"
    />
    <path
      d="M7.00684 24.4326L7.1883 24.5298V27.5278L7.00684 27.4306V24.4326Z"
      fill="#B5B5B5"
    />
    <path
      d="M7.53809 24.708L7.92892 24.9178V27.9183L7.53809 27.706V24.708Z"
      fill="#B5B5B5"
    />
    <path
      d="M8.48407 25.2148L8.66553 25.3095V28.3075L8.48407 28.2128V25.2148Z"
      fill="#B5B5B5"
    />
    <path
      d="M8.90527 25.4326L8.9974 25.4812V28.4792L8.90527 28.4332V25.4326Z"
      fill="#B5B5B5"
    />
    <path
      d="M8.14618 25.0508L8.2383 25.0968V28.0974L8.14618 28.0488V25.0508Z"
      fill="#B5B5B5"
    />
    <path
      d="M6.66113 24.2529L6.75047 24.299V27.2969L6.66113 27.2509V24.2529Z"
      fill="#B5B5B5"
    />
    <path
      d="M5.8457 23.8213L5.93504 23.8699V26.8679L5.8457 26.8193V23.8213Z"
      fill="#B5B5B5"
    />
    <path
      d="M5.25977 23.5264L5.35189 23.575V26.5729L5.25977 26.5243V23.5264Z"
      fill="#B5B5B5"
    />
    <path
      d="M4.4668 23.0859L4.55613 23.1345V26.1325L4.4668 26.0839V23.0859Z"
      fill="#B5B5B5"
    />
    <path
      d="M9.22363 25.6025L9.40509 25.6997V28.6977L9.22363 28.6005V25.6025Z"
      fill="#B5B5B5"
    />
    <path
      d="M19.5363 0L0 10.3369L24.4636 23.3059L43.9999 12.9409L19.5363 0Z"
      fill="#B5B5B5"
    />
    <path
      opacity="0.1"
      d="M0 10.3369L24.4636 22.6639L43.9999 12.941L24.4636 23.306L0 10.3369Z"
      fill="white"
    />
    <path
      d="M8.10742 6.04773L32.585 18.9963L35.5944 17.4027L11.1169 4.4541L8.10742 6.04773Z"
      fill="#888888"
    />
    <path
      d="M35.5944 17.3994V20.9039L35.2315 20.7146L34.8518 21.3131L34.4749 21.1085L34.0953 21.7148L33.7184 21.5101L33.3387 22.1061L32.9618 21.9092L32.585 22.5077V18.9956L35.5944 17.3994Z"
      fill="#FFB4AC"
    />
    <path
      opacity="0.25"
      d="M35.5944 17.3994V20.9039L35.2315 20.7146L34.8518 21.3131L34.4749 21.1085L34.0953 21.7148L33.7184 21.5101L33.3387 22.1061L32.9618 21.9092L32.585 22.5077V18.9956L35.5944 17.3994Z"
      fill="#AAAAAA"
    />
  </svg>
)
