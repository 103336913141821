import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import App from './App'
import * as serviceWorker from './serviceWorker'

// theme
import ThemeProvider from './theme'

import { DialogProvider } from './context/dialog'
import { PopAlertProvider } from './context/popAlert'
import { AuthenticatedProvider } from './context/authenticated'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <HelmetProvider>
    <ThemeProvider>
      <AuthenticatedProvider>
        <DialogProvider>
          <PopAlertProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PopAlertProvider>
        </DialogProvider>
      </AuthenticatedProvider>
    </ThemeProvider>
  </HelmetProvider>
)

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

serviceWorker.register()
