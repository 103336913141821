import React from 'react'
import { Box, Grid, Typography, Button } from '@mui/material'
import numeral from 'numeral'

const CardHouseLabel = ({ handleRemoveHouse, handleEditHouse, hosueList }) => {
  return (
    <>
      <Box
        sx={{ position: 'relative', backgroundColor: '#FFFFFF', p: 2, mb: 2 }}
      >
        <Grid container>
          <Grid item xs={12} sm={2}>
            <Typography variant="mel" component="div">
              House Label
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="lm" component="div" color="primary">
              {hosueList?.house_code || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="mel" component="div">
              Shipper
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="lm" component="div" color="black">
              {hosueList?.shipper_name || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="mel" component="div">
              Descriptions
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} sx={{ wordBreak: 'break-word' }}>
            <Typography variant="lm" component="div" color="black">
              {hosueList?.description || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ wordBreak: 'break-word' }}>
            <Typography variant="mel" component="div">
              Consignee Address
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="lm" component="div" color="black">
              {hosueList?.consignee || '-'}
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ border: '1px solid #7C7F7F', my: 2, p: 2 }}>
          <Grid container>
            <Grid item sm={2}>
              <Typography variant="sel" component="div">
                Qty ({hosueList?.qty_unit || '-'})
              </Typography>
              <Typography variant="lm" component="div" color="black">
                {hosueList?.qty || '-'}
              </Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography variant="sel" component="div">
                Chargeable weight (g)
              </Typography>
              <Typography variant="lm" component="div" color="black">
                {numeral(parseFloat(hosueList?.chargeable_weigth)).format(
                  '0,0.000'
                )}
              </Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography variant="sel" component="div">
                Custom Value
              </Typography>
              <Typography variant="lm" component="div" color="black">
                {numeral(parseFloat(hosueList?.custom_value)).format('0,0.000')}{' '}
                {hosueList?.currency_code}
              </Typography>
            </Grid>
            <Grid item sm={2}>
              <Typography variant="sel" component="div">
                Create By
              </Typography>
              <Typography variant="lm" component="div" color="black">
                {hosueList?.create_by || '-'}
              </Typography>
            </Grid>
            <Grid item sm={2}>
              <Typography variant="sel" component="div">
                Date
              </Typography>
              <Typography variant="lm" component="div" color="black">
                {hosueList?.create_date || '-'}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            top: '12px',
            right: '12px',
            zIndex: 1,
          }}
        >
          <Button
            variant="outlined"
            onClick={(e) => handleEditHouse(hosueList)}
            style={{ borderColor: '#7C7F7F' }}
            sx={{ mx: 1 }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.988 2.01172L21.988 5.01172L19.701 7.29972L16.701 4.29972L18.988 2.01172ZM8 15.9997H11L18.287 8.71272L15.287 5.71272L8 12.9997V15.9997Z"
                fill="#7C7F7F"
              />
              <path
                d="M19 19H8.158C8.132 19 8.105 19.01 8.079 19.01C8.046 19.01 8.013 19.001 7.979 19H5V5H11.847L13.847 3H5C3.897 3 3 3.896 3 5V19C3 20.104 3.897 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V10.332L19 12.332V19Z"
                fill="#7C7F7F"
              />
            </svg>
          </Button>
          <Button
            variant="outlined"
            onClick={(e) => handleRemoveHouse(hosueList)}
            style={{ borderColor: '#7C7F7F' }}
            sx={{ mx: 1 }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 17 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 5H15.1429M6.30357 9V15M9.83929 9V15M1.88393 5L2.76786 17C2.76786 17.5304 2.95411 18.0391 3.28565 18.4142C3.61719 18.7893 4.06685 19 4.53571 19H11.6071C12.076 19 12.5257 18.7893 12.8572 18.4142C13.1887 18.0391 13.375 17.5304 13.375 17L14.2589 5M5.41964 5V2C5.41964 1.73478 5.51277 1.48043 5.67854 1.29289C5.84431 1.10536 6.06914 1 6.30357 1H9.83929C10.0737 1 10.2985 1.10536 10.4643 1.29289C10.6301 1.48043 10.7232 1.73478 10.7232 2V5"
                stroke="#7C7F7F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default CardHouseLabel
