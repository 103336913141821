import { TextField, Box, Button } from '@mui/material'
import styled from 'styled-components'

export default function InputScanbarcode({
  inputRefBarcode,
  onKeyPress,
  handleScan,
  inputValue,
  setInputValue,
  placeholder = 'Scan barcode',
}) {
  return (
    <>
      <Box
        pt={1.5}
        px={2}
        pb={1.5}
        display="flex"
        sx={{
          position: 'fixed',
          width: '100%',
          top: 64,
          backgroundColor: 'white',
          zIndex: 2,
          boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.20)',
        }}
      >
        <ScanBox
          id="outlined-basic"
          label={placeholder}
          variant="outlined"
          fullWidth
          inputRef={inputRefBarcode}
          onKeyPress={onKeyPress}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          size={window.innerWidth < 600 ? 'small' : 'medium'}
          autoFocus
          inputProps={{ maxLength: 100 }}
        />
        <Button
          style={{
            backgroundColor: '#DADADA',
            borderRadius: '0px',
          }}
          onClick={(e) => handleScan(e)}
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.16667 6.25016H6.25V18.7502H4.16667V6.25016ZM7.29167 6.25016H8.33333V18.7502H7.29167V6.25016ZM9.375 6.25016H12.5V18.7502H9.375V6.25016ZM13.5417 6.25016H14.5833V18.7502H13.5417V6.25016ZM16.6667 6.25016H18.75V18.7502H16.6667V6.25016ZM19.7917 6.25016H20.8333V18.7502H19.7917V6.25016ZM2.08333 4.16683V8.3335H0V4.16683C0 3.6143 0.219493 3.08439 0.610194 2.69369C1.0009 2.30299 1.5308 2.0835 2.08333 2.0835H6.25V4.16683H2.08333ZM22.9167 2.0835C23.4692 2.0835 23.9991 2.30299 24.3898 2.69369C24.7805 3.08439 25 3.6143 25 4.16683V8.3335H22.9167V4.16683H18.75V2.0835H22.9167ZM2.08333 16.6668V20.8335H6.25V22.9168H2.08333C1.5308 22.9168 1.0009 22.6973 0.610194 22.3066C0.219493 21.9159 0 21.386 0 20.8335V16.6668H2.08333ZM22.9167 20.8335V16.6668H25V20.8335C25 21.386 24.7805 21.9159 24.3898 22.3066C23.9991 22.6973 23.4692 22.9168 22.9167 22.9168H18.75V20.8335H22.9167Z"
              fill="black"
            />
          </svg>
        </Button>
      </Box>
    </>
  )
}

const ScanBox = styled(TextField)(() => ({
  '& fieldset': {
    borderRadius: '0px',
  },
}))
