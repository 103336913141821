import React, { useState, useMemo } from 'react'

import jwt_decode from 'jwt-decode'
import Loading from '../components/loading'

const Authenticated = React.createContext(null)
function AuthenticatedProvider(props) {
  const [loading, setLoading] = useState(false)

  const token = sessionStorage.getItem('token') || ''
  let decode = {}
  let status = 'error'
  if (token) decode = jwt_decode(token)
  if (Date.now() < decode?.exp * 1000) status = 'success'
  const [contextAuth, setContextAuth] = useState({
    decode,
    token,
    status,
  })

  useMemo(() => {}, [token])

  return (
    <Authenticated.Provider value={{ contextAuth, setContextAuth }} {...props}>
      <Loading loading={loading} icon={true} opacity={0}>
        {props.children}
      </Loading>
    </Authenticated.Provider>
  )
}
function useAuthenticated() {
  const contextAuth = React.useContext(Authenticated)
  if (contextAuth === undefined) {
    throw new Error('useGlobalContext must be used within a UserProvider')
  }
  return contextAuth
}
export { AuthenticatedProvider, useAuthenticated }
