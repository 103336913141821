import { useState, useEffect } from 'react'
import _ from 'lodash'
import { useAuthenticated } from '../../context/authenticated'
import {
  Grid,
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Divider,
  Popover,
  Button,
} from '@mui/material'
import Drawer from '../../components/drawer'
import { handleSign } from '../../utils/auth'
import { useDialogContext } from '../../context/dialog'
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
  Link,
} from 'react-router-dom'

export default function Header() {
  const [open, setOpen] = useState(false)
  const [pageTitle, setPageTitlen] = useState('')
  const { pathname } = useLocation()
  const { contextAuth, setContextAuth } = useAuthenticated()
  const { setDialogContext } = useDialogContext()
  const username = _.get(contextAuth, 'decode.data.username', '')
  const firstname = _.get(contextAuth, 'decode.data.firstname', '')
  const lastname = _.get(contextAuth, 'decode.data.lastname', '')
  const [fontS, setFontS] = useState(18)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openUser = Boolean(anchorEl)
  const id = openUser ? 'simple-popover' : undefined

  return (
    <>
      <AppBar
        sx={{
          bgcolor: { background: '#CE0100' },
          position: 'fixed',
          zIndex: 2,
        }}
      >
        <Toolbar>
          <Grid container display="flex" alignItems="center">
            <Grid item xs={3}>
              <BtnLeft setOpen={setOpen} />
            </Grid>
            <Grid item xs={6} textAlign="center">
              <Typography variant="xlm">
                <TilteNavBar
                  setPageTitlen={setPageTitlen}
                  pageTitle={pageTitle}
                  pathname={pathname}
                  setFontS={setFontS}
                />
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              justifyContent="end"
              alignItems="center"
            >
              <Typography
                variant="lr"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {username}
              </Typography>

              <Button aria-describedby={id} onClick={handleClick}>
                <UserSVG />
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Popover
        id={id}
        open={openUser}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>
          {firstname} {'   '}
          {lastname}
        </Typography>
      </Popover>

      <Drawer
        anchor="left"
        open={open}
        setOpen={setOpen}
        margin={0}
        width={'220px'}
        styleEtc={{ width: '220px', overflowY: 'auto' }}
      >
        <Box mt={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'end',
              m: 5,
            }}
          >
            <Typography
              variant="h3"
              color={'common.black'}
              display="flex"
              alignItems="center"
              flexDirection="column"
              width={200}
            >
              Menu
            </Typography>
          </Box>
          <Divider />
          <List>
            <Link
              to={'/'}
              style={{ textDecoration: 'none', color: 'gray' }}
              onClick={() => setOpen(false)}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    width={200}
                  >
                    <svg
                      width="52"
                      height="52"
                      viewBox="0 0 52 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_26_6711)">
                        <path
                          d="M26 0C11.6406 0 0 11.6406 0 26C0 40.3594 11.6406 52 26 52C40.3594 52 52 40.3594 52 26C52 11.6406 40.3594 0 26 0ZM26 11.9812L38.6953 21.8486V40.0188H29.9609V29.0501H22.0391V40.0188H13.3047V21.8486L26 11.9812Z"
                          fill="#787878"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_26_6711">
                          <rect width="52" height="52" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <Typography
                      variant="ml"
                      sx={{ cursor: 'pointer' }}
                      color="gray"
                    >
                      DASHBOARD
                    </Typography>
                  </Box>
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
          <Divider />

          <List
            onClick={() => handleSign({ setDialogContext, setContextAuth })}
          >
            <ListItem disablePadding>
              <ListItemButton>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  width={200}
                >
                  <svg
                    width="52"
                    height="52"
                    viewBox="0 0 52 52"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M52 26L41.6 15.6V23.4H20.8V28.6H41.6V36.4M46.8 41.6C43.5258 45.9656 38.9611 49.1903 33.7524 50.8174C28.5436 52.4444 22.955 52.3914 17.7781 50.6658C12.6012 48.9401 8.09844 45.6294 4.9077 41.2025C1.71697 36.7756 0 31.4569 0 26C0 20.5431 1.71697 15.2244 4.9077 10.7975C8.09844 6.37063 12.6012 3.05987 17.7781 1.33423C22.955 -0.391403 28.5436 -0.444436 33.7524 1.18265C38.9611 2.80973 43.5258 6.03444 46.8 10.4H39.702C36.6998 7.75233 32.9974 6.02717 29.0391 5.43155C25.0807 4.83593 21.0346 5.39515 17.3862 7.04209C13.7378 8.68904 10.6421 11.3538 8.47062 14.7165C6.29914 18.0792 5.14409 21.9971 5.14409 26C5.14409 30.0029 6.29914 33.9208 8.47062 37.2835C10.6421 40.6462 13.7378 43.311 17.3862 44.9579C21.0346 46.6049 25.0807 47.1641 29.0391 46.5684C32.9974 45.9728 36.6998 44.2477 39.702 41.6H46.8Z"
                      fill="#979797"
                    />
                  </svg>

                  <Typography
                    variant="ml"
                    sx={{ cursor: 'pointer' }}
                    color="gray"
                  >
                    SIGN OUT
                  </Typography>
                </Box>
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
        </Box>
      </Drawer>
    </>
  )
}

const BtnLeft = ({ setOpen }) => {
  return (
    <IconButton
      sx={{ color: 'common.white' }}
      aria-label="menu"
      size="small"
      onClick={() => setOpen(true)}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.75 22.5H3.75V20H18.75V22.5ZM26.25 16.25H3.75V13.75H26.25V16.25ZM18.75 10H3.75V7.5H18.75V10Z"
          fill="white"
        />
      </svg>
    </IconButton>
  )
}
export const UserSVG = () => {
  return (
    <>
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 9.5C17.8257 9.5 16.6777 9.84823 15.7013 10.5006C14.7249 11.1531 13.9639 12.0804 13.5145 13.1653C13.0651 14.2503 12.9475 15.4441 13.1766 16.5958C13.4057 17.7476 13.9712 18.8056 14.8016 19.6359C15.6319 20.4663 16.6899 21.0318 17.8417 21.2609C18.9934 21.49 20.1873 21.3724 21.2722 20.923C22.3571 20.4736 23.2844 19.7126 23.9369 18.7362C24.5893 17.7598 24.9375 16.6118 24.9375 15.4375C24.9375 13.8628 24.3119 12.3526 23.1985 11.2391C22.085 10.1256 20.5747 9.5 19 9.5Z"
          fill="#F4F4F4"
        />
        <path
          d="M19 2.375C15.7119 2.375 12.4976 3.35004 9.76365 5.17682C7.02968 7.0036 4.89882 9.60007 3.64051 12.6379C2.3822 15.6757 2.05297 19.0184 2.69445 22.2434C3.33593 25.4683 4.91931 28.4306 7.24436 30.7556C9.5694 33.0807 12.5317 34.6641 15.7566 35.3055C18.9816 35.947 22.3243 35.6178 25.3621 34.3595C28.3999 33.1012 30.9964 30.9703 32.8232 28.2364C34.65 25.5024 35.625 22.2881 35.625 19C35.62 14.5923 33.8668 10.3666 30.7501 7.2499C27.6334 4.1332 23.4077 2.38003 19 2.375ZM28.4905 29.5996C28.4668 28.042 27.8322 26.556 26.7234 25.4619C25.6146 24.3677 24.1203 23.7529 22.5625 23.75H15.4375C13.8797 23.7529 12.3854 24.3677 11.2766 25.4619C10.1678 26.556 9.53317 28.042 9.50951 29.5996C7.35605 27.6768 5.83744 25.1452 5.15475 22.34C4.47206 19.5349 4.6575 16.5886 5.68652 13.8912C6.71553 11.1938 8.53958 8.8726 10.9171 7.23492C13.2947 5.59724 16.1136 4.72034 19.0006 4.72034C21.8876 4.72034 24.7065 5.59724 27.0841 7.23492C29.4616 8.8726 31.2857 11.1938 32.3147 13.8912C33.3437 16.5886 33.5291 19.5349 32.8465 22.34C32.1638 25.1452 30.6451 27.6768 28.4917 29.5996H28.4905Z"
          fill="#F4F4F4"
        />
      </svg>
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 12.25C20.0717 12.25 19.1815 11.8813 18.5251 11.2249C17.8687 10.5685 17.5 9.67826 17.5 8.75C17.5 7.82174 17.8687 6.9315 18.5251 6.27513C19.1815 5.61875 20.0717 5.25 21 5.25C21.9283 5.25 22.8185 5.61875 23.4749 6.27513C24.1313 6.9315 24.5 7.82174 24.5 8.75C24.5 9.67826 24.1313 10.5685 23.4749 11.2249C22.8185 11.8813 21.9283 12.25 21 12.25ZM21 24.5C20.0717 24.5 19.1815 24.1313 18.5251 23.4749C17.8687 22.8185 17.5 21.9283 17.5 21C17.5 20.0717 17.8687 19.1815 18.5251 18.5251C19.1815 17.8687 20.0717 17.5 21 17.5C21.9283 17.5 22.8185 17.8687 23.4749 18.5251C24.1312 19.1815 24.5 20.0717 24.5 21C24.5 21.9283 24.1312 22.8185 23.4749 23.4749C22.8185 24.1313 21.9283 24.5 21 24.5ZM21 36.75C20.0717 36.75 19.1815 36.3813 18.5251 35.7249C17.8687 35.0685 17.5 34.1783 17.5 33.25C17.5 32.3217 17.8687 31.4315 18.5251 30.7751C19.1815 30.1187 20.0717 29.75 21 29.75C21.9283 29.75 22.8185 30.1187 23.4749 30.7751C24.1312 31.4315 24.5 32.3217 24.5 33.25C24.5 34.1783 24.1312 35.0685 23.4749 35.7249C22.8185 36.3813 21.9283 36.75 21 36.75Z"
          fill="#F4F4F4"
        />
      </svg>
    </>
  )
}

const TilteNavBar = ({ setPageTitlen, pageTitle, pathname, setFontS, ref }) => {
  let [searchParams] = useSearchParams()

  useEffect(() => {
    if (sessionStorage.getItem('pageTitle').length > 45) {
      setFontS(13)
    } else {
      setFontS(18)
    }
    setPageTitlen(sessionStorage.getItem('pageTitle'))
  }, [pathname])

  if (searchParams.get('mode')) {
    return `${pageTitle} (${searchParams.get('mode')})`
  }
  return `${pageTitle}`
}
