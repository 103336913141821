import _ from 'lodash'
import axios from 'axios'

const rootPath = '/scanTracking'

export const getInfoDashboard = async ({
  setLoading,
  setDialogContext,
  infoArray,
  setInfoArray,
  setShowMenu,
}) => {
  setLoading(true)
  try {
    let myData = infoArray
    const result = await axios.get(`${rootPath}/getInfoDashboard`)

    if (result.data.status === 'error') {
      setDialogContext({
        type: 'fail',
        content: result?.data?.msg || 'error!',
        open: true,
        onConfirmClick: () => {
          setDialogContext((p) => ({ ...p, open: false }))
          setLoading(false)
        },
      })
    } else {
      myData[0].value = _.get(result, 'data.countCreated', '0')
      myData[1].value = _.get(result, 'data.countGen', '0')
      const show = _.get(result, 'data.showMenu', '')

      if (show === 'Y') {
        setShowMenu(true)
      } else {
        setShowMenu(false)
      }

      setInfoArray(myData)
      setDialogContext((p) => ({ ...p, open: false }))
      setLoading(false)
    }
  } catch (error) {
    console.error('Get info Dashboard error : ', error)
  }
  setLoading(false)
}
