import { Typography } from '@mui/material'
import _ from 'lodash'
import * as API from './apiCRUD'

export const getDataTable = async ({
  rootPath,
  setLoading,
  setDataTable,
  filter,
  path = '',
}) => {
  setLoading(true)

  try {
    const result = await API.findDataTable({
      filter,
      rootPath,
      path,
    })

    setDataTable((prev) => ({
      ...prev,
      rows: _.get(result, 'data.dataList', []),
      totalCount: _.get(result, 'data.totalCount', 0),
    }))
  } catch (error) {
    // console.error(' getData  error : ', error)
  }
  setTimeout(() => setLoading(false), 400)
}

export const handleInsert = async ({
  rootPath,
  loadData,
  values,
  headers = {},
  resetForm = () => {},
}) => {
  const result = await API.create({
    rootPath,
    body: values,
    headers: headers,
  })
  if (result?.data?.status === 'success') resetForm()
  loadData({ result })
  return result
}

export const handleUpdate = async ({
  key,
  rootPath,
  loadData,
  values,
  dataForm,
  id = dataForm[key],
  headers = {},
}) => {
  const result = await API.update({
    rootPath,
    body: values,
    id,
    headers: headers,
  })

  loadData({ result })
}

export const handleUpdateNotID = async ({ rootPath, loadData, values }) => {
  const result = await API.update({
    rootPath,
    body: values,
  })

  loadData({ result })
}

export const handleDelete = ({
  key,
  rootPath,
  loadData,
  row,
  setDialogContext,
  setLoading,
}) => {
  setDialogContext({
    type: 'delete',
    open: true,
    onConfirmClick: async () => {
      const id = row[key]

      setDialogContext((p) => ({ ...p, open: false }))
      const result = await API.remove({ rootPath, id })
      loadData({ result })
    },

    onCancelClick: () => {
      setDialogContext((p) => ({ ...p, open: false }))
      setLoading(false)
    },
  })
}
export const handleDeleteBody = ({
  rootPath,
  loadData,
  body,
  setDialogContext,
  setLoading,
}) => {
  setDialogContext({
    type: 'delete',
    open: true,
    onConfirmClick: async () => {
      setDialogContext((p) => ({ ...p, open: false }))
      const result = await API.removeBody({ rootPath, body })
      loadData({ result })
    },
    onCancelClick: () => {
      setDialogContext((p) => ({ ...p, open: false }))
      setLoading(false)
    },
  })
}
export const handleSwitchActive = ({
  key,
  rootPath,
  loadData,
  row,
  setDialogContext,
  setLoading,
  id = row[key],
}) => {
  setDialogContext({
    type: 'active',
    open: true,
    title: 'Confirm active/inactive',
    content: (
      <Typography sx={{ mx: 3 }} variant="body1">
        Do you want to active/inactive this item?
      </Typography>
    ),
    onConfirmClick: async () => {
      setDialogContext((p) => ({ ...p, open: false }))
      const result = await API.remove({ rootPath, id })
      loadData({ result })
    },
    disagreeText: 'Cancel',
    agreeText: 'Confirm',
    onCancelClick: () => {
      setDialogContext((p) => ({ ...p, open: false }))
      setLoading(false)
    },
  })
}

export const findDetail = async ({ url, setLoading, setData }) => {
  setLoading(true)

  try {
    const result = await API.findAll({ rootPath: url })

    setData((prev) => ({
      ...prev,
      ..._.get(result, 'data', []),
    }))
  } catch (error) {
    // console.error(' getData  error : ', error)
  }
  setTimeout(() => setLoading(false), 400)
}
