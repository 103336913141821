import React, { useRef } from 'react'
import { Box, Typography, Grid, Chip, Button } from '@mui/material'
import { useDialogContext } from '../../context/dialog'
import { useNavigate } from 'react-router-dom'
import numeral from 'numeral'

export default function Card({
  id = null,
  cj_code = '',
  status = '',
  status_raw,
  customer_code = '',
  create_date = '',
  create_by = '',
  qty = '',
  create_date_raw = '',
  setLoading,
  handleRemoveCard,
  hendleGenerateShipping,
  noAction = false,
}) {
  const { setDialogContext } = useDialogContext()
  const navigate = useNavigate()
  const pathName = sessionStorage.getItem('location')
  const inputRef = useRef()

  const renderStatus = (status) => {
    switch (status) {
      case 2:
        return { text: 'created.darker', background: 'created' }
      default:
        return { text: 'default.darker', background: 'default' }
    }
  }

  const handleClick = ({ id, cj_code, status_raw, create_date_raw }) => {
    if (noAction) return

    if (status_raw === 2) return navigate(`/inputhouse?params=${id}`)

    setDialogContext({
      type: 'content',
      device: 'tablet',

      title: (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <svg
              width="41"
              height="34"
              viewBox="0 0 41 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 34L20.5 0L41 34H0ZM20.5 28.6316C21.028 28.6316 21.471 28.4598 21.8288 28.1162C22.1866 27.7726 22.3649 27.3479 22.3636 26.8421C22.3636 26.3351 22.1847 25.9098 21.8269 25.5662C21.4691 25.2226 21.0268 25.0514 20.5 25.0526C19.972 25.0526 19.529 25.2244 19.1712 25.568C18.8134 25.9116 18.6351 26.3363 18.6364 26.8421C18.6364 27.3491 18.8153 27.7744 19.1731 28.118C19.5309 28.4616 19.9732 28.6328 20.5 28.6316ZM18.6364 23.2632H22.3636V14.3158H18.6364V23.2632Z"
                fill="#FF8E00"
              />
            </svg>
            <Typography sx={{ mx: 2 }} variant="lb">
              Generate shipping mark no.
            </Typography>
          </Box>
        </>
      ),
      agreeText: 'Confirm',
      disagreeText: 'Cancel',
      onConfirmClick: () =>
        hendleGenerateShipping({ id, cj_code, create_date_raw }),
      content: (
        <Box sx={{ display: 'grid', alignItems: 'center', mt: 2 }}>
          <Typography sx={{ mx: 2 }} variant="mr" color={'grey'}>
            Ref. CJ Tracking
          </Typography>
          <Typography sx={{ mx: 2 }} variant="lb" color={'primary'}>
            {cj_code}
          </Typography>
        </Box>
      ),
      open: true,
    })
  }

  return (
    <>
      <Box sx={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
        <Box
          onClick={() =>
            handleClick({ id, cj_code, status_raw, create_date_raw })
          }
          sx={{
            p: 2,
            mb: '18px',
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Grid container>
            <Grid item xs={4}>
              <>
                <Typography variant="sel" component="div">
                  Code
                </Typography>
                <Typography variant="lb" component="div" color="primary">
                  {cj_code}
                </Typography>
              </>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="sel" component="div">
                Create Date/Time
              </Typography>
              <Typography variant="lb" component="div" color="default">
                {create_date}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="sel" component="div">
                Status
              </Typography>
              <Chip
                label={
                  <Typography
                    variant="xsb"
                    color={renderStatus(status_raw).text}
                  >
                    {status}
                  </Typography>
                }
                sx={{
                  borderRadius: '8px',
                  backgroundColor: (theme) =>
                    theme.palette[renderStatus(status_raw).background].lighter,
                }}
              />
            </Grid>
          </Grid>

          <Grid container mt={2}>
            <Grid item xs={4}>
              <Typography variant="sel" component="div">
                Customer Code
              </Typography>
              <Typography
                variant="lb"
                component="div"
                color="default"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {customer_code}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="sel" component="div">
                Create By
              </Typography>
              <Typography variant="lb" component="div" color="default">
                {create_by}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="sel" component="div">
                Qty
              </Typography>
              <Typography variant="lb" component="div" color="default">
                {numeral(parseFloat(qty)).format('0,0')}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* delete btn */}
        {status_raw === 1 && (
          <Box
            sx={{
              position: 'absolute',
              top: '12px',
              right: '12px',
              zIndex: 1,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => handleRemoveCard({ id, cj_code })}
              style={{ borderColor: '#7C7F7F' }}
            >
              <svg
                width="17"
                height="20"
                viewBox="0 0 17 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 5H15.1429M6.30357 9V15M9.83929 9V15M1.88393 5L2.76786 17C2.76786 17.5304 2.95411 18.0391 3.28565 18.4142C3.61719 18.7893 4.06685 19 4.53571 19H11.6071C12.076 19 12.5257 18.7893 12.8572 18.4142C13.1887 18.0391 13.375 17.5304 13.375 17L14.2589 5M5.41964 5V2C5.41964 1.73478 5.51277 1.48043 5.67854 1.29289C5.84431 1.10536 6.06914 1 6.30357 1H9.83929C10.0737 1 10.2985 1.10536 10.4643 1.29289C10.6301 1.48043 10.7232 1.73478 10.7232 2V5"
                  stroke="#7C7F7F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
          </Box>
        )}
      </Box>
    </>
  )
}
