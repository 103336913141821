import axios from 'axios'

export const printing = async ({ body, printPDFUrl = '' }) => {
  const printPDF = await axios.get(printPDFUrl)
  const token = sessionStorage.getItem('token')
  if (printPDF?.status === 200) {
    window.open(
      `${
        process.env.REACT_APP_API_URL
      }/file?pathName=/${printPDF.data.path.replace(
        'upload/',
        ''
      )}&authorization=${token}`,
      '_blank'
    )
    const result = await axios.post(`printing`, body)
    // return result
  }
  //   return printPDF
}
