import { alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DADADA',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
}

const WTH = {
  grey: '#AAAAAA',
  greyDisabled: '#f2f2f2',
}

const SML = {
  createdText: '#7C7F7F',
  createdBackground: '#DADADA',
  shippingText: '#A75D00',
  shippingBackground: '#FFCE92',
  printedText: '#0D9796',
  printedBackground: '#B0D9E5',
}
const PRIMARY = {
  lighter: '#ffe6e5',
  light: '#ffb3b3',
  main: '#CE0100',
  dark: '#800100',
  darker: '#4d0000',
  contrastText: '#fff',
}

const DEFAULT = {
  lighter: '#DADADA',
  light: '#C4CDD5',
  main: '#808080',
  dark: '#637381',
  darker: '#454F5B',
  contrastText: '#fff',
}

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
}

const INFO = {
  lighter: '#CDEDFF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#1A3D81',
  contrastText: '#fff',
}

const SUCCESS = {
  lighter: '#B1F8B5',
  light: '#AAF27F',
  main: '#2FB235',
  dark: '#2E7D32',
  darker: '#08660D',
  contrastText: GREY[800],
}

const WARNING = {
  lighter: '#FCF89C',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#595901',
  contrastText: GREY[800],
}

const CREATED = {
  lighter: '#FFE4B0',
  light: '#ffd380',
  main: '#ffc24d',
  dark: '#BA7B00',
  darker: '#4d3200',
  contrastText: GREY[800],
}

const ERROR = {
  lighter: '#FFE1E1',
  light: '#FFCBCB',
  main: '#AD0909',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
}

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
}

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
}

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  wth: { ...WTH },
  sml: { ...SML },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  default: { ...DEFAULT },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  created: { ...CREATED },
  divider: GREY[500_24],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#fff', default: GREY[100], neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: WTH.greyDisabled,
    disabledBackground: WTH.greyDisabled,
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
}

export default palette
