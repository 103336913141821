import { useRoutes, Navigate } from 'react-router-dom'

import _ from 'lodash'
import NotFound from '../pages/all/notFound'
import Login from '../pages/all/login'

import LayoutMenuNavBar from '../layouts/menuNavBar'
import LayoutLogin from '../layouts/login'

import { useAuthenticated } from '../context/authenticated'

// ----------------------------------------------------------------------
import Dashboard from '../pages/dashboard'
import ScanTracking from '../pages/scanTracking'
import User from '../pages/user'
import InputHouse from '../pages/inputHouse'
import Printing from '../pages/printing'
import InquiryData from '../pages/inquiryData'
import InquiryDataDetail from '../pages/inquiryDataDetail'

export default function Router() {
  const { contextAuth } = useAuthenticated()
  let route = []

  const admin = _.get(contextAuth, 'decode.data.admin', '')
  let routeAdmin = []

  if (admin === 'Y') {
    routeAdmin = [{ path: '/User', element: <User /> }]
  }

  if (!contextAuth?.token) {
    route = [
      {
        path: '/',
        element: <LayoutLogin />,
        children: [
          { path: '', element: <Login /> },
          { path: 'login', element: <Login /> },
          { path: '*', element: <Navigate to="/login" replace /> },
        ],
      },
    ]
  } else {
    route = [
      {
        path: '',
        element: <LayoutMenuNavBar />,
        children: [
          { path: '', element: <Dashboard /> },
          { path: '/scan', element: <ScanTracking /> },
          { path: '/user', element: <User /> },
          { path: '/inputhouse', element: <InputHouse /> },
          { path: '/printing', element: <Printing /> },
          { path: '/inquiryData', element: <InquiryData /> },
          { path: '/inquiryData/detail/:id', element: <InquiryDataDetail /> },
          ...routeAdmin,
        ],
      },
      { path: '/404', element: <NotFound /> },
      { path: '*', element: <Navigate to="" replace /> },
    ]
  }

  return useRoutes([...route])
}
