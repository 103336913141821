import React from 'react'
import dayjs from 'dayjs'

import DataTable from '../../components/table'
import { ShowStatus } from '../../components/styles/mui'
import { Typography, Box, Button } from '@mui/material'

import { printing } from '../../services/printing'
export default function Table({
  filter,
  setFilter,
  dataTable,
  setDataTable,
  loadData,
}) {
  const handleData = async ({ type, data }) => {
    if (type === 'Print') {
      const body = {
        house_code: '', //?
        shiping_mark_code: data.shiping_mark_code,
        cj_code: data.cj_tracking,
        cj_create_date: dayjs(data.create_date, 'DD/MM/YYYY HH:mm:ss').format(
          'YYYY-MM-DD'
        ),
        sys_no_cj: data.id,
        qty: Number(data.qty),
        qty_unit: 0, //?
        shipper_name: '', //?
        origin: '', //?
        destination: '', //?
        chargeable_weight: 1.0, //?
        custom_value: 1.0, //?
        currency_code: '', //?
        description: '', //?
        status: '', //?
        page: 'PRINTING (ON PC)',
      }
      let res = await printing({
        body,
        printPDFUrl: `/pdf/printAll/${data.shiping_mark_code}`,
      })
      await loadData({ result: res })
    }
  }
  const columns = [
    {
      id: 'id',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'System No.',
      children: (r) => <Typography color="primary">{r.id}</Typography>,
    },
    {
      id: 'cj_tracking',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'CJ Tracking',
    },
    {
      id: 'customer_code',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'Customer Code',
    },
    {
      id: 'qty',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'Qty',
    },
    {
      id: 'create_date',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'Create Date Time',
    },
    {
      id: 'create_by',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'Create By',
    },
    {
      id: 'status',
      sort: true,
      align: 'center',
      disablePadding: false,
      label: 'Status',
      children: (r) => <ShowStatus status={r.status}></ShowStatus>,
    },

    {
      id: '',
      sort: false,
      align: 'center',
      disablePadding: false,
      label: 'Print',
      children: (r) => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: 'center',
            width: 140,
          }}
        >
          <Button
            variant="Outline2"
            onClick={() =>
              handleData({
                type: 'Print',
                data: r,
              })
            }
          >
            <img src={`/icons/print.svg`}></img>
          </Button>
        </Box>
      ),
    },
  ]

  return (
    <>
      <DataTable
        dataTable={{
          ...dataTable,
          columns: columns,
        }}
        setDataTable={setDataTable}
        filter={filter}
        setFilter={setFilter}
        showCheckbox={false}
      />
    </>
  )
}
