import axios from 'axios'
import dayjs from 'dayjs'

import _ from 'lodash'
import { handleDelete } from '../../services/apiCRUDMasterData'
import { key, rootPath } from './form/schema'
import { printing } from '../../services/printing'

export const handleDataAll = async ({
  type,
  data,
  date,
  loadData,
  setDialogContext,
  setLoading,
}) => {
  switch (type) {
    case 'Delete':
      handleDelete({
        key,
        rootPath,
        loadData,
        row: data,
        setDialogContext,
        setLoading,
      })
      break
    case 'Excel':
      let url = `${rootPath}/manifest?date=${date}`

      const result = await axios({
        url,
        method: 'GET',
        headers: {
          responseType: 'blob',
        },
      })

      if (result?.status === 200) {
        const token = sessionStorage.getItem('token') || ''
        let urlFile = `${process.env.REACT_APP_API_URL}${_.get(
          result,
          'data.url',
          ''
        )}&fileName=${_.get(
          result,
          'data.fileName',
          ''
        )}&authorization=${token}`
        const link = document.createElement('a')
        link.href = urlFile
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      }

      break

    case 'Print':
      const body = {
        house_code: '', //?
        shiping_mark_code: data.shiping_mark_code,
        cj_code: data.cj_tracking,
        cj_create_date: dayjs(data.create_date, 'DD/MM/YYYY HH:mm:ss').format(
          'YYYY-MM-DD'
        ),
        sys_no_cj: data.id,
        qty: Number(data.qty),
        qty_unit: '', //?
        shipper_name: '', //?
        origin: '', //?
        destination: '', //?
        chargeable_weight: 1.0, //?
        custom_value: 1.0, //?
        currency_code: '', //?
        description: '', //?
        status: '', //?
        page: 'INQUIRY',
      }
      let res = await printing({
        body,
        printPDFUrl: `/pdf/printAll/${data.shiping_mark_code}`,
      })
      await loadData({ result: res })

      break

    default:
      break
  }
}
