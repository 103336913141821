import { Divider } from '@mui/material'
import Barcode from 'react-jsbarcode'

const viewShipping = ({
  setDialogContext,
  Box,
  Typography,
  Grid,
  Button,
  viewShippingData,
}) => {
  console.log(viewShippingData)
  setDialogContext({
    type: 'blank',
    device: 'tablet',
    maxWidth: 'sm',
    title: (
      <Box p={2}>
        <Typography variant="lm">Shipping Mark Label</Typography>
      </Box>
    ),
    onCancelClick: () => {
      setDialogContext({ open: false })
    },
    content: (
      <>
        {/* detail section */}
        <Box
          p={2}
          sx={{
            border: '1px solid #7C7F7F',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Barcode
            value={viewShippingData?.shiping_mark_code || '-'}
            options={{ format: 'code128', height: 70 }}
            renderer="svg"
          />
        </Box>
        <Box p={2} sx={{ border: '1px solid #7C7F7F' }}>
          {/* <Divider /> */}
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Typography variant="mel" component="div">
                Packing Material
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="lb" component="div" color="black">
                {viewShippingData?.packing_material || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="mel" component="div">
                Parcel in box
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="lb" component="div" color="black">
                {viewShippingData?.quantit_in_boxy || '0'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="mel" component="div">
                Ref. CJ Tracking
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="lb" component="div" color="black">
                {viewShippingData?.cj_code || '-'}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* cancel btn */}
        <Box display={'flex'} justifyContent={'flex-end'} pt={2}>
          <Button
            data-testid="btn-cancel-dialog"
            sx={{ mx: 1, mb: 1, minWidth: 100 }}
            variant="textBtnCancel"
            onClick={() => setDialogContext({ open: false })}
          >
            Cancel
          </Button>
        </Box>
      </>
    ),
    open: true,
  })
}

export default viewShipping
