import * as yup from 'yup'

export const initialForm = {
  username: '',
  password: '',
  firstname: '',
  lastname: '',
  admin: '',
}

export const validationSchema = yup.object().shape({
  username: yup
    .string()
    .max(50, 'MAXIMUM 50 CHARACTERS')
    .required('PLEASE INPUT USERNAME'),
  password: yup
    .string()
    .max(100, 'MAXIMUM 100 CHARACTERS')
    .required('PLEASE INPUT PASSWORD'),
  firstname: yup
    .string()
    .max(100, 'MAXIMUM 100 CHARACTERS')
    .required('PLEASE INPUT FIRST NAME'),
  lastname: yup
    .string()
    .max(100, 'MAXIMUM 100 CHARACTERS')
    .required('PLEASE INPUT LAST NAME'),
})

export const initialValues = {
  type: 'insert',
  data: initialForm,
}

export const rootPath = '/user'
export const key = 'id'
