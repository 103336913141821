import React, { useEffect, useState } from 'react'

import { Box, Typography, Button, Grid } from '@mui/material'

import { Form, Formik } from 'formik'

import { GridList } from '../../../components/pc'
import TextFieldWrapper from '../../../components/formsUi/textField'
import SelectSearchWrapper from '../../../components/formsUi/selectSearch'
import TextFieldPasswordWrapper from '../../../components/formsUi/textFieldPassword'
import CheckboxWrapper from '../../../components/formsUi/checkbox/index'
import DatePickerWrapper from '../../../components/formsUi/datePicker/index'
import TextFieldNumberWrapper from '../../../components/formsUi/textFieldNumber'
import { handleInsert, handleUpdate } from '../../../services/apiCRUDMasterData'
import { getSearch, createHosueLabel } from '../handleData'

import { validationSchema, rootPath, key } from './schema'

const FormUser = ({
  inquiryDataAdmin = false,
  type,
  data,
  dataDetail,
  loadData,
  setDialogContextCreate,
  handleClose,
  dataSearch,
  setDataSearch,
  setLoading,
  setDialogContext,
  setOpenMessage,
  setMessagePop,
  navigate,
  currentDate,
}) => {
  const [dataSearchR, setDataSearchR] = useState(dataSearch)
  const [disabledSave, setDisabledSave] = useState(false)

  const handleSubmitForm = async (values, { setSubmitting, resetForm }) => {
    setDisabledSave(true)
    const dataPrepare = {
      ...values,
      qty: parseInt(values.qty),
      qty_unit: 'EA',
      origin: dataDetail.origin,
      destination: dataDetail.destination,
      sys_no_cj: dataDetail.sys_no_cj,
      cj_code: dataDetail.cj_code,
      cj_create_date: dataDetail.cj_create_date,
      shiping_mark_code: dataDetail.shiping_mark_code,
      type: type,
      house_code: data?.house_code || '',
      inquiryDataAdmin,
    }

    let result = {}

    result = await createHosueLabel({
      dataObj: dataPrepare,
      setLoading,
      setDialogContext,
      setOpenMessage,
      setMessagePop,
      navigate,
    })
    setDisabledSave(false)
    if (!result) resetForm()

    if (result) {
      handleClose()
      loadData()
    }
  }
  const handleDebounce = ({ name, value }) => {
    handleSearch({ name, value, dataSearchR, setDataSearchR })
  }

  const onChange = ({ name, value, option, setFieldValue }) => {
    handleOnChange({
      name,
      value,
      setFieldValue,
      setDataSearchR,
      option,
    })
  }

  const handleOnChange = ({
    name,
    value,
    setFieldValue,
    setDataSearchR,
    option,
  }) => {
    setFieldValue(name, value)
    setDataSearchR((prev) => ({
      ...prev,
      [name]: option,
    }))
  }

  useEffect(() => {
    Promise.all([
      handleSearch({
        name: 'consigneeName',
        value: '',
        dataSearchR,
        setDataSearchR,
      }),
      handleSearch({
        name: 'currency',
        value: '',
        dataSearchR,
        setDataSearchR,
      }),
    ])
  }, [])

  return (
    <Box p={2}>
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="xlm">{type}</Typography>

        <Grid container>
          <Grid item xs={6}>
            <Typography variant={'lr'} sx={{ color: '#AAAAAA' }}>
              CJ Tracking
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'end' }}>
            <Typography variant={'lr'} color={'primary'}>
              {dataDetail?.cj_code || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={'lr'} sx={{ color: '#AAAAAA' }}>
              Shipping Mark No.
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'end' }}>
            <Typography variant={'lr'} color={'primary'}>
              {dataDetail?.shiping_mark_code || '-'}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Formik
        initialValues={data}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmitForm}
      >
        {({ values, setFieldValue, isSubmitting, errors }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display={'flex'}>
                  <Typography variant={'ml'} sx={{ color: '#7C7F7F' }}>
                    Consignee Name
                  </Typography>
                  <Typography color="error">{'*'}</Typography>
                </Box>
                <SelectSearchWrapper
                  value={values?.consigneeName || ''}
                  valueSearch={dataSearchR?.consigneeNameSearch || ''}
                  name="consigneeName"
                  options={dataSearchR?.consigneeNameList || []}
                  defaultOptions={{
                    consignee_code: values?.consigneeName || '',
                  }}
                  valueKey="consignee_code"
                  labelKey="consignee_name"
                  onChange={({ name, value, option }) =>
                    onChange({ name, value, option, values, setFieldValue })
                  }
                  handleDebounce={handleDebounce}
                />
              </Grid>

              <Grid item xs={12}>
                <Box display={'flex'}>
                  <Typography variant={'ml'} sx={{ color: '#7C7F7F' }}>
                    Shipper
                  </Typography>
                  <Typography color="error">{'*'}</Typography>
                </Box>
                <TextFieldWrapper name="shipper" id="shipper" />
              </Grid>

              <Grid item xs={4}>
                <Box display={'flex'}>
                  <Typography variant={'ml'} sx={{ color: '#7C7F7F' }}>
                    Origin
                  </Typography>
                </Box>
                <Typography variant={'mb'}>
                  {dataDetail?.origin || '-'}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Box display={'flex'}>
                  <Typography variant={'ml'} sx={{ color: '#7C7F7F' }}>
                    Destination
                  </Typography>
                  <Typography color="error">{'*'}</Typography>
                </Box>
                <Typography variant={'mb'}>
                  {dataDetail?.destination || '-'}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Box display={'flex'}>
                  <Typography variant={'ml'} sx={{ color: '#7C7F7F' }}>
                    Qty
                  </Typography>
                  <Typography color="error">{'*'}</Typography>
                </Box>
                {/* <TextFieldWrapper name="qty" id="qty" /> */}
                <TextFieldNumberWrapper
                  name="qty"
                  digit={0}
                  disabled={false}
                  inputProps={{ maxLength: 100 }}
                  size={'small'}
                  sx={{ width: '100%' }}
                />
              </Grid>

              <Grid item xs={6}>
                <Box display={'flex'}>
                  <Typography variant={'ml'} sx={{ color: '#7C7F7F' }}>
                    Chargeable weight (g)
                  </Typography>
                  <Typography color="error">{'*'}</Typography>
                </Box>
                <TextFieldNumberWrapper
                  name="chargeableWeigth"
                  digit={3}
                  disabled={false}
                  inputProps={{ maxLength: 100 }}
                  size={'small'}
                  sx={{ width: '100%' }}
                />
              </Grid>

              <Grid item xs={6}>
                <Box display={'flex'}>
                  <Typography variant={'ml'} sx={{ color: '#7C7F7F' }}>
                    Date
                  </Typography>
                  <Typography color="error">{'*'}</Typography>
                </Box>
                <DatePickerWrapper name="date" id="date" />
              </Grid>

              <Grid item xs={6}>
                <Box display={'flex'}>
                  <Typography variant={'ml'} sx={{ color: '#7C7F7F' }}>
                    Custom value
                  </Typography>
                  <Typography color="error">{'*'}</Typography>
                </Box>
                <TextFieldNumberWrapper
                  name="customValue"
                  digit={3}
                  disabled={false}
                  inputProps={{ maxLength: 100 }}
                  size={'small'}
                  sx={{ width: '100%' }}
                />
              </Grid>

              <Grid item xs={6}>
                <Box display={'flex'}>
                  <Typography variant={'ml'} sx={{ color: '#7C7F7F' }}>
                    Currency
                  </Typography>
                  <Typography color="error">{'*'}</Typography>
                </Box>
                <SelectSearchWrapper
                  value={values?.currency || ''}
                  valueSearch={dataSearchR?.currencySearch || ''}
                  name="currency"
                  options={dataSearchR?.currencyList || []}
                  defaultOptions={{
                    currency: values?.currency || '',
                  }}
                  valueKey="currency"
                  labelKey="currency_name"
                  onChange={({ name, value, option }) =>
                    onChange({ name, value, option, values, setFieldValue })
                  }
                  handleDebounce={handleDebounce}
                />
              </Grid>

              <Grid item xs={12}>
                <Box display={'flex'}>
                  <Typography variant={'ml'} sx={{ color: '#7C7F7F' }}>
                    Description
                  </Typography>
                  <Typography color="error">{'*'}</Typography>
                </Box>
                <TextFieldWrapper
                  name="description"
                  id="description"
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
            </Grid>

            <Box
              sx={{
                mt: 2,
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box></Box>
              <Box>
                <Button variant="textBtnCancel" onClick={handleClose}>
                  <Typography variant="mm">Cancel</Typography>
                </Button>
                <Button color={'error'} type="submit" disabled={disabledSave}>
                  <Typography variant="mm">Save</Typography>
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export const handleSearch = async ({
  name,
  value,
  dataSearchR,
  setDataSearchR,
}) => {
  let myData = dataSearchR
  const pathList = {
    consigneeName: {
      path: `/inputHouse/consigneeName?id=${value}`,
      name: 'consigneeName',
    },
    currency: {
      path: `/inputHouse/currency?id=${value}`,
      name: 'currency',
    },
  }
  const result = await getSearch({ rootPath: pathList[name].path })

  myData[`${name}List`] = result
  myData[`${name}Search`] = value

  setDataSearchR((prev) => ({
    ...prev,
    ...myData,
  }))
}

export default FormUser
