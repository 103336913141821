import axios from 'axios'

export const findDataTable = ({ rootPath, filter, url = '', path = '' }) =>
  axios.get(
    `${rootPath}/table${url}?page=${filter?.page || 0}&limit=${
      filter?.limit || -1
    }${filter?.orderBy ? '&orderBy=' : ''}${filter?.orderBy || ''}${
      filter?.order ? '&order=' : ''
    }${filter?.order || ''}&search=${filter.search}${path}`
  )

  export const findDataTablePost = ({ rootPath, filter, url = '', path = '', body, headers = {} }) =>
  axios.post(
    `${rootPath}/table${url}?page=${filter?.page || 0}&limit=${
      filter?.limit || -1
    }${filter?.orderBy ? '&orderBy=' : ''}${filter?.orderBy || ''}${
      filter?.order ? '&order=' : ''
    }${filter?.order || ''}&search=${filter.search}${path}`,
    {data:body}, 
    {
      'Content-Type': 'application/json',
    }
  )

export const findAll = ({ rootPath, url = '' }) =>
  axios.get(`${rootPath}${url}`)

export const findOne = ({ rootPath, id }) => axios.get(`${rootPath}/${id}`)

export const create = ({ rootPath, url = '', body, headers = {} }) =>
  axios.post(`${rootPath}${url}`, body, headers)

export const update = ({ rootPath, body, id = '', headers = {} }) =>
  axios.put(`${rootPath}${id && '/'}${id}`, body, headers)

export const remove = ({ rootPath, id }) => axios.delete(`${rootPath}/${id}`)

export const removeBody = ({ rootPath, body }) =>
  axios.delete(
    `${rootPath}`,
    { data: body },
    {
      'Content-Type': 'application/json',
    }
  )
